import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import GridTable from "pages/_components/GridTable/GridTable";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, shape } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import Line from "pages/_components/Line";
import CredentialTokenComponent from "pages/forms/_components/credential/CredentialTokenComponent";

const UserDataUpdateRequestConfirm = ({ fetching, listUserData, credentials, setFieldValue, isApproved }) => {
    const createTable = () => {
        const columnsTemplate = "1fr 1fr";

        const list = listUserData.map((user) => (
            <GridTable.Container
                className="min-height-12-i"
                columnsTemplate={columnsTemplate}
                key={user.key}
                padding="px-0">
                <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                    <Box display="flex" column alignX="flex-start" fullWidth>
                        <Text color="secondary" size="6" align="left" bold ellipsis>
                            {`${user?.name || ""} ${user?.lastName || ""}`.trim()}
                        </Text>
                        <Text color="text" size="6" ellipsis>
                            {user?.document}
                        </Text>
                    </Box>
                </GridTable.Data>
                <GridTable.Data columnStart={2} alignX="flex-end" alignY="center">
                    <Box display="flex" column fullWidth>
                        <Text color="text" size="6" ellipsis>
                            {user?.email}
                        </Text>
                        <Text color="text" size="6" ellipsis>
                            +{user?.mobileNumber}
                        </Text>
                    </Box>
                </GridTable.Data>
            </GridTable.Container>
        ));

        return (
            <GridTable>
                <GridTable.Body loading={fetching} className="mb-6 px-7">
                    {list}
                </GridTable.Body>
            </GridTable>
        );
    };

    let labelDescription = isApproved
        ? "remediation.modal.confirm.description.approved"
        : "remediation.modal.confirm.description.rejected";
    labelDescription += listUserData?.length > 1 ? ".plural" : "";

    return (
        <div className="modal-container px-0">
            <Modal.Header closeButton className="px-5">
                <Box display="flex" column alignY="center">
                    <Text
                        id="modalTitleID"
                        component="h4"
                        labelKey="remediation.modal.confirm.title"
                        className="mt-0 mb-2"
                        color="heading-color"
                        size="3"
                        align="center"
                        bold
                    />
                    <Text
                        id="modalDescription"
                        labelKey={labelDescription}
                        className="mt-4 mb-2"
                        color="heading-color"
                        size="11"
                        align="center"
                        regular
                    />
                    <Line color="background-divider" className="mt-5" />
                </Box>
            </Modal.Header>
            <Box className="full-width full-height min-height-0">
                <Box display="flex" column fullWidth fullHeight className="px-0 px-md-0">
                    <Box display="flex" flex1 className="min-height-7rem">
                        <Modal.Body>
                            <Box display="flex" column className="px-0 px-md-5" fullWidth fullHeight>
                                <Box display="flex" flex1 className="min-height-7rem">
                                    {createTable()}
                                </Box>
                            </Box>
                        </Modal.Body>
                    </Box>
                    <Box
                        display="flex"
                        column
                        alignY="center"
                        className="mt-auto pt-7 pt-md-8 px-5 px-md-9 pb-10"
                        gap="8">
                        {!fetching && (
                            <CredentialTokenComponent
                                credentials={credentials}
                                onChangeToken={(token) => {
                                    if (setFieldValue) {
                                        setFieldValue("otp", token);
                                    }
                                }}
                                disabled={fetching}
                                relativeStyleError
                            />
                        )}

                        <Button
                            bsStyle="primary"
                            label="global.continue"
                            type="submit"
                            block
                            disabled={fetching || !listUserData}
                        />
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

UserDataUpdateRequestConfirm.propTypes = {
    fetching: bool.isRequired,
    isApproved: bool,
    listUserData: arrayOf(shape({})).isRequired,
    setFieldValue: func,
    credentials: shape({}),
};

UserDataUpdateRequestConfirm.defaultProps = {
    credentials: {},
    isApproved: true,
    setFieldValue: () => {},
};

export default resizableRoute(UserDataUpdateRequestConfirm);
