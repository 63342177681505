import classNames from "classnames";
import { Form, Formik } from "formik";
import { resizableRoute } from "pages/_components/Resizable";
import { arrayOf, bool, func, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
    actions as userDataUpdateRequestActions,
    selectors as userDataUpdateRequestSelector,
} from "reducers/userDataUpdateRequest";
import { compose } from "redux";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import UserDataUpdateDetail from "./UserDataUpdateDetail";
import UserDataUpdateRequestConfirm from "./UserDataUpdateRequestConfirm";
import UserDataUpdateRequestList from "./UserDataUpdateRequestList";
import UserDataUpdateSuccess from "./UserDataUpdateSuccess";

const FORM_ID = "userData.update.request.admin";
const ID_ACTIVITY = `${FORM_ID}.send`;

const UserDataUpdateRequestModal = ({
    dispatch,
    fetching,
    showModal,
    listUserData,
    listEnvironments,
    countryList,
    credentials,
}) => {
    const [pageSelected, setPageSelected] = useState("list");
    const [userDetail, setUserDetail] = useState(null);
    const [listFilter, setListFilter] = useState([]);
    const [actualEnvironments, setActualEnvironments] = useState([]);
    const [showSearchHelp, setShowSearchHelp] = useState(false);

    useEffect(() => {
        if (showModal) {
            dispatch(userDataUpdateRequestActions.listUserDataUpdateRequest());
            setPageSelected("list");
            setUserDetail(null);
            setShowSearchHelp(false);
        }
    }, [showModal]);

    useEffect(() => {
        if (listUserData) {
            setListFilter(listUserData);
        }
    }, [listUserData]);

    const handleCloseModal = () => {
        dispatch(userDataUpdateRequestActions.modalHide());
    };

    const handleFilterData = (filter) => {
        if (filter !== "") {
            const list = [
                ...listUserData.filter((el) => {
                    const search = filter.replace(/ /g, "");
                    const fullName = el?.name + el?.lastName;
                    return fullName.toLowerCase().includes(search.toLowerCase());
                }),
            ];
            setListFilter(list);
            setShowSearchHelp(list.length === 0);
        } else {
            setListFilter(listUserData || []);
            setShowSearchHelp(false);
        }
    };

    const handleSelectUser = (userSel) => {
        if (userSel) {
            setUserDetail(userSel);
            const listIds = userSel?.environments?.split(",") || [];
            const environments = listEnvironments.filter((en) =>
                listIds.some((el) => Number(el) === en?.idEnvironment),
            );
            setActualEnvironments(environments);

            setPageSelected("detail");
        }
    };

    const handleCheckboxChange = (isChecked, user, setFieldValue, values) => {
        const users = [...values?.selectedUsers];
        if (isChecked) {
            users.push(user);
            setFieldValue("selectedUsers", users);
        } else {
            setFieldValue(
                "selectedUsers",
                users.filter((el) => el?.idRequest !== user?.idRequest),
            );
        }
    };

    const handleApprove = (setFieldValue) => {
        dispatch(userDataUpdateRequestActions.credentialsGroupsRequest({ idActivity: ID_ACTIVITY }));
        setFieldValue("status", "APPROVED");
        setPageSelected("confirm");
    };

    const handleReject = (setFieldValue) => {
        dispatch(userDataUpdateRequestActions.credentialsGroupsRequest({ idActivity: ID_ACTIVITY }));
        setFieldValue("status", "REJECTED");
        setPageSelected("confirm");
    };

    const handleBack = () => {
        setPageSelected("list");
        setListFilter(listUserData || []);
    };

    const getPageSelected = (propsForm) => {
        switch (pageSelected) {
            case "list":
                return (
                    <UserDataUpdateRequestList
                        fetching={fetching}
                        listUserData={listFilter}
                        handleFilterData={handleFilterData}
                        handleCheckboxChange={handleCheckboxChange}
                        handleApprove={handleApprove}
                        handleReject={handleReject}
                        handleSelectUser={handleSelectUser}
                        selectedUsers={propsForm?.values?.selectedUsers}
                        values={propsForm.values}
                        setFieldValue={propsForm.setFieldValue}
                        showSearchHelp={showSearchHelp}
                    />
                );

            case "confirm":
                return (
                    <UserDataUpdateRequestConfirm
                        fetching={fetching}
                        listUserData={propsForm?.values?.selectedUsers}
                        credentials={credentials}
                        setFieldValue={propsForm.setFieldValue}
                        isApproved={propsForm?.values?.status === "APPROVED"}
                    />
                );

            case "detail":
                return (
                    <UserDataUpdateDetail
                        userData={userDetail}
                        handleBack={handleBack}
                        listEnvironments={actualEnvironments}
                        countryList={countryList}
                    />
                );
            case "success":
                return (
                    <UserDataUpdateSuccess
                        onClose={handleCloseModal}
                        isApproved={propsForm?.values?.status === "APPROVED"}
                        listUserData={propsForm?.values?.selectedUsers}
                    />
                );

            default:
                return (
                    <UserDataUpdateRequestList
                        fetching={fetching}
                        listUserData={listFilter}
                        handleFilterData={handleFilterData}
                        handleCheckboxChange={handleCheckboxChange}
                        handleApprove={handleApprove}
                        handleReject={handleReject}
                        handleSelectUser={handleSelectUser}
                        showSearchHelp={showSearchHelp}
                    />
                );
        }
    };

    return (
        <Modal
            aria-labelledby="modalTitleID"
            aria-modal="true"
            onHide={handleCloseModal}
            show={showModal}
            className={classNames("drawer remediation-user-modal")}>
            <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={{
                    selectedUsers: [],
                    otp: "",
                    status: "",
                }}
                validationSchema={() =>
                    Yup.object().shape({
                        otp: Yup.string()
                            .nullable()
                            .required(i18n.get("fields.defaultForm.defaultField.requiredError")),
                        status: Yup.string().required(i18n.get("fields.defaultForm.defaultField.requiredError")),
                        selectedUsers: Yup.array()
                            .of(Yup.string())
                            .min(1, i18n.get("fields.defaultForm.defaultField.requiredError")),
                    })
                }
                onSubmit={(values, formikBag) => {
                    const { status, selectedUsers, otp } = values;
                    const isNeedSignedByAdmin = selectedUsers?.length !== listUserData?.length;
                    dispatch(
                        userDataUpdateRequestActions.sendDataUserDataUpdateRequestAdminSend(
                            status,
                            selectedUsers,
                            otp,
                            () => setPageSelected("success"),
                            formikBag,
                            isNeedSignedByAdmin,
                        ),
                    );
                }}>
                {(propsForm) => <Form className="full-width">{getPageSelected(propsForm)}</Form>}
            </Formik>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    fetching: userDataUpdateRequestSelector.getFetching(state),
    countryList: userDataUpdateRequestSelector.getCountryList(state),
    listUserData: userDataUpdateRequestSelector.getListUserData(state),
    listEnvironments: userDataUpdateRequestSelector.getListEnvironments(state),
    showModal: userDataUpdateRequestSelector.getDisplayModal(state),
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(userDataUpdateRequestSelector.getCredentialsGroups(state)),
    hasCompleteCredentialGroups: userDataUpdateRequestSelector.hasCompleteCredentialGroups(state),
});

UserDataUpdateRequestModal.propTypes = {
    dispatch: func,
    countryList: arrayOf(shape({})),
    credentials: shape({}),
    fetching: bool.isRequired,
    listUserData: arrayOf(shape({})).isRequired,
    listEnvironments: arrayOf(shape({})).isRequired,
    showModal: bool,
};

UserDataUpdateRequestModal.defaultProps = {
    dispatch: () => {},
    showModal: false,
    countryList: [],
    credentials: {},
};

export default compose(connect(mapStateToProps))(resizableRoute(UserDataUpdateRequestModal));
