import moment from "moment";
import * as config from "util/config";
import * as i18n from "util/i18n";

export const loansIsNotAvailable = (overdueStatus) => {
    const loansNotAvailableConfig = config.get("loans.overdueStatus.notAvailable", "");
    const loansNotAvailable = loansNotAvailableConfig.split("|");
    return loansNotAvailable.includes(overdueStatus);
};

export const disclaimerIsRestricted = (acctBlockCod) => {
    if (!acctBlockCod) {
        return false;
    }
    const loanLatePaymentWarningRestriction = config.get("loans.late.payment.warning.restriction", "");
    const loanLatePaymentWarningRestricted = loanLatePaymentWarningRestriction.split("|");
    return loanLatePaymentWarningRestricted.includes(acctBlockCod);
};

export const payLoanButtonIsDisabled = (acctBlockCod) => {
    const payLoanButtonRestriction = config.get("loans.pay.button.restriction", "");
    const payLoanButtonRestricted = payLoanButtonRestriction.split("|");
    return payLoanButtonRestricted.includes(acctBlockCod);
};

export const getTypeForLoanInRevisionDisclaimer = (
    acctBlockCod,
    categoryId,
    categoryIdIcon,
    lastExpiredInstallmentDay,
    lastPaymentDay,
) => {
    const reviewDisclaimerRestriction = config.get("loans.review.disclaimer.restriction", "");
    const reviewDisclaimerRestricted = reviewDisclaimerRestriction.split("|");
    const isAcctBlockCodResctricted = reviewDisclaimerRestricted.includes(acctBlockCod);

    const reviewTypeRestriction = config.get("loans.review.type.restriction", "");
    const reviewTypeRestricted = reviewTypeRestriction.split("|");
    const isCategoryResctricted = reviewTypeRestricted.includes(categoryId);

    const reviewMinDays = Number(config.get("loans.review.min.days.restriction")) || 0;
    const reviewMaxDays = Number(config.get("loans.review.max.days.restriction")) || 0;

    const isLastExpiredInstallmentDayValid = lastExpiredInstallmentDay && moment(lastExpiredInstallmentDay).isValid();
    const isLastPaymentDayValid = lastPaymentDay && moment(lastPaymentDay).isValid();

    let diffInDays = 0;
    if (isLastExpiredInstallmentDayValid) {
        diffInDays = moment(moment()).diff(lastExpiredInstallmentDay, "days");
    } else if (isLastPaymentDayValid) {
        diffInDays = moment(moment()).diff(lastPaymentDay, "days");
    }

    const rangeDateIncluded =
        (isLastExpiredInstallmentDayValid || isLastPaymentDayValid) &&
        reviewMinDays > 0 &&
        reviewMaxDays > 0 &&
        diffInDays !== null &&
        diffInDays >= reviewMinDays &&
        diffInDays <= reviewMaxDays;

    if (isAcctBlockCodResctricted && isCategoryResctricted && rangeDateIncluded && categoryIdIcon) {
        return i18n.get(`loans.review.message.type.${categoryIdIcon.toLowerCase()}`);
    }
    return null;
};

export default loansIsNotAvailable;
