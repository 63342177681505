import { Field, Form, withFormik } from "formik";
import moment from "moment";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import DateField from "pages/_components/fields/DateField";
// import PhoneInput from "pages/_components/fields/PhoneInput";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import SwitchField from "pages/_components/fields/formik/SwitchField";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import { USD_CURRENCY } from "constants.js";
import { bool, func, string, shape } from "prop-types";
import React, { useEffect, useState } from "react";
import { numberFormat } from "util/number";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import * as configUtil from "util/config";
import { push } from "react-router-redux";
import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
} from "reducers/updateUserData/updateUserData.reducer";
import { compose } from "redux";
import * as dateUtils from "util/date";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import { useLoadingGlobalProvider } from "providers/LoadingGlobalProvider";

const FORM_ID = "settings.personalDataUpdateStep3";
const idActivity = `${FORM_ID}.send`;

const PersonalDataUpdateStep3 = ({
    isSubmitting,
    isFetching,
    isDesktop,
    dispatch,
    exchangeToken,
    currentUserData,
    preDataStep3,
    isDisabledStep3,
    setFieldValue,
    isDisabledDistrict,
    isDisabledCity,
    isDisabledJurisdiction,
    provinceListAux,
    districtByProvinceList,
    jurisdictionByDistrictList,
    cityByStateProvinceList,
    isDisabledIncomeFields,
    isFetchingProvince,
    isFetchingDistrict,
    isFetchingJuridistion,
    isFetchingCity,
}) => {
    //    const preferredCountries = ["pa", "ve"];
    //    const areaCodes = { ve: ["412", "414", "416", "424", "426"] };
    const countriesWithCityCatalogue = configUtil.getArray("settings.userData.cityCatalogueCountries");
    const countriesWithCityCatalogueBasedOnCode = configUtil.getArray("settings.userData.cityCode.step3");

    // Section 1
    const [employmentName, setEmploymentName] = useState();
    const [industryType, setIndustryType] = useState();
    const [businessType, setBusinessType] = useState();
    const [startDate, setStartDate] = useState();
    const [countryId, setCountryId] = useState();
    const [province, setProvince] = useState();
    const [district, setDistrict] = useState();
    const [jurisdiction, setJurisdiction] = useState();
    const [city, setCity] = useState();
    const [reference, setReference] = useState();
    const [street, setStreet] = useState();
    const [building, setBuilding] = useState();
    const [house, setHouse] = useState();
    const [postalCode, setPostalCode] = useState();

    // Section 2
    const [incomeType, setIncomeType] = useState();
    const [otherIncomeSource, setOtherIncomeSource] = useState();

    // Selectors
    const [industryTypeList, setIndustryTypeList] = useState([]);
    const [businessTypeList, setBusinessTypeList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [provinceList, setProvinceList] = useState([]);
    const [districtList, setDistrictList] = useState([]);
    const [jurisdictionList, setJurisdictionList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [incomeTypeList, setIncomeTypeList] = useState([]);
    const [otherIncomeSourceList, setOtherIncomeSourceList] = useState([]);

    const { setLoading } = useLoadingGlobalProvider();
    const { decimalSeparator, thousandSeparator } = numberFormat();

    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);

    useEffect(() => {
        dispatch(updateUserDataActions.preFormStep3(exchangeToken));
    }, [dispatch]);

    useEffect(() => {
        if (!isFetching && currentUserData && currentUserData.Employment) {
            const indType = currentUserData.Industry;
            const { EmploymentStartDt, OrgData, OtherIncome, Salary } = currentUserData.Employment;
            const busiType = OrgData.Type;

            setEmploymentName(OrgData?.OrgName?.FullName);
            setFieldValue("jobName", OrgData?.OrgName?.FullName);

            if (indType) {
                setIndustryType(indType);
                setFieldValue("industryType", indType);
            } else {
                setIndustryType("");
                setFieldValue("industryType", "");
            }

            if (busiType) {
                setBusinessType(busiType);
                setFieldValue("businessType", busiType);
            } else {
                setBusinessType("");
                setFieldValue("businessType", "");
            }
            const formattedEmploymentDate = moment(EmploymentStartDt).format("DD/MM/YYYY");
            setStartDate(formattedEmploymentDate);
            setFieldValue("joinDate", EmploymentStartDt);

            const { PostAddr } = OrgData?.Contact?.Locator ?? {};
            if (PostAddr?.length && PostAddr?.length > 0) {
                const {
                    AddrDesc,
                    Building,
                    CountyDistrict,
                    HouseNumber,
                    Jurisdiction,
                    Province,
                    Street,
                    Country,
                    City,
                    PostalCode,
                } = PostAddr ? PostAddr[0] : {};

                const countryCode =
                    Country?.CountryCode === "Panama" || Country?.CountryCode === "Panamá"
                        ? "PA"
                        : Country?.CountryCode;
                if (countryCode) {
                    setCountryId(countryCode);
                    setFieldValue("country", countryCode);
                } else {
                    setCountryId("");
                    setFieldValue("country", "");
                }

                const provinceCode = Province?.Cod;
                if (provinceCode) {
                    setProvince(provinceCode);
                    if (countryCode === "PA") {
                        setFieldValue("province", provinceCode);

                        if (CountyDistrict?.Cod) {
                            const districtCode = `${provinceCode}${CountyDistrict?.Cod}`;
                            setDistrict(districtCode);
                            setFieldValue("district", districtCode);
                        } else {
                            setDistrict("");
                            setFieldValue("district", "");
                        }

                        if (Jurisdiction?.Cod) {
                            setJurisdiction(Jurisdiction?.Cod);
                            setFieldValue("corregiment", Jurisdiction?.Cod);
                        } else {
                            setJurisdiction("");
                            setFieldValue("corregiment", "");
                        }

                        if (AddrDesc) {
                            setReference(AddrDesc);
                            setFieldValue("referencePoint", AddrDesc);
                        } else {
                            setReference("");
                            setFieldValue("referencePoint", "");
                        }
                    }
                } else {
                    setDistrict("");
                    setJurisdiction("");
                    setReference("");
                }

                if (Street) {
                    setStreet(Street);
                    setFieldValue("street", Street);
                } else {
                    setStreet("");
                    setFieldValue("street", "");
                }

                if (Building?.Desc) {
                    setBuilding(Building?.Desc);
                    setFieldValue("residentialBuilding", Building?.Desc);
                } else {
                    setBuilding("");
                    setFieldValue("residentialBuilding", "");
                }

                if (HouseNumber) {
                    setHouse(HouseNumber);
                    setFieldValue("floorApartmentHouse", HouseNumber);
                } else {
                    setHouse("");
                    setFieldValue("floorApartmentHouse", "");
                }

                if (PostalCode) {
                    setPostalCode(PostalCode);
                    setFieldValue("postalCode", PostalCode);
                }

                if (countriesWithCityCatalogue.includes(countryCode)) {
                    setFieldValue("stateProvince", provinceCode);
                    if (City?.Cod) {
                        setCity(City?.Cod);
                        setFieldValue("city", City?.Cod);
                    } else if (City?.Desc) {
                        setCity(City?.Desc);
                        setFieldValue("city", City?.Desc);
                    } else {
                        setCity("");
                        setFieldValue("city", "");
                    }
                } else if (countryCode !== "PA") {
                    setFieldValue("stateProvince", provinceCode);
                    if (City?.Desc) {
                        setCity(City?.Desc);
                        setFieldValue("city", City?.Desc);
                    } else {
                        setCity("");
                        setFieldValue("city", "");
                    }
                }
            } else {
                setCountryId("");
                setStreet("");
                setBuilding("");
                setHouse("");
                setPostalCode("");
            }

            const incomeAmnt = Salary?.Amt ? Salary.Amt.toFixed(2) : "";
            setFieldValue("monthlyIncomeAmount", {
                quantity: incomeAmnt,
                currency: USD_CURRENCY,
            });

            const otherIncomeAux = OtherIncome ? OtherIncome[0] : {};
            const otherIncomeTypeAux = otherIncomeAux?.IncomeType ? otherIncomeAux.IncomeType : "";
            setOtherIncomeSource(otherIncomeTypeAux);
            setFieldValue("otherIncomeSource", otherIncomeTypeAux);

            const otherIncomeAmnt = otherIncomeAux?.IncomeAmt?.Amt ? otherIncomeAux.IncomeAmt.Amt.toFixed(2) : "0.00";
            setFieldValue("otherMonthlyIncomeAmount", {
                quantity: otherIncomeAmnt,
                currency: USD_CURRENCY,
            });
        } else {
            setEmploymentName("");
            setIndustryType("");
            setBusinessType("");
            setStartDate("");
            setCountryId("");
            setStreet("");
            setBuilding("");
            setHouse("");
            setPostalCode("");
            setFieldValue("monthlyIncomeAmount", {
                quantity: "",
                currency: USD_CURRENCY,
            });
            setOtherIncomeSource("");
            setFieldValue("otherMonthlyIncomeAmount", {
                quantity: "0.00",
                currency: USD_CURRENCY,
            });
        }

        if (!isFetching && currentUserData && currentUserData.Income) {
            const incomeTypeAux = currentUserData?.Income?.IncomeType ? currentUserData.Income.IncomeType : "";
            setIncomeType(incomeTypeAux);
            setFieldValue("incomeReceivingMethod", incomeTypeAux);
        } else {
            setIncomeType("");
            setFieldValue("incomeReceivingMethod", "");
        }
    }, [currentUserData]);

    useEffect(() => {
        if (!isFetching && preDataStep3) {
            setIndustryTypeList(preDataStep3.industryTypeList);
            setBusinessTypeList(preDataStep3.businessTypeList);
            setCountryList(preDataStep3.countryList);
            setProvinceList(preDataStep3.provinceList);
            setDistrictList(preDataStep3.districtList);
            setJurisdictionList(preDataStep3.jurisdictionList);
            setCityList(preDataStep3.cityList);
            setIncomeTypeList(preDataStep3.incomeTypeList);
            setOtherIncomeSourceList(preDataStep3.otherIncomeSourceList);
            // Validate industry type is found in the list
            if (preDataStep3.industryTypeList && industryType) {
                const industryFound = preDataStep3.industryTypeList.find((option) => option.value === industryType);
                if (!industryFound) {
                    setIndustryType("");
                    setFieldValue("industryType", "");
                }
            }

            // Validate business type is found in the list
            if (preDataStep3.businessTypeList && businessType) {
                const businessFound = preDataStep3.businessTypeList.find((option) => option.value === businessType);
                if (!businessFound) {
                    setBusinessType("");
                    setFieldValue("businessType", "");
                }
            }

            // Validate income type is found in the list
            if (preDataStep3.incomeTypeList && incomeType) {
                const incomeTypeFound = preDataStep3.incomeTypeList.find((option) => option.value === incomeType);
                if (!incomeTypeFound) {
                    setIncomeType("");
                    setFieldValue("incomeReceivingMethod", "");
                }
            }

            // Validate other income source is found in the list
            if (preDataStep3.otherIncomeSourceList && otherIncomeSource) {
                const otherIncomeFound = preDataStep3.otherIncomeSourceList.find(
                    (option) => option.value === otherIncomeSource,
                );
                if (!otherIncomeFound) {
                    setOtherIncomeSource("");
                    setFieldValue("otherIncomeSource", "");
                }
            }
        }
    }, [preDataStep3]);

    useEffect(() => {
        if (city && cityList && countriesWithCityCatalogueBasedOnCode.includes(countryId)) {
            let cityAux = cityList.find((e) => e.label === city);
            if (!cityAux) {
                cityAux = cityList.find((e) => e.value === city);
            }
            setCity(cityAux?.value);
            setFieldValue("city", cityAux?.value);
        }
    }, [cityList]);

    const enableStep3 = () => {
        dispatch(updateUserDataActions.enableStep3(!isDisabledStep3));
    };

    const enableOtherIncomeFields = () => {
        dispatch(updateUserDataActions.enableIncomeFields(!isDisabledIncomeFields));
    };

    const handleChangeCountry = (countryCode) => {
        setCountryId(countryCode);
        setFieldValue("country", countryCode);
        setProvince("");
        setFieldValue("province", "");

        if (countryCode === "PA") {
            setDistrict("");
            setFieldValue("district", "");
            setJurisdiction("");
            setFieldValue("corregiment", "");
            if (!isDisabledDistrict) {
                dispatch(updateUserDataActions.disableDistrict(true));
            }

            if (!isDisabledJurisdiction) {
                dispatch(updateUserDataActions.disableJurisdiction(true));
            }
        }

        if (countryCode !== "PA") {
            setFieldValue("stateProvince", "");
            setCity("");
            setFieldValue("city", "");
            if (!isDisabledCity) {
                dispatch(updateUserDataActions.disableCity(true));
            }
        }

        dispatch(updateUserDataActions.getProvinceListRequest(countryCode));
    };

    useEffect(() => {
        if (!isFetching && !isDisabledStep3 && provinceListAux && provinceListAux.length > 0) {
            setProvinceList(provinceListAux);
        }
    }, [provinceListAux]);

    const handleChangeProvince = (provinceCode) => {
        setProvince(provinceCode);
        setFieldValue("province", provinceCode);

        if (countryId === "PA") {
            setDistrict("");
            setFieldValue("district", "");
            setJurisdiction("");
            setFieldValue("corregiment", "");

            if (isDisabledDistrict) {
                dispatch(updateUserDataActions.disableDistrict(false));
            }
            dispatch(updateUserDataActions.disableJurisdiction(true));
            dispatch(updateUserDataActions.getDistrictListRequest(provinceCode));
        } else if (countryId !== "PA") {
            setCity("");
            setFieldValue("city", "");

            dispatch(updateUserDataActions.disableCity(false));
            dispatch(updateUserDataActions.getCityListRequest(provinceCode));
        }
    };

    const getDataAmount = () => ({
        decimalSeparator,
        precision: 2,
        thousandsSeparator: thousandSeparator,
        options: [{ id: 1, label: USD_CURRENCY }],
    });

    useEffect(() => {
        if (!isFetching && !isDisabledStep3 && districtByProvinceList && districtByProvinceList.length > 0) {
            setDistrictList(districtByProvinceList);
        }
    }, [districtByProvinceList]);

    const handleChangeDistrict = (districtCode) => {
        setDistrict(districtCode);
        setFieldValue("district", districtCode);
        setJurisdiction("");
        setFieldValue("corregiment", "");
        dispatch(updateUserDataActions.disableJurisdiction(false));
        dispatch(updateUserDataActions.getJurisdictionListRequest(districtCode));
    };

    useEffect(() => {
        if (!isFetching && !isDisabledStep3 && jurisdictionByDistrictList && jurisdictionByDistrictList.length > 0) {
            setJurisdictionList(jurisdictionByDistrictList);
        }
    }, [jurisdictionByDistrictList]);

    const handleChangeJurisdiction = (jurisdictionCode) => {
        setJurisdiction(jurisdictionCode);
        setFieldValue("corregiment", jurisdictionCode);
    };

    useEffect(() => {
        if (!isFetching && !isDisabledStep3 && cityByStateProvinceList && cityByStateProvinceList.length > 0) {
            setCityList(cityByStateProvinceList);
        }
    }, [cityByStateProvinceList]);

    useEffect(() => {
        if (!isFetching && currentUserData && provinceList?.length) {
            const provinceCode = currentUserData?.Employment?.OrgData?.Contact?.Locator?.PostAddr[0]?.Province.Cod;
            const isValidCode = provinceList.some((elem) => elem.value === provinceCode);

            if (!isValidCode) {
                setFieldValue("province", "");
                setFieldValue("stateProvince", "");
                setProvince("");
            }
        }
    }, [provinceList]);

    const handleChangeCity = (cityCode) => {
        setCity(cityCode);
        setFieldValue("city", cityCode);
    };

    const handleChangeStartDate = (selectedStartDate) => {
        const selectedJoinDateAsString = moment(selectedStartDate.toDate()).format("DD/MM/YYYY");
        setStartDate(selectedJoinDateAsString);
        setFieldValue("joinDate", selectedStartDate.toDate());
    };

    const handleChangeIndustryType = (selectedIndustry) => {
        setIndustryType(selectedIndustry);
        setFieldValue("industryType", selectedIndustry);
    };

    const handleChangeBusinessType = (selectedBusiness) => {
        setBusinessType(selectedBusiness);
        setFieldValue("businessType", selectedBusiness);
    };

    const handleChangeIncomeReceivingMethod = (selectedIncomeMethod) => {
        setIncomeType(selectedIncomeMethod);
        setFieldValue("incomeReceivingMethod", selectedIncomeMethod);
    };

    const handleChangeOtherIncomeSource = (selectedIncomeMethod) => {
        setOtherIncomeSource(selectedIncomeMethod);
        setFieldValue("otherIncomeSource", selectedIncomeMethod);
    };

    const handleCancel = () => {
        dispatch(updateUserDataActions.enableStep3(false));
        dispatch(updateUserDataActions.disableDistrict(true));
        dispatch(updateUserDataActions.disableJurisdiction(true));
        dispatch(updateUserDataActions.disableCity(true));
        dispatch(push("/desktop"));
    };

    const renderLoading = () => (
        <Box
            display="flex"
            alignY="center"
            alignX="flex-start"
            className="pt-7 px-2"
            fullHeight
            fullWidth
            position="absolute"
            left="0"
            top="0">
            <Button loading bsStyle="link" className="min-width-12" />
            <Box
                display="flex"
                alignY="center"
                alignX="center"
                className="min-height-7 min-width-12 background-loading-in-select"
                fullWidth
                position="absolute"
                borderRadius="form"
                background="component-background"
                top="8"
                left="0"
            />
        </Box>
    );

    return (
        <>
            <Notification scopeToShow="personalDataUpdate" />
            <Form className="mx-n-5">
                <Box
                    display="flex"
                    column
                    fullWidth
                    background="component-background"
                    className="px-5 px-md-12 pt-7 pb-10 pb-md-11 mb-3 mb-md-8"
                    borderRadius="default">
                    <Box className="mb-8">
                        <Field
                            component={SwitchField}
                            idForm={FORM_ID}
                            name="modifyDataControl"
                            onChange={enableStep3}
                            value={!isDisabledStep3}
                        />
                    </Box>

                    <Row>
                        <Col xs={12} md={6}>
                            <Field
                                idForm={FORM_ID}
                                name="jobName"
                                component={TextField}
                                type="text"
                                initValue={employmentName}
                                labelNoMarginTop
                                disabled={isDisabledStep3}
                                maxLength="35"
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Field
                                idForm={FORM_ID}
                                name="industryType"
                                component={Selector}
                                options={industryTypeList}
                                value={industryType}
                                labelNoMarginTop
                                disabled={isDisabledStep3}
                                handleChange={handleChangeIndustryType}
                                searchable
                            />
                        </Col>
                        <Col xs={6}>
                            <Field
                                idForm={FORM_ID}
                                name="businessType"
                                component={Selector}
                                options={businessTypeList}
                                value={businessType}
                                labelNoMarginTop
                                disabled={isDisabledStep3}
                                handleChange={handleChangeBusinessType}
                                searchable
                            />
                        </Col>
                        <Col xs={6}>
                            <Field
                                component={DateField}
                                idForm={FORM_ID}
                                name="joinDate"
                                minDate={moment().add(-50, "years")}
                                maxDate={moment(new Date())}
                                dateFormat={dateUtils.FRIENDY_DATE_FORMAT(i18n.getLang())}
                                idField="birthDate.date"
                                value={startDate}
                                labelNoMarginTop
                                disabled={isDisabledStep3}
                                onChange={handleChangeStartDate}
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Field
                                idForm={FORM_ID}
                                name="country"
                                component={Selector}
                                options={countryList}
                                value={countryId}
                                valueKey="value"
                                labelKey="label"
                                labelNoMarginTop
                                disabled={isDisabledStep3}
                                handleChange={handleChangeCountry}
                                searchable
                            />
                        </Col>
                        {!isFetching && countryId === "PA" && (
                            <>
                                <Col xs={12} md={6}>
                                    <Box position="relative">
                                        <Field
                                            idForm={FORM_ID}
                                            name="province"
                                            component={Selector}
                                            options={provinceList}
                                            value={province}
                                            valueKey="value"
                                            labelKey="label"
                                            labelNoMarginTop
                                            disabled={isDisabledStep3}
                                            handleChange={handleChangeProvince}
                                            searchable
                                        />
                                        {isFetchingProvince && renderLoading()}
                                    </Box>
                                </Col>
                                <Col xs={6}>
                                    <Box position="relative">
                                        <Field
                                            idForm={FORM_ID}
                                            name="district"
                                            component={Selector}
                                            options={districtList}
                                            value={district}
                                            valueKey="value"
                                            labelKey="label"
                                            labelNoMarginTop
                                            disabled={isDisabledStep3 || isDisabledDistrict}
                                            handleChange={handleChangeDistrict}
                                            searchable
                                        />
                                        {isFetchingDistrict && renderLoading()}
                                    </Box>
                                </Col>
                                <Col xs={6}>
                                    <Box position="relative">
                                        <Field
                                            idForm={FORM_ID}
                                            name="corregiment"
                                            component={Selector}
                                            options={jurisdictionList}
                                            value={jurisdiction}
                                            valueKey="value"
                                            labelKey="label"
                                            labelNoMarginTop
                                            disabled={isDisabledStep3 || isDisabledJurisdiction}
                                            handleChange={handleChangeJurisdiction}
                                            searchable
                                        />
                                        {isFetchingJuridistion && renderLoading()}
                                    </Box>
                                </Col>
                            </>
                        )}
                        {!isFetching && countriesWithCityCatalogue.includes(countryId) && (
                            <>
                                <Col xs={6}>
                                    <Box position="relative">
                                        <Field
                                            idForm={FORM_ID}
                                            name="stateProvince"
                                            component={Selector}
                                            options={provinceList}
                                            value={province}
                                            valueKey="value"
                                            labelKey="label"
                                            labelNoMarginTop
                                            disabled={isDisabledStep3}
                                            handleChange={handleChangeProvince}
                                            searchable
                                        />
                                        {isFetchingProvince && renderLoading()}
                                    </Box>
                                </Col>
                            </>
                        )}
                        {!isFetching && countriesWithCityCatalogue.includes(countryId) && (
                            <Col xs={6} md={12}>
                                <Box position="relative">
                                    <Field
                                        idForm={FORM_ID}
                                        name="city"
                                        component={Selector}
                                        options={cityList}
                                        value={city}
                                        valueKey="value"
                                        labelKey="label"
                                        labelNoMarginTop
                                        disabled={isDisabledStep3 || isDisabledCity}
                                        handleChange={handleChangeCity}
                                        searchable
                                    />
                                    {isFetchingCity && renderLoading()}
                                </Box>
                            </Col>
                        )}
                        {!isFetching && countryId !== "PA" && !countriesWithCityCatalogue.includes(countryId) && (
                            <>
                                <Col xs={6}>
                                    <Box position="relative">
                                        <Field
                                            idForm={FORM_ID}
                                            name="stateProvince"
                                            component={Selector}
                                            options={provinceList}
                                            value={province}
                                            valueKey="value"
                                            labelKey="label"
                                            labelNoMarginTop
                                            disabled={isDisabledStep3}
                                            handleChange={handleChangeProvince}
                                            searchable
                                        />
                                        {isFetchingProvince && renderLoading()}
                                    </Box>
                                </Col>
                                <Col xs={6} md={12}>
                                    <Field
                                        idForm={FORM_ID}
                                        name="city"
                                        component={TextField}
                                        initValue={city}
                                        type="text"
                                        labelNoMarginTop
                                        disabled={isDisabledStep3 || isDisabledCity}
                                        maxLength="35"
                                        pattern="^[a-zA-Z0-9 ]*$"
                                    />
                                </Col>
                            </>
                        )}
                        <Col xs={12}>
                            <Field
                                idForm={FORM_ID}
                                name="street"
                                component={TextField}
                                type="text"
                                initValue={street}
                                labelNoMarginTop
                                disabled={isDisabledStep3}
                                maxLength="60"
                                pattern="^[a-zA-Z0-9.,\/\- ]*$"
                            />
                        </Col>
                        <Col xs={6} md={countryId === "PA" ? 4 : 6}>
                            <Field
                                idForm={FORM_ID}
                                name="residentialBuilding"
                                component={TextField}
                                type="text"
                                initValue={building}
                                labelNoMarginTop
                                disabled={isDisabledStep3}
                                maxLength="35"
                                pattern="^[a-zA-Z0-9.,\/\- ]*$"
                            />
                        </Col>
                        <Col xs={6} md={countryId === "PA" ? 4 : 6}>
                            <Field
                                idForm={FORM_ID}
                                name="floorApartmentHouse"
                                component={TextField}
                                type="text"
                                initValue={house}
                                labelNoMarginTop
                                disabled={isDisabledStep3}
                                maxLength="10"
                                pattern="^[a-zA-Z0-9.,\/\- ]*$"
                            />
                        </Col>
                        {!isFetching && countryId === "PA" && (
                            <>
                                <Col xs={12} md={4}>
                                    <Field
                                        idForm={FORM_ID}
                                        name="referencePoint"
                                        component={TextField}
                                        type="text"
                                        labelNoMarginTop
                                        initValue={reference}
                                        disabled={isDisabledStep3}
                                        maxLength="35"
                                        pattern="^[a-zA-Z0-9 ]*$"
                                    />
                                </Col>
                            </>
                        )}

                        <>
                            <Col xs={12} md={4}>
                                <Field
                                    idForm={FORM_ID}
                                    name="postalCode"
                                    component={TextField}
                                    type="text"
                                    labelNoMarginTop
                                    initValue={postalCode}
                                    disabled={isDisabledStep3}
                                    maxLength="10"
                                    pattern="^[a-zA-Z0-9 ,.]*$"
                                />
                            </Col>
                        </>
                    </Row>
                </Box>

                <Box
                    display="flex"
                    column
                    fullWidth
                    background="component-background"
                    className="px-5 px-md-12 pt-9 pb-7 mb-5 mb-md-8"
                    borderRadius="default">
                    <Row>
                        <Col xs={12} md={6}>
                            <Field
                                idForm={FORM_ID}
                                name="incomeReceivingMethod"
                                component={Selector}
                                options={incomeTypeList}
                                value={incomeType}
                                labelNoMarginTop
                                disabled={isDisabledStep3}
                                handleChange={handleChangeIncomeReceivingMethod}
                                searchable
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Field
                                idForm={FORM_ID}
                                idActivity={idActivity}
                                mode="edit"
                                component={FormFieldsComponents.Amount}
                                data={getDataAmount()}
                                name="monthlyIncomeAmount"
                                idField="monthlyIncomeAmount"
                                key="monthlyIncomeAmount"
                                customPlaceholderQuantity="0.00"
                                labelNoMarginTop
                                quantityTextAlign="text-left"
                                disabled={isDisabledStep3}
                                disabledCurrency
                                maxLength="18"
                            />
                        </Col>
                        <Box
                            display="flex"
                            {...(!isDesktop && { columnReverse: true, className: "mb-3" })}
                            alignY="center"
                            gap={isDesktop ? "7" : "5"}
                            fullWidth>
                            <Box display="flex" alignX="flex-start" className="mr-auto" flex1>
                                <Field
                                    component={SwitchField}
                                    idForm={FORM_ID}
                                    name="otherIncomeControl"
                                    onChange={enableOtherIncomeFields}
                                />
                            </Box>
                            <Box display="flex" alignX="flex-start" className="mr-auto" flex1>
                                <Disclaimer
                                    iconClassname="tooltip-img"
                                    labelKey="settings.personalDataUpdateStep3.disclaimer"
                                    notBorder
                                    textSize={isDesktop ? "7" : "6"}
                                    padding="pb-0 px-0"
                                    textColor="secondary"
                                />
                            </Box>
                        </Box>
                        <Col xs={12} md={6}>
                            <Field
                                idForm={FORM_ID}
                                name="otherIncomeSource"
                                component={Selector}
                                options={otherIncomeSourceList}
                                value={otherIncomeSource}
                                labelNoMarginTop
                                disabled={isDisabledIncomeFields}
                                handleChange={handleChangeOtherIncomeSource}
                                searchable
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <Field
                                idForm={FORM_ID}
                                idActivity={idActivity}
                                mode="edit"
                                component={FormFieldsComponents.Amount}
                                data={getDataAmount()}
                                name="otherMonthlyIncomeAmount"
                                idField="otherMonthlyIncomeAmount"
                                key="otherMonthlyIncomeAmount"
                                customPlaceholderQuantity="0.00"
                                labelNoMarginTop
                                quantityTextAlign="text-left"
                                disabled={isDisabledIncomeFields}
                                disabledCurrency
                                maxLength="18"
                                acceptZero
                            />
                        </Col>
                    </Row>
                </Box>
                <Row className="px-5 px-md-0" {...(!isDesktop && { gapY: "3" })}>
                    <Col xs={12} md={4} mdOffset={2}>
                        <Button label="global.cancel" bsStyle="outline" onClick={handleCancel} block />
                    </Col>
                    <Col xs={12} md={4} {...(!isDesktop && { className: "grid-reversed" })}>
                        <Button
                            label="global.continue"
                            loading={isSubmitting || isFetching}
                            type="submit"
                            bsStyle="primary"
                            block
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

PersonalDataUpdateStep3.propTypes = {
    dispatch: func.isRequired,
    isFetching: bool,
    isSubmitting: bool.isRequired,
    isDesktop: bool.isRequired,
    exchangeToken: string.isRequired,
    preDataStep3: shape({}),
    currentUserData: shape({}),
    isDisabledStep3: bool,
    isDisabledDistrict: bool,
    isDisabledJurisdiction: bool,
    isDisabledCity: bool,
    setFieldValue: func.isRequired,
    districtByProvinceList: shape({}),
    jurisdictionByDistrictList: shape({}),
    cityByStateProvinceList: shape({}),
    provinceListAux: shape({}),
    isDisabledIncomeFields: bool,
    isFetchingProvince: bool,
    isFetchingDistrict: bool,
    isFetchingJuridistion: bool,
    isFetchingCity: bool,
};

PersonalDataUpdateStep3.defaultProps = {
    isFetching: false,
    currentUserData: null,
    preDataStep3: null,
    isDisabledStep3: true,
    isDisabledDistrict: true,
    isDisabledJurisdiction: true,
    isDisabledCity: true,
    districtByProvinceList: {},
    jurisdictionByDistrictList: {},
    cityByStateProvinceList: {},
    provinceListAux: {},
    isDisabledIncomeFields: true,
    isFetchingProvince: false,
    isFetchingDistrict: false,
    isFetchingJuridistion: false,
    isFetchingCity: false,
};

const mapStateToProps = (state) => ({
    isFetching: updateUserDataSelectors.isFetching(state),
    exchangeToken: updateUserDataSelectors.getExchangeToken(state),
    currentUserData: updateUserDataSelectors.getCurrentUserData(state),
    preDataStep3: updateUserDataSelectors.getPreDataStep3(state),
    isDisabledStep3: updateUserDataSelectors.isDisabledStep3(state),
    isDisabledDistrict: updateUserDataSelectors.isDisabledDistrict(state),
    isDisabledJurisdiction: updateUserDataSelectors.isDisabledJurisdiction(state),
    isDisabledCity: updateUserDataSelectors.isDisabledCity(state),
    districtByProvinceList: updateUserDataSelectors.getDistrictByProvinceList(state),
    jurisdictionByDistrictList: updateUserDataSelectors.getJurisdictionList(state),
    provinceListAux: updateUserDataSelectors.getProvinceList(state),
    cityByStateProvinceList: updateUserDataSelectors.getCityList(state),
    isDisabledIncomeFields: updateUserDataSelectors.isDisabledIncomeFields(state),
    isFetchingProvince: updateUserDataSelectors.isFetchingProvince(state),
    isFetchingDistrict: updateUserDataSelectors.isFetchingDistrict(state),
    isFetchingJuridistion: updateUserDataSelectors.isFetchingJuridistion(state),
    isFetchingCity: updateUserDataSelectors.isFetchingCity(state),
});

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            jobName: "",
            industryType: "",
            businessType: "",
            joinDate: "",
            country: "",
            province: "",
            stateProvince: "",
            district: "",
            corregiment: "",
            street: "",
            residentialBuilding: "",
            floorApartmentHouse: "",
            referencePoint: "",
            city: "",
            postalCode: "",
            phone: { prefix: "", areaCode: "", value: "" },
            incomeReceivingMethod: "",
            otherIncomeSource: "",
            monthlyIncomeAmount: {
                quantity: "",
                currency: USD_CURRENCY,
            },
            otherMonthlyIncomeAmount: {
                quantity: "0.00",
                currency: USD_CURRENCY,
            },
            otherIncomeControl: false,
        }),

        validationSchema: () =>
            Yup.object().shape({
                jobName: Yup.string().required(i18n.get(`${FORM_ID}.jobName.required`)),
                industryType: Yup.string().required(i18n.get(`${FORM_ID}.industryType.required`)),
                businessType: Yup.string().required(i18n.get(`${FORM_ID}.businessType.required`)),
                joinDate: Yup.string()
                    .typeError(i18n.get(`${FORM_ID}.joinDate.required`))
                    .required(i18n.get(`${FORM_ID}.joinDate.required`)),
                country: Yup.string().required(i18n.get(`${FORM_ID}.country.required`)),
                province: Yup.string().when("country", {
                    is: "PA",
                    then: Yup.string().required(i18n.get(`${FORM_ID}.province.required`)),
                    otherwise: Yup.string().notRequired(),
                }),
                stateProvince: Yup.string().when("country", {
                    is: "PA",
                    then: Yup.string().notRequired(),
                    otherwise: Yup.string().required(i18n.get(`${FORM_ID}.stateProvince.required`)),
                }),
                district: Yup.string().when("country", {
                    is: "PA",
                    then: Yup.string().required(i18n.get(`${FORM_ID}.district.required`)),
                }),
                corregiment: Yup.string().when("country", {
                    is: "PA",
                    then: Yup.string().required(i18n.get(`${FORM_ID}.corregiment.required`)),
                }),
                city: Yup.string().when("country", {
                    is: "PA",
                    then: Yup.string().notRequired(),
                    otherwise: Yup.string().required(i18n.get(`${FORM_ID}.city.required`)),
                }),
                street: Yup.string().required(i18n.get(`${FORM_ID}.street.required`)),
                postalCode: Yup.string().required(i18n.get(`${FORM_ID}.postalCode.required`)),
                residentialBuilding: Yup.string().required(i18n.get(`${FORM_ID}.residentialBuilding.required`)),
                floorApartmentHouse: Yup.string().required(i18n.get(`${FORM_ID}.floorApartmentHouse.required`)),
                // phone: Yup.object().shape({
                //     value: Yup.string().required(i18n.get(`${FORM_ID}.phone.required`)),
                // }),
                incomeReceivingMethod: Yup.string().required(i18n.get(`${FORM_ID}.incomeReceivingMethod.required`)),
                monthlyIncomeAmount: Yup.object().test(
                    "monthlyIncomeAmount",
                    i18n.get(`${FORM_ID}.monthlyIncomeAmount.required`),
                    (val) => !!val?.quantity && val?.quantity > 0 && !!val?.currency,
                ),
                otherIncomeControl: Yup.boolean(),
                otherIncomeSource: Yup.string().when("otherIncomeControl", {
                    is: true,
                    then: Yup.string().required(i18n.get(`${FORM_ID}.otherIncomeSource.required`)),
                    otherwise: Yup.string().notRequired(),
                }),
                otherMonthlyIncomeAmount: Yup.object().when("otherIncomeControl", {
                    is: true,
                    then: Yup.object().test(
                        "otherMonthlyIncomeAmount",
                        i18n.get(`${FORM_ID}.otherMonthlyIncomeAmount.required`),
                        (val) => !!val?.quantity && val?.quantity >= 0 && !!val?.currency,
                    ),
                }),
                referencePoint: Yup.string().when("country", {
                    is: "PA",
                    then: Yup.string().required(i18n.get(`${FORM_ID}.referencePoint.required`)),
                }),
            }),
        handleSubmit: (values, formikBag) => {
            const personalData = {
                ...values,
                monthlyIncomeAmount: { amount: values?.monthlyIncomeAmount?.quantity },
                otherMonthlyIncomeAmount: { amount: values?.otherMonthlyIncomeAmount?.quantity },
            };
            formikBag.props.dispatch(updateUserDataActions.saveForm(personalData, 3, formikBag));
        },
    }),
)(PersonalDataUpdateStep3);
