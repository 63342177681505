import classNames from "classnames";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import withExchangeToken from "pages/_components/withExchangeToken";
import Logo from "pages/login/_components/Logo";
import RemediationUserContentStep1 from "pages/RemediationUser/_components/RemediationUserContentStep1";
import { bool, func, string } from "prop-types";
import HeadSimple from "pages/_components/HeadSimple";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux/actions";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { compose } from "redux";

class RemediationUserStep1 extends Component {
    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
        dispatch(push("/"));
    };

    render() {
        const { isDesktop, userFirstName, isMobile } = this.props;

        return (
            <>
                {isDesktop && (
                    <HeadSimple
                        title="remediation.step1.title"
                        className="mb-2 mb-md-0"
                        textParams={{
                            NAME: userFirstName,
                        }}
                    />
                )}
                {!isDesktop && <Head logo={<Logo isDesktop={isDesktop} className="svg-image logo" />} />}
                <Notification scopeToShow="remediationUserStep1" />
                <MainContainer
                    className={classNames("main-container", {
                        "container-fluid with-special-header-login": isDesktop,
                    })}>
                    <RemediationUserContentStep1
                        className={classNames("app-default-main styled-2 no-max-height")}
                        isDesktop={isDesktop}
                        isMobile={isMobile}
                        userFirstName={userFirstName}
                    />
                </MainContainer>
            </>
        );
    }
}

RemediationUserStep1.propTypes = {
    isDesktop: bool.isRequired,
    userFirstName: string.isRequired,
    dispatch: func.isRequired,
    isMobile: bool,
};

RemediationUserStep1.defaultProps = {
    isMobile: false,
};

const mapStateToProps = (state) => ({
    userFirstName: loginSelectors.getUserFirstName(state),
});

export default compose(withRouter, connect(mapStateToProps))(withExchangeToken(RemediationUserStep1));
