import * as i18nUtils from "util/i18n";
import { store } from "../store";

const availableLanguages = ["en", "es"];

export const replaceParams = (message, params) =>
    Object.keys(params).reduce((str, key) => {
        const regex = new RegExp(`[#$]{${key}}`, "gi");
        return str.replace(regex, params[key]);
    }, message);

export const get = (id, defaultValue = null, params = {}) => {
    const { items } = store.getState().i18n;
    const finalId = id ? id.trim() : undefined;
    if (items && items[finalId] !== null && typeof items[finalId] !== "undefined") {
        return replaceParams(items[finalId], params);
    }

    return defaultValue !== null ? defaultValue : `*${finalId}*`;
};

export const getArray = (id, defaultValue = null, params = {}) => get(id, defaultValue, params).split("|");

export const getLang = () => store.getState().i18n.lang;

export const deviceLanguage = () => {
    const language = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

    return availableLanguages.find((item) => language.indexOf(item) > -1) || "es";
};

export const getI18Component = (type, lang, idActivity, idField, recursive = true) => {
    const index = idActivity?.lastIndexOf(".send");
    const msg = `${idActivity?.substring(0, index)}`;

    // esto es para q sigan funcioonando los form dinamicos
    const keyMessage = `forms.${msg}.${idField}.${type}`;
    const message = i18nUtils.get(keyMessage);
    if (message !== `*${keyMessage}*`) {
        // si existe la key
        return message;
    }

    const keyMessageOther = `${msg}.${idField}.${type}`;
    const messageOther = i18nUtils.get(keyMessageOther);
    if (messageOther !== `*${keyMessageOther}*`) {
        return messageOther;
    }

    const splitData = type.split("_");
    if (splitData) {
        return recursive ? getI18Component(splitData[0], lang, idActivity, idField, false) : null;
    }

    return null;
};
export const generateFieldForm = (type, idActivity, idField) => {
    const index = idActivity?.lastIndexOf(".send");
    const msg = `${idActivity?.substring(0, index)}`;
    return `forms.${msg}.${idField}.${type}`;
};
