import classNames from "classnames";
import { isPast, isSameDay } from "date-fns";
import isEmpty from "lodash/isEmpty";
import { resizableRoute } from "pages/_components/Resizable";
import moment from "moment";
import RadioButtonGroup from "pages/forms/_components/_fields/_commons/RadioButtonGroup";
import Message from "pages/forms/_components/_fields/_scheduler/Message";
import SchedulerModal from "pages/forms/_components/_scheduler/SchedulerModal";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React, { Component } from "react";
import * as dateUtils from "util/date";
import * as i18n from "util/i18n";
import * as schedulerUtils from "util/scheduler";
import { getTransactionKind } from "util/transaction";

const FORM_ID = "scheduler";
const ID_TODAY = schedulerUtils.TODAY;
const ID_SCHEDULED = schedulerUtils.CUSTOM;

class Scheduler extends Component {
    static propTypes = {
        data: shape({}).isRequired,
        field: shape({}).isRequired,
        form: shape({}).isRequired,
        renderRadioButtons: bool,
        radioInline: bool,
        disabled: bool,
        disabledToday: bool,
        disabledScheduled: bool,
        idActivity: string,
        isDesktop: bool.isRequired,
        buttonAction: func,
        displayNone: bool,
        closeModalAction: func,
        shouldRequestCredentials: bool,
        externalIsDisplayed: bool,
        propsForm: shape({}),
    };

    static defaultProps = {
        renderRadioButtons: false,
        radioInline: false,
        disabled: false,
        disabledToday: false,
        disabledScheduled: false,
        idActivity: "",
        buttonAction: () => {},
        displayNone: false,
        closeModalAction: () => {},
        shouldRequestCredentials: false,
        externalIsDisplayed: false,
        propsForm: {},
    };

    state = {
        errors: null,
        isDisplayed: false,
        externalIsDisplayed: false,
        isScheduled: false,
        dayTransfer: ID_TODAY,
        options: [],
        titleKind: "",
    };

    componentDidMount() {
        const { field, form, idActivity, disabledToday, disabledScheduled } = this.props;
        const titleKind = getTransactionKind(idActivity);
        const options = [
            {
                id: ID_TODAY,
                label: i18n.get(`scheduler.immediate.radiobutton.label.${titleKind}`),
                disabled: disabledToday,
            },
            {
                id: ID_SCHEDULED,
                label: i18n.get(`scheduler.schedule.radiobutton.label.${titleKind}`),
                disabled: disabledScheduled,
            },
        ];
        this.setState({ options, titleKind });
        if (field.value === null) {
            form.setFieldValue(field.name, {
                selectedOption: schedulerUtils.TODAY,
                valueDate: this.getDefaultValueDate(),
            });
        }
        if (field.value === null || field?.value?.selectedOption === schedulerUtils.TODAY) {
            this.setState((state) => ({ ...state, dayTransfer: ID_TODAY }));
        } else {
            this.setState((state) => ({ ...state, dayTransfer: ID_SCHEDULED, isScheduled: true }));
        }
    }

    componentDidUpdate(prevProps) {
        const { field, form } = this.props;
        const { field: prevField } = prevProps;
        if (field.value?.selectedOption !== prevField.value?.selectedOption) {
            if (field.value === null) {
                form.setFieldValue(field.name, {
                    selectedOption: schedulerUtils.TODAY,
                    valueDate: this.getDefaultValueDate(),
                });
            }

            if (field.value === null || field?.value?.selectedOption === schedulerUtils.TODAY) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState((state) => ({ ...state, dayTransfer: ID_TODAY, isScheduled: false }));
            } else {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState((state) => ({ ...state, dayTransfer: ID_SCHEDULED, isScheduled: true }));
            }
        }

        if (
            (this.state.isScheduled === undefined || this.state.isScheduled === false) &&
            this.state.dayTransfer !== ID_TODAY
        ) {
            this.setState({ dayTransfer: ID_TODAY });
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {
            form: { errors },
        } = props;

        const programErrors = Object.keys(errors)
            .filter((key) => key.includes("program@"))
            .reduce(
                (obj, key) => ({
                    ...obj,
                    [key]: errors[key],
                }),
                {},
            );

        let { externalIsDisplayed } = state;
        if (props.externalIsDisplayed !== externalIsDisplayed) {
            // eslint-disable-next-line prefer-destructuring
            externalIsDisplayed = props.externalIsDisplayed;
        }
        return {
            errors: programErrors,
            externalIsDisplayed,
        };
    }

    getDefaultValueDate() {
        const {
            data: { firstWorkingDate },
        } = this.props;

        return moment(firstWorkingDate, "YYYY-MM-DD");
    }

    handleClick = () => {
        this.setState((state) => ({ ...state, isDisplayed: true }));
    };

    handleErrors = () => {
        const { errors } = this.state;
        if (!isEmpty(errors)) {
            return (
                <div className="form-group has-error">
                    {Object.keys(errors).map((error) => (
                        <div className="form-group has-error">
                            <FieldError error={errors[error]} />
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    handleValueChange = (data) => {
        const { field, form, buttonAction } = this.props;
        if (data.frequency === schedulerUtils.ONCE) {
            form.setFieldValue(field.name, {
                ...field.value,
                selectedOption: data.frequency,
                valueDate: moment(data.startsOn),
                program: null,
            });
        } else {
            const newProgramData = {
                frequency: schedulerUtils.isCustom(data.frequency)
                    ? schedulerUtils.getCustomFrecuency(data.frequency, "frecuency")
                    : data.frequency, // one of DAY, WEEK, MONTH, YEAR
                frequencyValue: schedulerUtils.isCustom(data.frequency)
                    ? schedulerUtils.getCustomFrecuency(data.frequency, "frecuencyValue")
                    : "1",
                days: dateUtils.getDayFromDate(data.startsOn), // int that represents the days of the week used by the java class Days at the backend. Same class used by the calendar restriction
                day: data.startsOn.getDate(), // day of the month.
                occurrence: null, // occurrence=3 + days=4 every 3rd WEDNESDAY of the month
                startsOn: data.startsOn,
                lapse: data.isUntilFurtherNotice ? "UNLIMITED" : "UNTIL",
                number: data.lapse.number,
                date: data.lapse.date,
                isCustom: schedulerUtils.isCustom(data.frequency),
            };
            if (newProgramData.frequency === schedulerUtils.WEEK && newProgramData.isCustom) {
                newProgramData.days = data.days;
            }
            if (newProgramData.frequency === schedulerUtils.MONTH && data.occurrenceType === "occurrence") {
                newProgramData.occurrence = dateUtils.weekOfMonth(moment(data.startsOn));
                newProgramData.day = null;
            }
            form.setFieldValue(field.name, {
                ...field.value,
                selectedOption: newProgramData.frequency,
                program: newProgramData,
                valueDate: moment(data.startsOn),
            });
        }
        form.setTouched({ ...form.touched, [field.name]: true });
        this.setState((state) => ({ ...state, isScheduled: true, isDisplayed: false }));
        buttonAction(data);
    };

    handleCloseBottomSheetClick = () => {
        const { field, closeModalAction } = this.props;
        if (field.value?.selectedOption === "TODAY") {
            this.setState((state) => ({ ...state, dayTransfer: ID_TODAY, isDisplayed: false, isScheduled: false }));
        } else {
            this.setState((state) => ({ ...state, isDisplayed: false }));
        }
        closeModalAction();
    };

    handleOnCHangeRadioButton = (idOption) => {
        const { field, form } = this.props;
        if (idOption === ID_SCHEDULED) {
            this.setState((state) => ({ ...state, isDisplayed: true }));
        }
        if (idOption === ID_TODAY) {
            form.setFieldValue(field.name, {
                selectedOption: schedulerUtils.TODAY,
                valueDate: this.getDefaultValueDate(),
            });
            this.setState((state) => ({ ...state, isScheduled: false }));
        }
        this.setState((state) => ({ ...state, dayTransfer: idOption }));
    };

    editSchedule = () => {
        this.setState((state) => ({ ...state, isDisplayed: true }));
    };

    updatePastDate(value) {
        if (value && value?.valueDate) {
            const currentDate = new Date();
            if (isPast(value?.valueDate) && !isSameDay(currentDate.getTime(), value?.valueDate)) {
                const { form } = this.props;
                form.values.scheduler.valueDate = new Date();
            }
        }
    }

    renderButon = () => {
        const {
            field: { value },
        } = this.props;
        const { isDisplayed } = this.state;
        return (
            <>
                <span className="scheduler-message">
                    <Message value={value || { valueDate: "" }} />
                </span>
                <button
                    aria-haspopup="dialog"
                    className="btn btn-link btn-small"
                    onClick={this.handleClick}
                    aria-expanded={isDisplayed}
                    type="button">
                    <Image src="images/schedule.svg" className="svg-icon" />
                    {!value || value?.selectedOption !== schedulerUtils.TODAY ? (
                        <>
                            <I18n id="scheduler.editDate" componentProps={{ "aria-hidden": true }} />
                            <I18n id="scheduler.editDate.a11y" componentProps={{ className: "visually-hidden" }} />
                        </>
                    ) : (
                        <>
                            <I18n id="scheduler.schedule.label" componentProps={{ "aria-hidden": true }} />
                            <I18n id="scheduler.schedule.a11y" componentProps={{ className: "visually-hidden" }} />
                        </>
                    )}
                </button>
            </>
        );
    };

    renderRadioButonGroup = (radioInline) => {
        const { options, dayTransfer } = this.state;
        const { disabled } = this.props;

        return (
            <RadioButtonGroup
                className={classNames("d-flex", { "flex-row": radioInline })}
                inLineControl={false}
                name="eSignOption"
                onChange={this.handleOnCHangeRadioButton}
                options={options}
                value={dayTransfer}
                {...(radioInline && { radioButtonGroupFlex: true })}
                disabled={disabled}
            />
        );
    };

    render() {
        const {
            renderRadioButtons,
            data: { mode, programable, schedulable, allowRecurrent },
            field: { value },
            radioInline,
            isDesktop,
            displayNone,
            shouldRequestCredentials,
            propsForm,
        } = this.props;

        const { errors, isDisplayed, externalIsDisplayed, isScheduled, titleKind } = this.state;
        this.updatePastDate(value);

        if (programable || schedulable) {
            if (mode === "edit") {
                return (
                    <>
                        <SchedulerModal
                            idForm={FORM_ID}
                            handleCloseBottomSheetClick={this.handleCloseBottomSheetClick}
                            goBack={this.handleCloseBottomSheetClick}
                            onScheduleClick={this.handleValueChange}
                            value={value}
                            isDisplayed={isDisplayed || externalIsDisplayed}
                            shouldRequestCredentials={shouldRequestCredentials}
                            drawer
                            isScheduled={isScheduled}
                            titleKind={titleKind}
                            propsForm={propsForm}
                            {...(allowRecurrent === false && { allowRecurrent: false })}
                        />
                        <Box
                            display={displayNone ? "none" : "flex"}
                            column
                            background="white"
                            className={classNames("pt-7 pl-5 pl-lg-10 pr-5 pr-lg-10 mx-n-5", {
                                "pb-7": !isScheduled,
                                "pb-5": isScheduled,
                            })}
                            borderRadius="default">
                            <Box display="flex" alignY="center" alignX="between" fullWidth wrap>
                                <Box display="flex" alignY="center" column>
                                    <Box fullWidth>
                                        <FieldLabel
                                            labelKey={`scheduler.label.${titleKind}`}
                                            idField="readableID"
                                            labelClassName="color-heading"
                                        />
                                    </Box>
                                    <div className="form-group form-group--scheduler">
                                        <div className="form-group-text scheduler">
                                            {renderRadioButtons && this.renderRadioButonGroup(radioInline)}
                                            {!renderRadioButtons && this.renderButon()}
                                        </div>
                                    </div>
                                </Box>
                                {isScheduled && (
                                    <Button
                                        bsStyle="link"
                                        className="height-auto"
                                        label={
                                            isDesktop
                                                ? "scheduler.editButton.label"
                                                : "scheduler.editButton.label.mobile"
                                        }
                                        onClick={this.editSchedule}
                                    />
                                )}
                            </Box>
                            {!isEmpty(errors) && this.handleErrors()}
                            {isScheduled && (
                                <Box display="flex" column alignY="center" className="mt-auto pt-3" fullWidth>
                                    <Box display="flex" alignY="flex-end" alignX="center">
                                        <Text color="primary" size="6" className="mr-2" align="center">
                                            {schedulerUtils.getScheduleMessageStartDate(value, titleKind)}
                                        </Text>
                                    </Box>
                                    <Box display="flex" alignY="flex-end" alignX="center">
                                        <Text color="primary" size="6" className="mr-2" align="center">
                                            {schedulerUtils.getMessagePay(value)}
                                        </Text>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </>
                );
            }
        }

        return null;
    }
}

export default resizableRoute(Scheduler);
