import { useReducer } from "react";
import moment from "moment";

const initialState = {
    section1: {
        userFullName: undefined,
        userNationalityId: undefined,
        // userNationality: undefined,
    },
    section2: {
        birthDate: undefined,
        startDate: undefined,
        birthDateAsString: undefined,
        educationLevelId: undefined,
        maritalStatusId: undefined,
        professionOccupationId: undefined,
    },
    section3: {
        documentUserValue: undefined,
        issuedDate: undefined,
        issuedDateAsString: undefined,
        expirationDate: undefined,
        expirationDateAsString: undefined,
        minExpirationDate: undefined,
    },
    selectors: {
        maritalStatusList: undefined,
        educationLevelList: undefined,
        professionOccupationList: undefined,
        countryList: undefined,
        documentTypeList: [],
        idNumberMap1: [],
        idNumberMap2: [],
    },
    userNationality: undefined,
};

const setNewSection2 = (state, newSection2) => ({
    ...state,
    section2: newSection2,
});

const setNewSection3 = (state, newSection3) => ({
    ...state,
    section3: newSection3,
});

const reducer = (state, action) => {
    const newSection2 = state.section2;
    const newSection3 = state.section3;
    switch (action.type) {
        case "SET_SECTIONS": {
            const { PersonData, EducationLevel, MaritalStat, Profession } = action.payload;
            const { PersonName, Nationality, BirthDt, BirthPlace } = PersonData;
            const [year, month, day] = BirthDt.split("-");

            // Initial date to enable calendar (edit view)
            const startDateAux = new Date();
            const yearAsNumber = year ? parseInt(year, 2) : "";
            const monthAsNumber = month ? parseInt(month, 2) - 1 : "";
            const dayAsNumber = day ? parseInt(day, 2) : "";
            startDateAux.setFullYear(yearAsNumber, monthAsNumber, dayAsNumber);
            const birthDateAsString = BirthDt ? moment(BirthDt).format("DD/MM/YYYY") : "";

            return {
                ...state,
                section1: {
                    userFullName: PersonName?.FullName,
                    userNationalityId: Nationality,
                },
                section2: {
                    birthDate: BirthDt,
                    birthPlace: BirthPlace,
                    startDate: startDateAux,
                    birthDateAsString,
                    educationLevelId: EducationLevel,
                    maritalStatusId: MaritalStat?.Value,
                    professionOccupationId: Profession?.Value,
                },
            };
        }

        case "SET_PRE_DATA_STEP_1": {
            const { idData, civilStatusList, educationLevelList, professionList, documentData } = action.payload;
            if (documentData) {
                const { countryList, documentTypeList, idNumberMap1, idNumberMap2 } = documentData;
                let documentUserValue;
                let issuedDate;
                let issuedDateAsString;
                let expirationDate;
                let expirationDateAsString;
                let minExpirationDate;

                if (idData) {
                    const {
                        idCountry,
                        idType,
                        idNumber1,
                        idNumber2,
                        idDocument1,
                        idDocument2,
                        idIssuedDate,
                        idExpirationDate,
                    } = idData.issuedIdent;

                    const documentUser = {
                        documentCountry: idCountry,
                        documentType: idType,
                        idNumber1,
                        idNumber2,
                        documentNumber1: idDocument1,
                        documentNumber2: idDocument2,
                    };
                    documentUserValue = documentUser;

                    const idIssuedDateAsString = moment(idIssuedDate).format("DD/MM/YYYY");
                    issuedDate = idIssuedDate;
                    issuedDateAsString = idIssuedDateAsString;

                    const idExpirationDateAsString = idExpirationDate
                        ? moment(idExpirationDate).format("DD/MM/YYYY")
                        : "";
                    expirationDate = idExpirationDate;
                    expirationDateAsString = idExpirationDateAsString;

                    const minExpDate = idExpirationDate ? moment(idExpirationDate) : moment(new Date());
                    minExpirationDate = minExpDate;
                }
                return {
                    ...state,
                    section3: {
                        documentUserValue,
                        issuedDate,
                        issuedDateAsString,
                        expirationDate,
                        expirationDateAsString,
                        minExpirationDate,
                    },
                    selectors: {
                        maritalStatusList: civilStatusList,
                        educationLevelList,
                        professionOccupationList: professionList,
                        countryList: countryList || [],
                        documentTypeList: documentTypeList || [],
                        idNumberMap1: idNumberMap1 || [],
                        idNumberMap2: idNumberMap2 || [],
                        birthCountryList:
                            countryList?.map((country) => ({ value: country.id, label: country.name })) || [],
                    },
                };
            }
            return {
                ...state,
            };
        }

        case "SET_BIRTH_DATE": {
            newSection2.birthDate = action.payload;
            newSection2.birthDateAsString = moment(action.payload?.toDate()).format("DD/MM/YYYY");
            return setNewSection2(state, newSection2);
        }

        case "SET_BIRTH_PLACE": {
            newSection2.birthPlace = action.payload;
            return setNewSection2(state, newSection2);
        }

        case "SET_MARITAL_STATUS": {
            newSection2.maritalStatusId = action.payload;
            return setNewSection2(state, newSection2);
        }

        case "SET_EDUCATION_LEVEL": {
            newSection2.educationLevelId = action.payload;
            return setNewSection2(state, newSection2);
        }

        case "SET_PROFESSION_OCCUPATION": {
            newSection2.professionOccupationId = action.payload;
            return setNewSection2(state, newSection2);
        }

        case "SET_ISSUED_DATE": {
            newSection3.issuedDate = action.payload;
            newSection3.issuedDateAsString = moment(action.payload?.toDate()).format("DD/MM/YYYY");
            return setNewSection3(state, newSection3);
        }

        case "SET_EXPIRATION_DATE": {
            newSection3.expirationDate = action.payload;
            newSection3.expirationDateAsString = moment(action.payload?.toDate()).format("DD/MM/YYYY");
            return setNewSection3(state, newSection3);
        }

        case "SET_DOCUMENT_USER": {
            newSection3.documentUserValue = action.payload;
            return setNewSection3(state, newSection3);
        }

        case "SET_USER_NATIONALITY": {
            return {
                ...state,
                userNationality: action.payload,
            };
        }

        default:
            return state;
    }
};

const useCustomState = () => {
    const [customState, dispatch] = useReducer(reducer, initialState);

    const setCustomState = (action, data) => {
        if (action === "init") {
            dispatch({ type: "SET_SECTIONS", payload: data });
        } else if (action === "preDataStep1") {
            dispatch({ type: "SET_PRE_DATA_STEP_1", payload: data });
        } else {
            dispatch({ type: `SET_${action.toUpperCase()}`, payload: data });
        }
    };

    return { customState, setCustomState };
};

export default useCustomState;
