import classNames from "classnames";
import { Field } from "formik";
import FormTransition from "pages/forms/_components/FormTransition";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import FormattedAmount from "pages/_components/FormattedAmount";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as balancePurchaseSelectors } from "reducers/balancePurchase";
import { selectors as creditCardSelectors } from "reducers/creditCard";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import {
    PDFAmountField,
    PDFTextField,
    PDFAmountFieldError,
    PDFBalancePurchaseBeneficiaryField,
} from "pages/forms/customForms/PDFTicket";
import Notification from "pages/_components/Notification";
import { formatCreditCardNumber } from "util/creditCards";
import { numberFormat } from "util/number";
import moment from "moment";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import { validateTransferLocalForm } from "pages/frequentDestination/validation/BeneficiaryFormValidation";
import { isFromNotificationRedirection } from "util/general";
import Checkbox from "pages/_components/Checkbox";
import AttachmentField from "pages/_components/fields/AttachmentField";
import LinkText from "pages/settings/companyDataUpdate/_components/LinkText";
import BeneficiaryBalancePurchaseField from "./_customFields/BeneficiaryField/BeneficiaryBalancePurchaseField";
import { useGenericProps, useMetaData } from "./hooks/TransferInternalHooks";

export const ID_FORM = "balance.purchase.create";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

export const EDIT_MODE = "edit";
export const PREVIEW_MODE = "preview";
export const VIEW_MODE = "view";

const BalancePurchaseForm = (props) => {
    const {
        preDataForm,
        mode,
        isDesktop,
        dispatch,
        location,
        selectedPlan,
        transaction,
        creditCardList,
        fromOneTouchAccess,
    } = props;

    const [metadata] = useMetaData(preDataForm, ID_ACTIVITY);
    const [genericProp] = useGenericProps(props, ID_ACTIVITY);
    const notificationRedirection = isFromNotificationRedirection(location);

    const params = new URLSearchParams(location.search);
    const productId = params.get("creditCard") ?? transaction?.data?.productId;

    const backendReference = transaction?.data?.backendReference || transaction?.data?.reference;

    const getCreditCardLabel = () => {
        const card = creditCardList?.find((item) => item.idProduct === productId);
        return card ? `${card.franchise} ${card.numberMask}` : null;
    };

    useEffect(() => {
        if (mode === EDIT_MODE) {
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData: { productId } }));
        }
    }, [dispatch, mode, productId]);

    const showDisclaimer = () => (
        <Box background="background-secondary" borderRadius="default" className="p-3 pl-md-10 pr-md-7 py-md-5">
            <Box component="ul" className="pl-4">
                <Box component="li" className="pl-1 color-text-boton li-style-position-outside">
                    <Text labelKey={`forms.${ID_FORM}.selected.option.text1`} size="small" color="text-boton" />
                </Box>
                <Box component="li" className="pl-1 color-text-boton li-style-position-outside">
                    <Text labelKey={`forms.${ID_FORM}.selected.option.text2`} size="small" color="text-boton" />
                </Box>
            </Box>
        </Box>
    );

    const validateForm = (values) => {
        const messageRequired = i18n.get(DEFAULT_REQUIRED);

        const errors = validateTransferLocalForm(
            values,
            messageRequired,
            i18n.get("administration.users.emailIncorrectFormat"),
            i18n.get("administration.users.numberMaxLengthExceeded"),
            i18n.get("beneficiary.create.validation.creditCard.notFound"),
        );

        const { amount } = values;
        if (!amount) {
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.required`);
        }
        const { quantity, currency } = amount;
        if (!quantity || quantity === "" || !currency || currency === "") {
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.required`);
        }

        if (!values?.productId || values?.productId === "") {
            errors.productId = messageRequired;
        }

        if (!values?.apcReview || values?.apcReview === false) {
            errors.apcReview = messageRequired;
        }

        if (!values?.apcIdentification || values?.apcIdentification?.length < 1) {
            errors.apcIdentification = messageRequired;
        }

        if (!values?.apcAcccountStatement || values?.apcAcccountStatement?.length < 1) {
            errors.apcAcccountStatement = messageRequired;
        }

        return errors;
    };

    const onPrepareData = (setFieldValue) => {
        setFieldValue("apcReview", false);
        setFieldValue("apcIdentification", []);
        setFieldValue("apcAcccountStatement", []);
        const amount = {
            currency: selectedPlan?.amount.currency,
            quantity: selectedPlan?.amount.quantity + selectedPlan?.totalCommission.quantity,
        };

        const purchaseAmount = {
            currency: selectedPlan?.amount.currency,
            quantity: selectedPlan?.amount.quantity,
        };

        const montlyAmount = {
            currency: selectedPlan?.montlyPayment.currency,
            quantity: selectedPlan?.montlyPayment.amount,
        };

        setFieldValue("option", selectedPlan?.option);
        setFieldValue("commissionAmount", selectedPlan?.commission);
        setFieldValue("itbmsAmount", selectedPlan?.itbms);
        setFieldValue("totalCommission", selectedPlan?.totalCommission);
        setFieldValue("term", selectedPlan?.term);
        setFieldValue("amount", amount);
        setFieldValue("purchaseAmount", purchaseAmount);
        setFieldValue("montlyAmount", montlyAmount);
        setFieldValue("ratePercentage", selectedPlan?.ratePercentage);
        setFieldValue("productId", productId);
    };

    const onChangeTC = (setFieldValue) => {
        setFieldValue("beneficiary.productType.id", "TC");
        setFieldValue("beneficiary.productType.label", "TC");
    };

    const { decimalSeparator, thousandSeparator } = numberFormat();

    const getDataAmount = (amount) => ({
        decimalSeparator,
        precision: 2,
        thousandsSeparator: thousandSeparator,
        options: [
            {
                id: amount?.currency,
                label: amount?.currency ? i18n.get(`currency.label.${amount?.currency}`) : "",
            },
        ],
    });

    const getAmountNumberFormat = (amount) => {
        if (!amount) {
            return "";
        }

        const amontNumber = (Math.round(amount.quantity * 100) / 100).toFixed(2);
        return `${amount.currency} ${amontNumber}`;
    };

    const conditionsLink = configUtils.get("balance.purchase.link.conditions", "");

    const renderResumeField = (values, genericProps) => (
        <>
            <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                <Text size="6" align="center" bold className="data-amount" labelKey={`forms.${ID_FORM}.toPurchase`} />
            </Box>
            <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Amount}
                    data={mode === PREVIEW_MODE ? getDataAmount(values?.purchaseAmount) : getDataAmount(values?.amount)}
                    key="amount"
                    name="amount"
                    value={mode === PREVIEW_MODE ? values?.purchaseAmount : values?.amount}
                    idField="amount"
                    bigDataAmount
                />
            </Box>

            <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                {(backendReference || values?.backendReference) && (
                    <FormFieldsComponents.ReadTextCustom
                        {...genericProps}
                        value={backendReference || values?.backendReference}
                        label={`forms.${ID_FORM}.voucher.label`}
                        shouldRender={mode === "view" && (backendReference || values?.backendReference)}
                    />
                )}
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    value={
                        // eslint-disable-next-line no-nested-ternary
                        mode === "preview"
                            ? moment().format("DD/MM/YYYY")
                            : transaction?.idParentTransaction
                            ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                            : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                    }
                    label={`forms.${ID_FORM}.date.label`}
                />
                {mode === VIEW_MODE && (
                    <FormFieldsComponents.ReadTextCustom
                        {...genericProps}
                        label={`forms.${ID_FORM}.operationType.label`}
                        value={i18n.get(`activities.${ID_FORM}.send`)}
                    />
                )}
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    label={`forms.${ID_FORM}.from.label`}
                    value={getCreditCardLabel()}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    label={`forms.${ID_FORM}.beneficiaryName.label`}
                    value={values?.beneficiary?.name}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    label={`forms.${ID_FORM}.bankName.label`}
                    value={values?.beneficiary?.bank?.label}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    label={`forms.${ID_FORM}.creditCard.label`}
                    value={formatCreditCardNumber(values?.beneficiary?.number)}
                />
                {mode === VIEW_MODE && (
                    <FormFieldsComponents.ReadTextCustom
                        {...genericProps}
                        label={`forms.${ID_FORM}.balance.purchance.label`}
                        value={getAmountNumberFormat(values?.purchaseAmount)}
                    />
                )}
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    label={`forms.${ID_FORM}.totalCommission.label`}
                    value={getAmountNumberFormat(values?.commissionAmount)}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    label={`forms.${ID_FORM}.itbms.label`}
                    value={getAmountNumberFormat(values?.itbmsAmount)}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    label="creditCard.financing.plans.balance.purchase.plan.montly.payment"
                    value={getAmountNumberFormat(values?.montlyAmount)}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    label="creditCard.financing.plans.balance.purchase.plan.term"
                    value={`${values?.term} ${i18n.get("creditCard.financing.plans.balance.purchase.option.label")}`}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    label="creditCard.financing.plans.balance.purchase.plan.rate"
                    value={`${values?.ratePercentage}%`}
                />
                <FormFieldsComponents.ReadTextCustom
                    {...genericProps}
                    label={`forms.${ID_FORM}.aprovalTime.label`}
                    value={i18n.get(`forms.${ID_FORM}.aprovation.time`)}
                />
            </Box>
        </>
    );

    const renderEditMode = (setFieldValue, values, genericProps) => (
        <>
            <Row className="pb-7 pb-md-0">
                <Col xs={12} md={6}>
                    <Box display="flex" column gap="5">
                        <Box
                            background="white"
                            className="mt-7 mt-md-0 p-5 px-md-10 pt-md-8 pb-md-8"
                            borderRadius="default"
                            toBorderInMobile>
                            <Box fullWidth {...(!isDesktop && { className: "mb-2" })}>
                                <Text
                                    component="h2"
                                    labelKey={`forms.${ID_FORM}.selected.option.title`}
                                    size="4"
                                    color="primary"
                                    bold
                                />
                            </Box>

                            <Box display="flex" column>
                                <Box display="flex">
                                    <Text
                                        component="label"
                                        labelKey="creditCard.financing.plans.balance.purchase.plan.amount"
                                        size="5"
                                        color="heading"
                                        bold
                                        addColon
                                    />
                                    <FormattedAmount
                                        quantity={selectedPlan?.amount.quantity}
                                        currency={selectedPlan?.amount.currency}
                                    />
                                </Box>

                                <Box display="flex">
                                    <Text
                                        component="label"
                                        labelKey="creditCard.financing.plans.balance.purchase.plan.term"
                                        size="5"
                                        color="heading"
                                        bold
                                        addColon
                                    />
                                    <Text size="5" color="heading">
                                        {`${selectedPlan?.term} ${i18n.get(
                                            "creditCard.financing.plans.balance.purchase.option.label",
                                        )}`}
                                    </Text>
                                </Box>

                                <Box display="flex">
                                    <Text
                                        component="label"
                                        labelKey="creditCard.financing.plans.balance.purchase.plan.rate"
                                        size="5"
                                        color="heading"
                                        bold
                                        addColon
                                    />
                                    <Text size="5" color="heading">
                                        {selectedPlan?.rate}
                                    </Text>
                                </Box>

                                <Box display="flex">
                                    <Text
                                        component="label"
                                        labelKey="creditCard.financing.plans.balance.purchase.plan.montly.payment"
                                        size="5"
                                        color="heading"
                                        bold
                                        addColon
                                    />
                                    <FormattedAmount
                                        quantity={selectedPlan?.montlyPayment.amount}
                                        currency={selectedPlan?.montlyPayment.currency}
                                    />
                                </Box>

                                <Box display="flex">
                                    <Text
                                        component="label"
                                        labelKey="creditCard.financing.plans.balance.purchase.plan.commision"
                                        size="5"
                                        color="heading"
                                        bold
                                        addColon
                                    />
                                    <FormattedAmount
                                        quantity={selectedPlan?.commission.quantity}
                                        currency={selectedPlan?.commission.currency}
                                    />
                                </Box>
                                <Box display="flex">
                                    <Text
                                        component="label"
                                        labelKey={`forms.${ID_FORM}.itbms.label`}
                                        size="5"
                                        color="heading"
                                        bold
                                        addColon
                                    />
                                    <FormattedAmount
                                        quantity={selectedPlan?.itbms.quantity}
                                        currency={selectedPlan?.itbms.currency}
                                    />
                                </Box>
                            </Box>
                            {props.isMobile && <br />}
                            <Box className={props.isMobile ? "mt-0" : "mt-8"}>
                                <Text
                                    size={props.isMobile ? "8" : "7"}
                                    labelKey="creditCard.financing.plans.balance.purchase.plans.disclaimer"
                                    color="heading-color"
                                />
                            </Box>
                        </Box>

                        {isDesktop && showDisclaimer()}
                    </Box>
                </Col>
                <Col xs={12} md={6}>
                    <Box display="flex" column gap="3" fullHeight>
                        <Box
                            background="white"
                            className="p-5 py-md-8 px-md-9"
                            {...(!isDesktop && { column: true })}
                            borderRadius="default"
                            toBorderInMobile
                            flex1>
                            <Box display="flex" column gap="5">
                                <Box>
                                    <Text
                                        component="h3"
                                        labelKey={`forms.${ID_FORM}.credit.card.title`}
                                        size="4"
                                        color="primary"
                                        bold
                                    />
                                </Box>

                                <Col xs={12} md={6}>
                                    <Field
                                        {...genericProps}
                                        isLocalForm
                                        component={BeneficiaryBalancePurchaseField}
                                        key="beneficiary"
                                        name="beneficiary"
                                        idField="beneificary"
                                        bankList={preDataForm?.bankList || []}
                                        value={{
                                            ...values?.beneficiary,
                                            name: preDataForm?.beneficiaryName,
                                        }}
                                        onChange={() => onChangeTC(setFieldValue)}
                                        validateBankPermissions
                                        isBalancePurchase
                                        showHeaderComponent={false}
                                    />
                                </Col>
                                <Box display="flex" column gap="0">
                                    <Col xs={12} md={6} className="mt-2 mb-4">
                                        <Field
                                            idForm={ID_FORM}
                                            name="apcReview"
                                            component={Checkbox}
                                            labelText={`forms.${ID_FORM}.apcReview.label`}
                                            block={false}
                                            formGroup
                                            labelSize={isDesktop ? "7" : "6"}
                                            labelColor="heading-color"
                                        />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Field
                                            component={AttachmentField}
                                            name="apcAcccountStatement"
                                            dispatch={dispatch}
                                            labelKey={`forms.${ID_FORM}.apcAcccountStatement.label`}
                                            notificationScope={ID_FORM}
                                            labelSize={isDesktop ? "7" : "6"}
                                        />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Field
                                            component={AttachmentField}
                                            name="apcIdentification"
                                            dispatch={dispatch}
                                            labelKey={`forms.${ID_FORM}.apcIdentification.label`}
                                            notificationScope={ID_FORM}
                                            labelSize={isDesktop ? "7" : "6"}
                                        />
                                    </Col>
                                    <Box className="mt-3 ml-7">
                                        <LinkText
                                            linkLabel={`forms.${ID_FORM}.conditions.link`}
                                            link={conditionsLink}
                                            linkColor="secondary-hover-color"
                                            hasUnderline={false}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {!isDesktop && showDisclaimer()}
                    </Box>
                </Col>
            </Row>
        </>
    );

    const renderPreviewMode = (values, genericProps) => renderResumeField(values, genericProps);

    const renderViewMode = (values, genericProps) => renderResumeField(values, genericProps);

    const [init, setInit] = useState(false);

    const renderFields = (setFieldValue, values) => {
        if (!mode) {
            return <div />;
        }

        if ((init === undefined || init !== true) && mode === "edit") {
            setInit(true);
            onPrepareData(setFieldValue);
        }

        return (
            <>
                {mode === EDIT_MODE && renderEditMode(setFieldValue, values, genericProp)}
                {mode === PREVIEW_MODE && renderPreviewMode(values, genericProp)}
                {mode === VIEW_MODE && renderViewMode(values, genericProp)}
            </>
        );
    };

    const isFinished = useMemo(() => transaction?.idTransactionStatus === "FINISHED", [transaction]);

    const renderTicket = (values) => (
        <>
            {isFinished ? (
                <PDFAmountField
                    idForm={ID_FORM}
                    name="amount"
                    values={values}
                    label={i18n.get(`forms.${ID_FORM}.toPurchase`)}
                />
            ) : (
                <PDFAmountFieldError
                    idForm={ID_FORM}
                    name="amount"
                    values={values}
                    label={i18n.get(`forms.${ID_FORM}.toPurchase`)}
                />
            )}
            {isFinished && (
                <PDFTextField
                    idForm={ID_FORM}
                    name="backendReference"
                    value={backendReference || values?.backendReference}
                    label={i18n.get(`forms.${ID_FORM}.voucher.label`)}
                />
            )}
            <PDFTextField
                idForm={ID_FORM}
                value={
                    transaction?.idParentTransaction
                        ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                        : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                }
                label={i18n.get(`forms.${ID_FORM}.date.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                value={` ${i18n.get(`activities.${ID_FORM}.send`)}`}
                label={i18n.get(`forms.${ID_FORM}.operationType.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                value={getCreditCardLabel()}
                label={i18n.get(`forms.${ID_FORM}.from.label`)}
            />
            <PDFBalancePurchaseBeneficiaryField
                idForm={ID_FORM}
                name="beneficiary"
                values={values}
                creditCardLabel={i18n.get(`forms.${ID_FORM}.creditCard.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                value={getAmountNumberFormat(values?.purchaseAmount)}
                label={i18n.get(`forms.${ID_FORM}.balance.purchance.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                value={getAmountNumberFormat(values?.commissionAmount)}
                label={i18n.get(`forms.${ID_FORM}.totalCommission.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                value={getAmountNumberFormat(values?.itbmsAmount)}
                label={i18n.get(`forms.${ID_FORM}.itbms.label`)}
            />
            <PDFTextField
                idForm={ID_FORM}
                value={getAmountNumberFormat(values?.montlyAmount)}
                label={i18n.get("creditCard.financing.plans.balance.purchase.plan.montly.payment")}
            />
            <PDFTextField
                idForm={ID_FORM}
                value={`${values?.term} ${i18n.get("creditCard.financing.plans.balance.purchase.option.label")}`}
                label={i18n.get("creditCard.financing.plans.balance.purchase.plan.term")}
            />
            <PDFTextField
                idForm={ID_FORM}
                value={`${values?.ratePercentage}%`}
                label={i18n.get("creditCard.financing.plans.balance.purchase.plan.rate")}
            />
            <PDFTextField
                idForm={ID_FORM}
                label={i18n.get(`forms.${ID_FORM}.aprovalTime.label`)}
                value={i18n.get(`forms.${ID_FORM}.aprovation.time`)}
            />
        </>
    );

    const formProps = {
        title: `forms.${ID_FORM}`,
        metadata,
        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preDataForm,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validate: validateForm,
        showSchedulerMessage: false,
        topDisclaimer: "",
        showFilterChips: false,
        onlyBackToDesktopBtn: true,

        pathBackEditMode:
            (fromOneTouchAccess !== undefined && fromOneTouchAccess === true) || notificationRedirection
                ? "/desktop"
                : undefined,
    };

    return (
        <>
            <Notification scopeToShow={ID_FORM} />
            <FormTransition {...props} {...formProps} />
        </>
    );
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
    selectedPlan: balancePurchaseSelectors.getSelectedPlan(state),
    creditCardList: creditCardSelectors.getList(state),
});

BalancePurchaseForm.propTypes = {
    dispatch: func.isRequired,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    fromTransaction: bool,
    usesJointAccount: bool,
    fromOneTouchAccess: bool,
    selectedPlan: shape({}).isRequired,
    creditCardList: arrayOf(shape({})).isRequired,
    isMobile: bool.isRequired,
};

BalancePurchaseForm.defaultProps = {
    fromBackoffice: false,
    mode: "",
    preDataForm: {},
    previewData: {},
    transaction: {},
    location: {},
    fromTransaction: false,
    usesJointAccount: false,
    fromOneTouchAccess: false,
};

export default compose(connect(mapStateToProps), withRouter)(BalancePurchaseForm);
