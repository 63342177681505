import React, { Component } from "react";
import { shape, node, string, func } from "prop-types";

import * as i18nUtils from "util/i18n";

import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import LoansListItem from "pages/loans/ListItem";
import WidgetList from "pages/desktop/widgets/WidgetList";
import WithPermissions from "pages/_components/WithPermissions";
import * as config from "util/config";
import { selectors as sessionSelectors } from "reducers/session";
import { connect } from "react-redux";
import { resizableRoute } from "pages/_components/Resizable";
import { actions as productRequestActions } from "reducers/productRequest";
import WidgetHeader from "./_components/WidgetHeader";

class Loans extends Component {
    static propTypes = {
        closeButton: node,
        draggableItemProps: shape({}).isRequired,
        loggedUser: shape({
            bankType: string,
        }),
        dispatch: func.isRequired,
    };

    static defaultProps = {
        closeButton: null,
        loggedUser: null,
    };

    render() {
        const { closeButton, draggableItemProps, dispatch } = this.props;
        const uiAutomationProp = { "data-ui-automation": "widgetLoans" };
        const itemsToShowButton = Number(config.get("widgets.product.maxToShow"));

        // const { bankType } = loggedUser;
        // const requestLoan = config.get(`loans.requestLoan.byBankType.${bankType}`);

        return (
            <WidgetList item={LoansListItem} keyExtractor={(item) => item.idProduct} name="loans">
                {(list, listLenght) => (
                    <section
                        className="widget"
                        {...uiAutomationProp}
                        {...draggableItemProps}
                        aria-roledescription={i18nUtils.get("desktop.widgets.message.roleDescription")}>
                        {listLenght > 0 && (
                            <WidgetHeader title={i18nUtils.get("list.addWidget.loans")} action={closeButton} />
                        )}
                        {list}
                        <WithPermissions permissions={["loans"]}>
                            <Box display="flex" alignX="between" fullWidth flex1 className="px-md-3">
                                <WithPermissions permissions={["requestLoan"]}>
                                    {/* {requestLoan ? (
                                        <Button
                                            href="/loansPayment/request/"
                                            image="/images/plusCircle.svg"
                                            label="widgets.loans.requestLoan"
                                            bsStyle="link"
                                            className="btn-widget"
                                        />
                                    ) : null} */}
                                    <Button
                                        image="/images/plusCircle.svg"
                                        label="widgets.loans.requestLoan"
                                        bsStyle="link"
                                        className="btn-widget"
                                        onClick={() => {
                                            dispatch(productRequestActions.modalShow("LO"));
                                        }}
                                    />
                                </WithPermissions>
                                {listLenght > itemsToShowButton ? (
                                    <Button
                                        className="btn-widget"
                                        href="/loans"
                                        label="widgets.loans.myLoans"
                                        bsStyle="link"
                                    />
                                ) : null}
                            </Box>
                        </WithPermissions>
                        <div className="overlay" />
                    </section>
                )}
            </WidgetList>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(resizableRoute(Loans));
