import * as API from "middleware/api";

export const listUserDataUpdateRequest = () => API.executeWithAccessToken("/userData.update.request.admin.pre", {});

export const sendUserDataUpdateRequestAdmin = (status, selectedUsers, otp) =>
    API.executeWithAccessToken("/userData.update.request.admin.send", { status, selectedUsers, _otp: otp });

export const loadUserDataUpdateRequest = (exchangeToken) =>
    API.executeWithExchangeToken("/userData.update.request.pre", {}, exchangeToken);

export const sendUserDataUpdateRequest = (
    name,
    lastName,
    email,
    mobilePhone,
    documentUser,
    documentNumber,
    dataTreatment,
    exchangeToken,
) =>
    API.executeWithExchangeToken(
        "/userData.update.request.send",
        { name, lastName, email, mobilePhone, documentUser, documentNumber, dataTreatment },
        exchangeToken,
    );
