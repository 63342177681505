import React, { useCallback, useMemo } from "react";
import { bool, func, shape, string } from "prop-types";
import classNames from "classnames";
import Text from "pages/_components/Text";
import FieldError from "pages/_components/fields/FieldError";
import Image from "pages/_components/Image";
import Box from "./Box";

const RadioToggle = React.memo(
    ({
        name,
        labelTextYes,
        labelTextNo,
        onChange,
        field,
        form,
        idForm,
        labelTextYesProps,
        labelTextNoProps,
        labelColor,
        labelSize,
        className,
        noLabel,
        disabled,
        topFixed,
        gap,
        relativeStyleError,
    }) => {
        const controlName = field?.name || name;

        const hasError = useMemo(() => {
            if (!form || !field) {
                return false;
            }
            const { errors } = form;
            const [namespace, key] = field.name.split(".");
            const errorExists = errors?.[namespace]?.[key] || errors?.[field.name];
            return !!errorExists;
        }, [form, field]);

        const error = useMemo(() => {
            if (!form || !field) {
                return "";
            }
            const { errors } = form;
            const [namespace, key] = field.name.split(".");
            return errors?.[namespace]?.[key] || errors?.[field.name] || "";
        }, [form, field]);

        const handleChange = useCallback(
            (value) => {
                if (field && form) {
                    form.setFieldValue(field.name, value);
                }
                onChange(value);
            },
            [field, form, onChange],
        );

        const renderLabel = useCallback(
            (labelText, labelProps) =>
                !noLabel && (
                    <div className="c-control-text">
                        {labelText ? (
                            <Text {...labelProps} labelKey={labelText} color={labelColor} size={labelSize} />
                        ) : (
                            field && (
                                <Text labelKey={`${idForm}.${field?.name}.label`} color={labelColor} size={labelSize} />
                            )
                        )}
                    </div>
                ),
            [field, idForm, labelColor, labelSize, noLabel],
        );

        const renderOption = useCallback(
            (value, labelText, labelProps) => (
                <div className="mt-5 display-flex align-items-center position-relative mr-10">
                    <input
                        id={`checkbox_${controlName}_${value}`}
                        className="c-control-input mr-3"
                        type="radio"
                        value={value}
                        name={controlName}
                        disabled={disabled}
                        onChange={() => handleChange(value)}
                    />
                    <label className="c-control-label m-0" htmlFor={`checkbox_${controlName}_${value}`}>
                        <div className="c-control-icons">
                            <div className="c-control-mark">
                                <Image src="images/icons/checkBox.svg" className="svg-icon svg-caret" />
                            </div>
                        </div>
                        {renderLabel(labelText, labelProps)}
                    </label>
                </div>
            ),
            [className, controlName, disabled, gap, handleChange, hasError, renderLabel, topFixed],
        );

        return (
            <Box display="flex">
                <div
                    className={classNames(
                        "c-control",
                        "c-control--has-icon c-control--checkbox display-flex flex-column align-items-center position-relative mr-5",
                        className,
                        {
                            "has-error": hasError,
                            "top-fixed": topFixed,
                            [`gap-${gap}`]: gap,
                        },
                    )}>
                    <div className="display-flex">
                        {renderOption("Y", labelTextYes, labelTextYesProps)}
                        {renderOption("N", labelTextNo, labelTextNoProps)}
                    </div>
                    {hasError && (
                        <FieldError error={error} className="top-100" relativeStyleError={relativeStyleError} />
                    )}
                </div>
            </Box>
        );
    },
);

RadioToggle.propTypes = {
    name: string,
    labelTextYes: string,
    labelTextNo: string,
    onChange: func,
    field: shape({
        onBlur: func,
        onChange: func,
        name: string,
        value: bool,
    }),
    idForm: string,
    form: shape({
        touched: shape({}),
        errors: shape({}),
        setFieldValue: func,
    }),
    labelTextYesProps: shape({}),
    labelTextNoProps: shape({}),
    labelColor: string,
    labelSize: string,
    className: string,
    noLabel: bool,
    disabled: bool,
    topFixed: bool,
    gap: string,
    relativeStyleError: bool,
};

RadioToggle.defaultProps = {
    name: "",
    labelTextYes: "global.yes",
    labelTextNo: "global.no",
    onChange: () => {},
    field: null,
    form: null,
    idForm: "",
    labelTextYesProps: {},
    labelTextNoProps: {},
    labelColor: "",
    labelSize: null,
    className: "",
    noLabel: false,
    disabled: false,
    topFixed: false,
    gap: "3",
    relativeStyleError: false,
};

export default RadioToggle;
