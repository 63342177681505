import { createReducer, makeActionCreator } from "util/redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";

export const types = {
    LIST_USER_DATA_UPDATE_REQUEST: "userDataUpdateRequest/LIST_USER_DATA_UPDATE_REQUEST",
    LIST_USER_DATA_UPDATE_SUCCESS: "userDataUpdateRequest/LIST_USER_DATA_UPDATE_SUCCESS",
    LIST_USER_DATA_UPDATE_FAILED: "userDataUpdateRequest/LIST_USER_DATA_UPDATE_FAILED",

    USER_DATA_UPDATE_SEND_ADMIN_REQUEST: "userDataUpdateRequest/USER_DATA_UPDATE_SEND_ADMIN_REQUEST",
    USER_DATA_UPDATE_SEND_ADMIN_FAILED: "userDataUpdateRequest/USER_DATA_UPDATE_SEND_ADMIN_FAILED",
    USER_DATA_UPDATE_SEND_ADMIN_SUCCESS: "userDataUpdateRequest/USER_DATA_UPDATE_SEND_ADMIN_SUCCESS",

    USER_DATA_UPDATE_GROUPS_REQUEST: "userDataUpdateRequest/USER_DATA_UPDATE_GROUPS_REQUEST",
    USER_DATA_UPDATE_GROUPS_SUCCESS: "userDataUpdateRequest/USER_DATA_UPDATE_GROUPS_SUCCESS",

    MODAL_SHOW: "userDataUpdateRequest/MODAL_SHOW",
    MODAL_CLOSE: "userDataUpdateRequest/MODAL_CLOSE",
    IS_NEED_SIGNED_BY_ADMIN: "userDataUpdateRequest/IS_NEED_SIGNED_BY_ADMIN",

    USER_DATA_UPDATE_PRE_REQUEST: "userDataUpdateRequest/USER_DATA_UPDATE_PRE_REQUEST",
    USER_DATA_UPDATE_PRE_FAILED: "userDataUpdateRequest/USER_DATA_UPDATE_PRE_FAILED",
    USER_DATA_UPDATE_PRE_SUCCESS: "userDataUpdateRequest/USER_DATA_UPDATE_PRE_SUCCESS",

    USER_DATA_UPDATE_SEND_REQUEST: "userDataUpdateRequest/USER_DATA_UPDATE_SEND_REQUEST",
    USER_DATA_UPDATE_SEND_FAILED: "userDataUpdateRequest/USER_DATA_UPDATE_SEND_FAILED",
    USER_DATA_UPDATE_SEND_SUCCESS: "userDataUpdateRequest/USER_DATA_UPDATE_SEND_SUCCESS",
};

export const INITIAL_STATE = {
    isFetching: false,
    displayModal: false,
    listUserData: [],
    listEnvironments: [],
    credentialsGroups: [],
    hasCompleteCredentialGroups: false,
    countryList: [],
    documentTypeList: [],
    idNumberMap1: {},
    idNumberMap2: {},
    exchangeToken: null,
    isNeedSignedByAdmin: false,
};

const reducer = createReducer(INITIAL_STATE, {
    [types.LIST_USER_DATA_UPDATE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        listUserData: [],
        listEnvironments: [],
        countryList: [],
    }),

    [types.LIST_USER_DATA_UPDATE_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        listUserData: action.listUserData,
        listEnvironments: action.listEnvironments,
        countryList: action.countryList,
    }),

    [types.LIST_USER_DATA_UPDATE_FAILED]: (state) => ({
        ...state,
        isFetching: false,
        listUserData: [],
        listEnvironments: [],
        countryList: [],
    }),

    [types.USER_DATA_UPDATE_SEND_ADMIN_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.USER_DATA_UPDATE_SEND_ADMIN_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        isNeedSignedByAdmin: action.isNeedSignedByAdmin,
    }),
    [types.USER_DATA_UPDATE_SEND_ADMIN_FAILED]: (state) => ({
        ...state,
        isFetching: false,
    }),

    [types.USER_DATA_UPDATE_GROUPS_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        credentialsGroups: action.credentialsGroups,
        hasCompleteCredentialGroups: true,
    }),
    [types.USER_DATA_UPDATE_GROUPS_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        hasCompleteCredentialGroups: false,
    }),

    [types.MODAL_SHOW]: (state) => ({
        ...state,
        displayModal: true,
    }),
    [types.MODAL_CLOSE]: (state) => ({
        ...state,
        displayModal: false,
    }),
    [types.IS_NEED_SIGNED_BY_ADMIN]: (state, action) => ({
        ...state,
        isNeedSignedByAdmin: action.isNeedSignedByAdmin,
    }),

    [types.USER_DATA_UPDATE_PRE_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
        countryList: [],
        documentTypeList: [],
        idNumberMap1: {},
        idNumberMap2: {},
    }),
    [types.USER_DATA_UPDATE_PRE_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        countryList: action.countryList,
        documentTypeList: action.documentTypeList,
        idNumberMap1: action.idNumberMap1,
        idNumberMap2: action.idNumberMap2,
        exchangeToken: action._exchangeToken,
    }),
    [types.USER_DATA_UPDATE_PRE_FAILED]: (state) => ({
        ...state,
        isFetching: false,
    }),

    [types.USER_DATA_UPDATE_SEND_REQUEST]: (state) => ({
        ...state,
        isFetching: true,
    }),
    [types.USER_DATA_UPDATE_SEND_SUCCESS]: (state, action) => ({
        ...state,
        isFetching: false,
        countryList: action.countryList,
        documentTypeList: action.documentTypeList,
        idNumberMap1: action.idNumberMap1,
        idNumberMap2: action.idNumberMap2,
    }),
    [types.USER_DATA_UPDATE_SEND_FAILED]: (state) => ({
        ...state,
        isFetching: false,
    }),
});

export default persistReducer(
    {
        key: "userDataUpdateRequest",
        storage: storageSession,
        whitelist: ["isNeedSignedByAdmin"],
    },
    reducer,
);

export const actions = {
    listUserDataUpdateRequest: makeActionCreator(types.LIST_USER_DATA_UPDATE_REQUEST),
    listUserDataUpdateRequestSuccess: makeActionCreator(
        types.LIST_USER_DATA_UPDATE_SUCCESS,
        "listUserData",
        "listEnvironments",
        "countryList",
    ),
    listUserDataUpdateRequestFailed: makeActionCreator(types.LIST_USER_DATA_UPDATE_FAILED),

    sendDataUserDataUpdateRequestAdminSend: makeActionCreator(
        types.USER_DATA_UPDATE_SEND_ADMIN_REQUEST,
        "status",
        "selectedUsers",
        "otp",
        "onFinish",
        "formikBag",
        "isNeedSignedByAdmin",
    ),
    sendDataUserDataUpdateRequestAdminSendSuccess: makeActionCreator(
        types.USER_DATA_UPDATE_SEND_ADMIN_SUCCESS,
        "isNeedSignedByAdmin",
    ),
    sendDataUserDataUpdateRequestAdminSendFailed: makeActionCreator(types.USER_DATA_UPDATE_SEND_ADMIN_FAILED),

    credentialsGroupsRequest: makeActionCreator(types.USER_DATA_UPDATE_GROUPS_REQUEST, "data"),
    credentialsGroupsSuccess: makeActionCreator(types.USER_DATA_UPDATE_GROUPS_SUCCESS, "credentialsGroups"),

    modalShow: makeActionCreator(types.MODAL_SHOW),
    modalHide: makeActionCreator(types.MODAL_CLOSE),

    loadDataUserDataUpdateRequestPre: makeActionCreator(types.USER_DATA_UPDATE_PRE_REQUEST),
    loadDataUserDataUpdateRequestPreSuccess: makeActionCreator(
        types.USER_DATA_UPDATE_PRE_SUCCESS,
        "countryList",
        "documentTypeList",
        "idNumberMap1",
        "idNumberMap2",
        "_exchangeToken",
    ),
    loadDataUserDataUpdateRequestPreFailed: makeActionCreator(types.USER_DATA_UPDATE_PRE_FAILED),

    sendDataUserDataUpdateRequestSend: makeActionCreator(
        types.USER_DATA_UPDATE_SEND_REQUEST,
        "name",
        "lastName",
        "email",
        "mobilePhone",
        "documentUser",
        "dataTreatment",
        "formikBag",
    ),
    sendDataUserDataUpdateRequestSendSuccess: makeActionCreator(types.USER_DATA_UPDATE_SEND_SUCCESS),
    sendDataUserDataUpdateRequestSendFailed: makeActionCreator(types.USER_DATA_UPDATE_SEND_FAILED),
};

export const selectors = {
    getFetching: ({ userDataUpdateRequest }) => userDataUpdateRequest.isFetching,
    getDisplayModal: ({ userDataUpdateRequest }) => userDataUpdateRequest?.displayModal,
    getListUserData: ({ userDataUpdateRequest }) => userDataUpdateRequest?.listUserData,
    getListEnvironments: ({ userDataUpdateRequest }) => userDataUpdateRequest?.listEnvironments,
    getCredentialsGroups: ({ userDataUpdateRequest }) => userDataUpdateRequest?.credentialsGroups,
    hasCompleteCredentialGroups: ({ userDataUpdateRequest }) => userDataUpdateRequest?.hasCompleteCredentialGroups,
    getCountryList: ({ userDataUpdateRequest }) => userDataUpdateRequest?.countryList,
    getDocumentTypeList: ({ userDataUpdateRequest }) => userDataUpdateRequest?.documentTypeList,
    getIdNumberMap1: ({ userDataUpdateRequest }) => userDataUpdateRequest?.idNumberMap1,
    getIdNumberMap2: ({ userDataUpdateRequest }) => userDataUpdateRequest?.idNumberMap2,
    getExchangeToken: ({ userDataUpdateRequest }) => userDataUpdateRequest?.exchangeToken,
    isNeedSignedByAdmin: ({ userDataUpdateRequest }) => userDataUpdateRequest?.isNeedSignedByAdmin,
};
