import { Field } from "formik";
import moment from "moment";
import BeneficiaryField from "pages/forms/customForms/_customFields/BeneficiaryField";
import { validateTransferForeignForm } from "pages/frequentDestination/validation/BeneficiaryFormValidation";
import Box from "pages/_components/Box";
import PageLoading from "pages/_components/PageLoading";
import { bool, func, shape, string } from "prop-types";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { selectors as bankSelectors } from "reducers/bankSelector";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import { calculateShortLabel } from "util/account";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import { statusMap } from "util/general";
import FormTransition from "../_components/FormTransition";
import FromComponent from "./foreignTransfer/FromComponent";
import TransactionalProfileInfo from "./foreignTransfer/TransactionalProfileInfo";
import { generateIdField } from "./foreignTransfer/SwitfBankSelector";
import { generateExchangeRateLabel, useExchangeRateValue } from "./hooks/TransferCustomHooks";
import { PDFAmountField, PDFAmountFieldError, PDFTextField } from "./PDFTicket";
import { BENEFICIARY_NAME_DATE, BENEFICIARY_THREE_NAME, IBAN_NUMBER } from "./_customFields/BeneficiaryField/Constants";

export const ID_FORM = "transfers.foreign";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const TransferForeignForm = (props) => {
    const { mode, dispatch, location, preDataForm, transaction, fromOneTouchAccess, previewData } = props;

    /**
     * state exchange data
     */
    const [exchageData, setExchangeData] = useExchangeRateValue(undefined);
    const [currentAmount, setCurrentAmount] = useState(undefined);
    const [currentDebitAccount, setCurrentDebitAccount] = useState(undefined);

    const [commission, setCommission] = useState({ currency: "", quantity: 0 });
    const [tax, setTax] = useState({ currency: "", quantity: 0 });
    const [estimatedAmount, setEstimatedAmount] = useState({ currency: "", quantity: 0 });
    const [totalAmountEstimated, setTotalAmountEstimated] = useState({ currency: "", quantity: 0 });
    const [ratesUrl, setRatesUrl] = useState(undefined);

    useEffect(() => {
        if (mode === "edit") {
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData: {} }));
        }
        // eslint-disable-next-line
    }, [dispatch, location]);

    const renderFields = (setFieldValue, values) => {
        const { currentLang, fromBackoffice } = props;
        const idTransaction = transaction?.data?.backendReference;
        const debitAccountOrigin = parse(location.search)?.debitAccount;
        setCurrentAmount(values?.amount);
        setCurrentDebitAccount(values?.debitAccountData);

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        if (
            mode &&
            mode === "edit" &&
            (!preDataForm ||
                !preDataForm.currencyList ||
                !preDataForm.debitAccountList ||
                !preDataForm.countryList ||
                !preDataForm.documentTypeList)
        ) {
            return (
                <Box background="white" className="py-5">
                    <PageLoading loading />
                </Box>
            );
        }

        const preData = preDataForm || {
            currencyList: [],
            debitAccountList: [],
            defaultCountry: "UY",
            countryList: [],
            documentTypeList: [],
        };

        const codeBankList = preData?.codeBankList?.map((item) => ({ id: item, label: item }));
        const productTypeList =
            preData?.beneficiaryProductTypeList?.map((item) => ({
                id: item?.id || "",
                label: i18n.get(`forms.${ID_FORM}.productTypeList.option.${item?.id}`) || "",
            })) || [];
        const relationshipTypeList =
            preData?.beneficiaryRelationList?.map((item) => ({
                id: item?.id || "",
                label: i18n.get(`forms.${ID_FORM}.relationshipTypeList.option.${item?.id}`) || "",
            })) || [];
        const countryList = preData?.countryList?.map((country) => ({ id: country.id, label: country.name }));
        setRatesUrl(preData?.ratesUrl);
        return (
            <>
                <FromComponent
                    genericProps={genericProps}
                    preData={preData}
                    previewData={previewData}
                    debitAccountOrigin={debitAccountOrigin}
                    values={values}
                    mode={mode}
                    currentLang={currentLang}
                    transaction={transaction}
                    idForm={ID_FORM}
                    setFieldValue={setFieldValue}
                    exchageData={exchageData}
                    setExchangeData={setExchangeData}
                    currentAmount={currentAmount}
                    currentDebitAccount={currentDebitAccount}
                    setFieldValueCustom={setFieldValue}
                    commission={commission}
                    setCommission={setCommission}
                    tax={tax}
                    setTax={setTax}
                    totalAmountEstimated={totalAmountEstimated}
                    setTotalAmountEstimated={setTotalAmountEstimated}
                    estimatedAmount={estimatedAmount}
                    setEstimatedAmount={setEstimatedAmount}
                    ratesUrl={ratesUrl}
                    setRatesUrl={setRatesUrl}
                    bankSelectedCountryId={values?.beneficiary?.bankCountry}
                    intermediaryBankSelectedCountryId={values?.beneficiary?.intermediary_bankCountry}
                />

                <Field
                    {...genericProps}
                    isForeignForm
                    component={BeneficiaryField}
                    key="beneficiary"
                    name="beneficiary"
                    idField="beneificary"
                    productTypeList={productTypeList || []}
                    value={
                        values?.beneficiary || {
                            bankSelector: codeBankList[0],
                            intermediary_bankSelector: codeBankList[0],
                            beneficiaryOption: BENEFICIARY_THREE_NAME,
                            birthDateFrom: new Date(),
                            accountType: IBAN_NUMBER,
                        }
                    }
                    codeBankList={codeBankList || []}
                    relationshipTypeList={relationshipTypeList}
                    countryList={countryList}
                    genericProps={genericProps}
                />
                {mode === "preview" && (values?.showApprovalTransfer === "1" || values?.profileCountryAdd) && (
                    <TransactionalProfileInfo ID_FORM={ID_FORM} values={values} />
                )}
            </>
        );
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
        // eslint-disable-next-line react/prop-types
    } = props.preData || {};

    const validateForm = (values) => {
        const messageRquired = i18n.get(DEFAULT_REQUIRED);

        /**
         * Validate beneficiary
         */

        const errors = validateTransferForeignForm(values, i18n, configUtils);
        /**
         * Validate amount
         */
        const { amount, debitAccount, debitReference } = values;
        if (!amount) {
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.required`);
        }
        const { quantity, currency } = amount;
        if (!quantity || quantity === "" || !currency || currency === "") {
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.required`);
        }

        /**
         * validate account
         *
         */

        if (!debitAccount || debitAccount === "") {
            errors.debitAccount = messageRquired;
        }

        if (!debitReference || debitReference.length === 0) {
            errors.debitReference = messageRquired;
        }

        const countDebitReference = debitReference?.reduce((acc, item) => {
            if (!item || item === "") {
                // eslint-disable-next-line no-param-reassign
                acc += 1;
            }
            return acc;
        }, 0);

        if (countDebitReference > 0) {
            errors.debitReference = messageRquired;
        }
        return errors;
    };

    const getTicketLabelNameBeneficiary = (beneficiary) => {
        const renderDate =
            beneficiary.beneficiaryOption &&
            beneficiary.beneficiaryOption === BENEFICIARY_NAME_DATE &&
            beneficiary.birthDateFrom
                ? moment(beneficiary.birthDateFrom).format("DD/MM/YYYY") || ""
                : "";
        return `${beneficiary.name || ""} ${renderDate}`;
    };

    const generateLabelBank = (beneficiary, idComponent) => {
        const codeBank = beneficiary[generateIdField("bankSelector", idComponent)]?.id || "";
        return `${i18n.get(`forms.${ID_FORM}.beneficiary.bankSelector.label_preview`)} ${codeBank}`;
    };
    const renderTicketBankSelector = (beneficiary, idComponent) => (
        <>
            {Object.keys(beneficiary).includes(generateIdField("swiftCode", idComponent)) && (
                <PDFTextField
                    idForm={ID_FORM}
                    value={beneficiary[generateIdField("swiftCode", idComponent)] || ""}
                    label={generateLabelBank(beneficiary, idComponent)}
                />
            )}

            {Object.keys(beneficiary).includes(generateIdField("bankName", idComponent)) && (
                <PDFTextField
                    idForm={ID_FORM}
                    value={beneficiary[generateIdField("bankName", idComponent)] || ""}
                    label={i18n.get(`forms.${ID_FORM}.beneficiary.bankName.label_preview`)}
                />
            )}
            {Object.keys(beneficiary).includes(generateIdField("bankAddress", idComponent)) && (
                <PDFTextField
                    idForm={ID_FORM}
                    value={beneficiary[generateIdField("bankAddress", idComponent)] || ""}
                    label={i18n.get(`forms.${ID_FORM}.beneficiary.bankAddress.label_preview`)}
                />
            )}
            {Object.keys(beneficiary).includes(generateIdField("bankCity", idComponent)) && (
                <PDFTextField
                    idForm={ID_FORM}
                    value={beneficiary[generateIdField("bankCity", idComponent)] || ""}
                    label={i18n.get(`forms.${ID_FORM}.beneficiary.bankCity.label_preview`)}
                />
            )}
            {Object.keys(beneficiary).includes(generateIdField("bankCountryName", idComponent)) && (
                <PDFTextField
                    idForm={ID_FORM}
                    value={beneficiary[generateIdField("bankCountryName", idComponent)] || ""}
                    label={i18n.get(`forms.${ID_FORM}.beneficiary.bankCountry.label_preview`)}
                />
            )}
        </>
    );

    const renderTicketBeneficiary = (values) => {
        const beneficiary = values?.beneficiary;
        if (!beneficiary) {
            return <div />;
        }
        return (
            <>
                {beneficiary.productType && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={beneficiary.productType.label || ""}
                        label={i18n.get("forms.transfers.foreign.productType.label_preview")}
                    />
                )}
                {beneficiary.relationshipType && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={beneficiary.relationshipType.label}
                        label={i18n.get("forms.transfers.foreign.beneficiaryRelationshipType.label_preview")}
                    />
                )}
                {beneficiary.name && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={getTicketLabelNameBeneficiary(beneficiary)}
                        label={i18n.get("forms.transfers.foreign.beneficiaryName.label_preview")}
                    />
                )}
                {beneficiary.account && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={beneficiary.account}
                        label={i18n.get("forms.transfers.foreign.beneficiaryAccount.label_preview")}
                    />
                )}
                {beneficiary.serviceDescription && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={beneficiary.serviceDescription}
                        label={i18n.get("forms.transfers.foreign.beneficiaryServiceDescription.label_preview")}
                    />
                )}
                {beneficiary.address && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={beneficiary.address}
                        label={i18n.get("forms.transfers.foreign.beneficiaryAddress.label_preview")}
                    />
                )}
                {beneficiary.city && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={beneficiary.city}
                        label={i18n.get("forms.transfers.foreign.beneficiaryCity.label_preview")}
                    />
                )}
                {beneficiary.country && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={beneficiary.country.label || ""}
                        label={i18n.get("forms.transfers.foreign.beneficiaryCountry.label_preview")}
                    />
                )}
                {renderTicketBankSelector(beneficiary, "")}
                {beneficiary.addIntermediaryBank && renderTicketBankSelector(beneficiary, "intermediary")}
            </>
        );
    };

    const renderTicket = (values) => {
        const { idTransactionStatus } = props.transaction;
        const idStatus = statusMap.get(idTransactionStatus) ?? idTransactionStatus;
        return (
            <>
                {idStatus === "FINISHED" ? (
                    <PDFAmountField
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get("forms.transfers.foreign.amount.label_preview")}
                    />
                ) : (
                    <PDFAmountFieldError
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get("forms.transfers.foreign.amount.label_preview")}
                    />
                )}
                {transaction?.data?.backendReference && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={transaction.data.backendReference}
                        label={i18n.get("forms.transfers.foreign.referenceTrx.label_preview")}
                    />
                )}
                {transaction?.creationDateTime && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={moment(transaction?.creationDateTime).format("DD/MM/YYYY")}
                        label={i18n.get("forms.transfers.foreign.date.label_preview")}
                    />
                )}
                <PDFTextField
                    idForm={ID_FORM}
                    value={i18n.get("forms.transfers.foreign.operationType.foreignTransfer")}
                    label={i18n.get("forms.transfers.foreign.operationType.label_preview")}
                />
                <PDFTextField
                    idForm={ID_FORM}
                    value={calculateShortLabel(values?.debitAccountData?.productType, values?.debitAccountData?.number)}
                    name="debitAccount"
                />
                {values?.debitReference && values?.debitReference[0] && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={values.debitReference[0].label}
                        label={i18n.get("forms.transfers.foreign.debitReference.label_preview")}
                    />
                )}
                {values?.reason && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={values.reason}
                        label={i18n.get("forms.transfers.foreign.reason.label_preview")}
                    />
                )}

                {values?.estimatedAmount && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={`${values.estimatedAmount.currency} ${values.estimatedAmount.quantity.toFixed(2)}`}
                        label={i18n.get("forms.transfers.foreign.totalAmount.abroad.label_preview")}
                    />
                )}

                <PDFTextField
                    idForm={ID_FORM}
                    value={i18n.get("forms.transfers.foreign.formName.pill")}
                    label={i18n.get("beneficiary.view.beneficiaryType.label")}
                />

                {exchageData && (
                    <PDFTextField
                        idForm={ID_FORM}
                        value={generateExchangeRateLabel(exchageData)}
                        label={i18n.get("forms.transfers.foreign.balance.label_preview")}
                    />
                )}
                {renderTicketBeneficiary(values)}
            </>
        );
    };

    const getTopDisclaimer = () => {
        if (mode === "preview") {
            return "forms.transfers.foreign.currency.disclaimer";
        }
        return null;
    };

    const formProps = {
        title: "forms.transfers.foreign.formName",
        titlePreviewMode: "forms.transfers.foreign.preview.formName",
        titleViewMode: "forms.transfers.foreign.view.formName",
        metadata: {
            draftsEnabled: false,
            templatesEnabled: true,
            schedulable: true,
            programable: true,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: ID_ACTIVITY,
        },

        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        titleConfirmation: true,
        titleFormConfirmation: "OTP CODE",
        validate: validateForm,
        topDisclaimer: getTopDisclaimer(),
        topDisclaimerTextSize: "small",
        topDisclaimerTextColor: "text-boton",
        topDisclaimerAlignY: "flex-start",
        pathBackEditMode: fromOneTouchAccess !== undefined && fromOneTouchAccess === true ? "/desktop" : undefined,
        showPageInfo: true,
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    selectedBank: bankSelectors.getSelectedBank(state, "foreignBank"),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
});

TransferForeignForm.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    isDesktop: bool,
    fromTransaction: bool,
    selectedBank: shape({ type: string, code: string }),
    usesJointAccount: bool,
    fromOneTouchAccess: bool,
};
TransferForeignForm.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    transaction: {},
    location: {},
    fromTransaction: false,
    isDesktop: false,
    selectedBank: { type: "", code: "" },
    usesJointAccount: false,
    fromOneTouchAccess: false,
};
export default compose(connect(mapStateToProps), withRouter)(TransferForeignForm);
