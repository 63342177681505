import { Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Disclaimer from "pages/_components/Disclaimer";
import Notification from "pages/_components/Notification";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import AttachElementInfo from "pages/settings/personalDataUpdate/_components/AttachElementInfo";
import { bool, func, number, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
} from "reducers/updateUserData/updateUserData.reducer";
import { compose } from "redux";
import * as config from "util/config";
import * as Yup from "yup";
import * as i18n from "util/i18n";
import { useLoadingGlobalProvider } from "providers/LoadingGlobalProvider";
import Tooltip from "pages/_components/Tooltip";

const FORM_ID = "settings.personalDataUpdateStep6";

const PersonalDataUpdateStep6 = ({
    isFetching,
    isSubmitting,
    isDesktop,
    dispatch,
    exchangeToken,
    setFieldValue,
    preDataStep6,
    selectedMandatoryAttachments,
    selectedAdditionalAttachments,
    selectedIdentificationAttachments,
    mandatoryCount,
    additionalCount,
    identificationCount,
    isSkippingStep6,
}) => {
    // Section 1
    const [mandatoryDocuments, setMandatoryDocuments] = useState([]);
    const [residenceDocsDisclaimer, setResidenceDocsDisclaimer] = useState([]);

    // Section 2
    const [additionalDocuments, setAdditionalDocuments] = useState([]);
    const [employmentDocsDisclaimer, setEmploymentDocsDisclaimer] = useState([]);
    const [additionalDocsDisclaimer, setAdditionalDocsDisclaimer] = useState("");

    // Section identifications

    const [identificationDocuments, setIdentificationDocuments] = useState([]);

    // Files
    const [counter, setCounter] = useState(1);
    const [isValidAttachedMandatoryDocs, setIsValidAttachedMandatoryDocs] = useState(false);
    const [isValidAttachedAdditionalDocs, setIsValidAttachedAdditionalDocs] = useState(false);
    const [isValidAttachedIdentificationDocs, setIsValidAttachedIdentificationDocs] = useState(false);

    const { setLoading } = useLoadingGlobalProvider();

    useEffect(() => {
        setLoading(isFetching);
    }, [isFetching]);

    useEffect(() => {
        dispatch(updateUserDataActions.preFormStep6(exchangeToken));
    }, [dispatch]);

    useEffect(() => {
        if (!isFetching && preDataStep6) {
            setMandatoryDocuments(preDataStep6?.mandatoryDocuments);
            setIdentificationDocuments(preDataStep6?.identificationDocuments);
            setAdditionalDocuments(preDataStep6?.additionalDocuments);
            setResidenceDocsDisclaimer(preDataStep6?.residenceDocsDisclaimer);
            setEmploymentDocsDisclaimer(preDataStep6?.employmentDocsDisclaimer);
            setAdditionalDocsDisclaimer(preDataStep6?.additionalDocsDisclaimer);

            const hasMandatoryDocs = preDataStep6?.mandatoryDocuments && preDataStep6?.mandatoryDocuments.length > 0;
            const hasAddDocs = preDataStep6?.additionalDocuments && preDataStep6?.additionalDocuments.length > 0;
            const hasIdentificationDocs =
                preDataStep6?.identificationDocuments && preDataStep6?.identificationDocuments.length > 0;

            if (!hasMandatoryDocs && hasAddDocs) {
                setIsValidAttachedMandatoryDocs(true);
                setFieldValue("loadedMandatoryDocuments", []);
            }

            if (!hasAddDocs && hasMandatoryDocs) {
                setIsValidAttachedAdditionalDocs(true);
                setFieldValue("loadedAdditionalDocuments", []);
            }

            if (!hasIdentificationDocs) {
                setIsValidAttachedIdentificationDocs(true);
                setFieldValue("loadedIdentificationDocuments", []);
            }

            if (!hasMandatoryDocs && !hasAddDocs) {
                setIsValidAttachedMandatoryDocs(true);
                setIsValidAttachedAdditionalDocs(true);
                setFieldValue("loadedIdentificationDocuments", []);
            }
        }
    }, [preDataStep6]);

    const addDocumentHandler = () => {
        setCounter(counter + 1);
    };

    const handleCancel = () => {
        dispatch(push("/desktop"));
    };

    const getElementLink = (e) => {
        const linkConfigMap = new Map([
            ["settings.personalDataUpdateStep6.crs.label", "settings.userData.link.crs"],
            ["settings.personalDataUpdateStep6.w9.label", "settings.userData.link.w9"],
            ["settings.personalDataUpdateStep6.w8Ben.label", "settings.userData.link.w8Ben"],
            ["settings.personalDataUpdateStep6.dddPep.label", "settings.userData.link.ddd.pep"],
        ]);

        const linkConfig = linkConfigMap.get(e);
        return linkConfig ? config.get(linkConfig, "") : undefined;
    };

    const renderMandatoryDocumentSection = () => {
        const mandatoryDocumentsOptions = [];
        if (mandatoryDocuments) {
            mandatoryDocuments.map((elem, idx) => {
                const elemLink = getElementLink(elem);
                return mandatoryDocumentsOptions.push(
                    <>
                        <AttachElementInfo
                            dispatch={dispatch}
                            internalIndex={idx}
                            isMandatory
                            labelKey={elem}
                            link={elemLink}
                            linkLabel="settings.personalDataUpdateStep6.link.label"
                            tooltipLabel="settings.personalDataUpdateStep6.tooltip.label"
                            mandatoryDocuments={mandatoryDocuments}
                            setFieldValue={setFieldValue}
                        />
                    </>,
                );
            });
        }
        return mandatoryDocumentsOptions;
    };

    const renderIdentificationDocumentSection = () => {
        const identificationDocumentsOptions = [];
        if (identificationDocuments) {
            identificationDocuments.map((elem, idx) => {
                const elemLink = getElementLink(elem);
                return identificationDocumentsOptions.push(
                    <>
                        <AttachElementInfo
                            dispatch={dispatch}
                            internalIndex={idx}
                            isIdentification
                            labelKey={elem}
                            link={elemLink}
                            linkLabel="settings.personalDataUpdateStep6.link.label"
                            tooltipLabel="settings.personalDataUpdateStep6.tooltip.label"
                            mandatoryDocuments={mandatoryDocuments}
                            setFieldValue={setFieldValue}
                        />
                    </>,
                );
            });
        }
        return identificationDocumentsOptions;
    };

    useEffect(() => {
        if (
            mandatoryDocuments &&
            mandatoryDocuments.length > 0 &&
            selectedMandatoryAttachments &&
            selectedMandatoryAttachments.length > 0 &&
            mandatoryDocuments.length === selectedMandatoryAttachments.length
        ) {
            if (selectedMandatoryAttachments.filter((e) => e.name !== null && e.name.trim().length > 0).length !== 0) {
                setFieldValue("mandatoryDocuments", mandatoryDocuments);
                setIsValidAttachedMandatoryDocs(true);
                setFieldValue("loadedMandatoryDocuments", selectedMandatoryAttachments);
            } else if (isValidAttachedMandatoryDocs) {
                setIsValidAttachedMandatoryDocs(false);
                setFieldValue("loadedMandatoryDocuments", []);
            }
        } else {
            setIsValidAttachedMandatoryDocs(false);
        }
    }, [mandatoryCount]);

    useEffect(() => {
        if (
            additionalDocuments &&
            additionalDocuments.length > 0 &&
            selectedAdditionalAttachments &&
            selectedAdditionalAttachments.length >= 1
        ) {
            setFieldValue("additionalDocuments", additionalDocuments);
            if (selectedAdditionalAttachments.filter((e) => e.name !== null && e.name.trim().length > 0).length >= 1) {
                setIsValidAttachedAdditionalDocs(true);
                setFieldValue("loadedAdditionalDocuments", selectedAdditionalAttachments);
            } else if (isValidAttachedAdditionalDocs) {
                setIsValidAttachedAdditionalDocs(false);
                setFieldValue("loadedAdditionalDocuments", []);
            }
        } else {
            setIsValidAttachedAdditionalDocs(false);
        }
    }, [additionalCount]);

    useEffect(() => {
        if (
            identificationDocuments &&
            identificationDocuments.length > 0 &&
            selectedIdentificationAttachments &&
            selectedIdentificationAttachments.length >= 1
        ) {
            setFieldValue("identificationDocuments", identificationDocuments);
            if (
                selectedIdentificationAttachments.filter((e) => e.name !== null && e.name.trim().length > 0).length >= 1
            ) {
                setIsValidAttachedIdentificationDocs(true);
                setFieldValue("loadedIdentificationDocuments", selectedIdentificationAttachments);
            } else if (isValidAttachedIdentificationDocs) {
                setIsValidAttachedIdentificationDocs(false);
                setFieldValue("loadedIdentificationDocuments", []);
            }
        } else {
            setIsValidAttachedIdentificationDocs(false);
        }
    }, [identificationCount]);

    return (
        <>
            <Notification scopeToShow="personalDataUpdate" />
            <Form className="mx-n-5">
                <Box className="mb-5 mb-md-8">
                    <Box
                        display="flex"
                        column
                        fullWidth
                        alignY="center"
                        {...(isDesktop && { background: "component-background" })}
                        className="px-5 px-md-12 py-2 py-md-7 mb-7"
                        borderRadius="default">
                        <Text
                            labelKey={`${FORM_ID}.title.label`}
                            component="h2"
                            color="heading"
                            size="4"
                            className="mt-0 mb-3"
                            align="center"
                            bold
                        />
                        <Text
                            labelKey={`${FORM_ID}.allowedFormats.label`}
                            component="p"
                            size="7"
                            color="heading"
                            className="m-0"
                            align="center"
                        />
                    </Box>
                    {mandatoryDocuments && mandatoryDocuments.length > 0 && (
                        <Text
                            labelKey={`${FORM_ID}.attachDocuments.label`}
                            component="h3"
                            color="primary"
                            size={isDesktop ? "5" : "6"}
                            className="mt-0 mb-3 ml-5 mx-md-0"
                            bold
                        />
                    )}
                    {renderMandatoryDocumentSection()}
                    <input type="hidden" name="mandatoryDocuments" />
                    <input type="hidden" name="loadedMandatoryDocuments" />

                    {identificationDocuments && identificationDocuments.length > 0 && (
                        <Box display="flex">
                            <Text
                                labelKey={`${FORM_ID}.documentsInstructions.label`}
                                component="h3"
                                color="primary"
                                size={isDesktop ? "5" : "6"}
                                className="mt-0 mb-3 ml-5 mx-md-0"
                                bold
                            />
                            <Tooltip
                                text={`${FORM_ID}.additionalDocsDisclaimer.documents`}
                                position={isDesktop ? "left-centered" : "bottom-right"}
                                className="mb-3"
                            />
                        </Box>
                    )}
                    {renderIdentificationDocumentSection()}
                    <input type="hidden" name="identificationDocuments" />
                    <input type="hidden" name="loadedMIdentificationDocuments" />

                    {residenceDocsDisclaimer && (
                        <Disclaimer
                            labelKey="settings.personalDataUpdateStep6.disclaimer.residence.documents"
                            notBorder
                            textSize="7"
                            padding="pt-5 pb-0 px-5 px-md-0"
                            textColor="secondary-color"
                            alignY="flex-start"
                        />
                    )}

                    {additionalDocuments && additionalDocuments.length > 0 && (
                        <>
                            <Box display="flex" column fullWidth className="mt-5">
                                <Box display="flex">
                                    <Text
                                        labelKey={`${FORM_ID}.documentsInstructions.label`}
                                        component="h3"
                                        color="primary"
                                        size={isDesktop ? "5" : "6"}
                                        className="mt-0 mb-3 ml-5 mx-md-0"
                                        bold
                                    />
                                    <Tooltip
                                        inText={
                                            additionalDocsDisclaimer
                                                ? i18n.get(`${FORM_ID}.additionalDocsDisclaimer`, null, {
                                                      additionalDocsDisclaimer,
                                                  })
                                                : i18n.get(`${FORM_ID}.additionalDocsDisclaimer.highRisk`)
                                        }
                                        position={isDesktop ? "left-centered" : "bottom-right"}
                                        className="mb-3"
                                    />
                                </Box>
                                {Array.from({ length: counter }).map((_, index) => (
                                    <AttachElementInfo
                                        dispatch={dispatch}
                                        id={`optionalDocument_${index}`}
                                        internalIndex={index}
                                        isMandatory={false}
                                        name={`optionalDocument_${counter - 1}`}
                                        options={additionalDocuments}
                                        setFieldValue={setFieldValue}
                                    />
                                ))}
                            </Box>
                            <Box
                                display="flex"
                                {...(isDesktop ? { alignX: "between" } : { column: true, className: "mb-3" })}
                                fullWidth>
                                {employmentDocsDisclaimer && isDesktop && (
                                    <Disclaimer
                                        {...(!isDesktop && { className: "ml-auto" })}
                                        textColor="secondary-color"
                                        labelKey="settings.personalDataUpdateStep6.disclaimer.employment.documents"
                                    />
                                )}
                                <Button
                                    className="ml-auto min-width-fit-content"
                                    label="settings.personalDataUpdateStep6.button.addDocument.label"
                                    bsStyle="link"
                                    image="/images/plusCircle.svg"
                                    onClick={addDocumentHandler}
                                />
                                <input type="hidden" name="loadedAdditionalDocuments" />

                                {employmentDocsDisclaimer && !isDesktop && (
                                    <Disclaimer
                                        {...(!isDesktop && { className: "ml-auto" })}
                                        textColor="secondary-color"
                                        labelKey="settings.personalDataUpdateStep6.disclaimer.employment.documents"
                                    />
                                )}
                            </Box>
                        </>
                    )}
                    <input type="hidden" name="additionalDocuments" />
                </Box>

                <Row className="px-5 px-md-0" {...(!isDesktop && { gapY: "3" })}>
                    <Col xs={12} md={4} mdOffset={2}>
                        <Button label="global.cancel" bsStyle="outline" onClick={handleCancel} block />
                    </Col>
                    <Col xs={12} md={4} {...(!isDesktop && { className: "grid-reversed" })}>
                        <Button
                            label="global.continue"
                            disabled={
                                !(
                                    isValidAttachedMandatoryDocs &&
                                    isValidAttachedAdditionalDocs &&
                                    isValidAttachedIdentificationDocs
                                )
                            }
                            type="submit"
                            bsStyle="primary"
                            block
                            loading={isSubmitting || isFetching || isSkippingStep6}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    );
};

PersonalDataUpdateStep6.propTypes = {
    dispatch: func.isRequired,
    isFetching: bool,
    isSubmitting: bool,
    isDesktop: bool.isRequired,
    exchangeToken: string.isRequired,
    preDataStep6: shape({}).isRequired,
    values: shape({}).isRequired,
    setFieldValue: func.isRequired,
    selectedMandatoryAttachments: shape({}),
    selectedAdditionalAttachments: shape({}),
    selectedIdentificationAttachments: shape({}),
    mandatoryCount: number,
    additionalCount: number,
    identificationCount: number,
    isSkippingStep6: bool,
};

PersonalDataUpdateStep6.defaultProps = {
    isFetching: false,
    isSubmitting: false,
    selectedMandatoryAttachments: [],
    selectedAdditionalAttachments: [],
    selectedIdentificationAttachments: [],
    mandatoryCount: 0,
    additionalCount: 0,
    identificationCount: 0,
    isSkippingStep6: false,
};

const mapStateToProps = (state) => ({
    isFetching: updateUserDataSelectors.isFetching(state),
    isSubmitting: updateUserDataSelectors.isSubmitting(state),
    exchangeToken: updateUserDataSelectors.getExchangeToken(state),
    currentUserData: updateUserDataSelectors.getCurrentUserData(state),
    preDataStep6: updateUserDataSelectors.getPreDataStep6(state),
    selectedMandatoryAttachments: updateUserDataSelectors.getSelectedMandatoryAttachments(state),
    selectedAdditionalAttachments: updateUserDataSelectors.getSelectedAdditionalAttachments(state),
    selectedIdentificationAttachments: updateUserDataSelectors.getSelectedIdentificationAttachments(state),
    mandatoryCount: updateUserDataSelectors.getMandatoryCount(state),
    additionalCount: updateUserDataSelectors.getAdditionalCount(state),
    identificationCount: updateUserDataSelectors.getIdentificationCount(state),
    isSkippingStep6: updateUserDataSelectors.isSkippingStep6(state),
});

export default compose(
    connect(mapStateToProps),
    resizableRoute,
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        mapPropsToValues: () => ({
            mandatoryDocuments: [],
            loadedMandatoryDocuments: [],
            additionalDocuments: [],
            loadedAdditionalDocuments: [],
        }),
        validationSchema: () => Yup.object().shape({}),
        handleSubmit: (values, formikBag) => {
            formikBag.props.dispatch(updateUserDataActions.saveForm(values, 6, formikBag));
        },
    }),
)(PersonalDataUpdateStep6);
