import classNames from "classnames";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, shape, string } from "prop-types";
import React from "react";

const HeadSimple = ({
    ariaLabelOnLeftClick,
    ariaLabelOnRightClick,
    background,
    className,
    imageLeft,
    imageRight,
    isMobile,
    onLeftClick,
    onRightClick,
    title,
    textParams,
}) => {
    const handlerLeftClick = () => {
        onLeftClick();
    };
    const handlerRightClick = () => {
        onRightClick();
    };

    return (
        <Box
            component="header"
            background={background}
            display="flex"
            columnReverse={!isMobile}
            alignX="center"
            className={classNames("head-simple", className)}>
            {onLeftClick && (
                <Box display="flex" alignX="flex-end" className={onLeftClick ? "" : "px-3"}>
                    <Button
                        ariaLabel={ariaLabelOnLeftClick}
                        display="flex"
                        alignX="flex-end"
                        className="p-0"
                        image={imageLeft}
                        onClick={handlerLeftClick}
                        bsStyle="link"
                    />
                </Box>
            )}
            <Box
                display="flex"
                flex1
                alignX="center"
                alignY="center"
                className={classNames("mt-md-9", {
                    "ml-7": !onLeftClick && isMobile,
                    "mr-7": !onRightClick && isMobile,
                })}>
                <Text
                    component="h1"
                    className="m-0"
                    color="heading"
                    size={isMobile ? "2" : "3"}
                    align="center"
                    bold
                    labelKey={title}
                    textParams={textParams}
                />
            </Box>
            {onRightClick && (
                <Box display="flex" alignX="flex-end" className={onRightClick ? "mb-md-auto" : "px-3 mb-md-auto"}>
                    <Button
                        ariaLabel={ariaLabelOnRightClick}
                        display="flex"
                        alignX="flex-end"
                        className="head-simple-right-button"
                        onClick={handlerRightClick}
                        image={imageRight}
                        bsStyle="link"
                    />
                </Box>
            )}
        </Box>
    );
};

HeadSimple.propTypes = {
    ariaLabelOnLeftClick: string,
    ariaLabelOnRightClick: string,
    background: string,
    className: string,
    imageLeft: string,
    imageRight: string,
    isMobile: bool.isRequired,
    onLeftClick: func,
    onRightClick: func,
    title: string,
    textParams: shape({}),
};

HeadSimple.defaultProps = {
    ariaLabelOnLeftClick: "global.back",
    ariaLabelOnRightClick: "global.close",
    background: null,
    className: null,
    imageLeft: "images/icons/arrowLeft.svg",
    imageRight: "images/icons/cross16.svg",
    onLeftClick: null,
    onRightClick: null,
    title: null,
    textParams: {},
};

export default resizableRoute(HeadSimple);
