import React, { Component } from "react";
import { string, number as numberType, bool, func } from "prop-types";

import * as i18nUtils from "util/i18n";
import * as dateUtils from "util/date";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import ListItem from "pages/_components/listItem/ListItem";
import SideBarModal from "pages/_components/modal/SideBarModal";
import DownloadAccoutDetailForm from "pages/forms/customForms/DownloadAccoutDetailForm";
import { loansIsNotAvailable } from "pages/loans/hooks/loandsHook";

class LoansListItem extends Component {
    static propTypes = {
        alias: string,
        completeFavorites: bool,
        currentLang: string.isRequired,
        hideContextsOptions: bool,
        idProduct: string,
        isDesktop: bool.isRequired,
        nextDueDate: string.isRequired,
        number: string.isRequired,
        numberOfFees: numberType.isRequired,
        numberOfPaidFees: numberType.isRequired,
        overdueStatus: string,
        paidAmount: numberType.isRequired,
        push: func.isRequired,
        setFavoriteLoan: func,
        showLink: bool,
        showPayment: bool,
        showProductIcon: bool,
        showStar: bool,
        subType: string.isRequired,
        totalAmount: numberType.isRequired,
    };

    static defaultProps = {
        alias: null,
        completeFavorites: false,
        hideContextsOptions: false,
        idProduct: null,
        overdueStatus: "",
        setFavoriteLoan: () => {},
        showLink: true,
        showPayment: false,
        showProductIcon: true,
        showStar: false,
    };

    state = {
        showAccountStatusModal: false,
    };

    render() {
        const {
            alias,
            completeFavorites,
            currentLang,
            dispatch,
            hideContextsOptions,
            idProduct,
            isDesktop,
            nextDueDate,
            number,
            numberOfFees,
            numberOfPaidFees,
            overdueStatus,
            paidAmount,
            setFavoriteLoan,
            showLink,
            showPayment,
            showProductIcon,
            showStar,
            subType,
            totalAmount,
            ...props
        } = this.props;
        const reference = `${number}`;
        const isExpired = dateUtils.isDateLessThanToday(nextDueDate);
        const productTypeTitle = i18nUtils.get("desktop.widgets.loan");
        const pendingBalance = totalAmount - paidAmount;
        const contextOptions = [];

        if (!overdueStatus || !overdueStatus.toLowerCase() === "nab") {
            contextOptions.push({
                label: "contextMenu.loans.widget.options.account.status",
                onClick: () => {
                    // dispatch(push("/websiteunderconstruction"));
                    this.setState({ showAccountStatusModal: true });
                },
            });
        }

        if (overdueStatus && !loansIsNotAvailable(overdueStatus)) {
            contextOptions.push({
                label: "contextMenu.loans.widget.options.pay.service",
                onClick: () => {
                    this.props.push(`/formCustom/payLoan?loan=${idProduct}`);
                },
            });
        }

        return (
            <>
                <ListItem
                    {...props}
                    idProduct={idProduct}
                    productTypeTitle={productTypeTitle}
                    title={subType}
                    name={alias}
                    reference={reference}
                    expiredText={i18nUtils.get("loans.list.item.expired")}
                    isExpired={isExpired}
                    amount={pendingBalance}
                    amountLabel={i18nUtils.get("widgets.loans.pendingTotal")}
                    path={showLink ? `/loans/${idProduct}` : null}
                    overdueStatusLoan={overdueStatus}
                    contextOptions={hideContextsOptions ? null : contextOptions}
                    showProductIcon={showProductIcon}
                    showStar={showStar}
                    setFavoriteAccount={setFavoriteLoan}
                    completeFavorites={completeFavorites}
                    hideChevron
                />

                <SideBarModal
                    show={this.state.showAccountStatusModal}
                    onClose={() => {
                        this.setState({ showAccountStatusModal: false });
                    }}
                    title="forms.product.state.download.title">
                    <DownloadAccoutDetailForm isDesktop={isDesktop} account={{ idProduct }} currentLang={currentLang} />
                </SideBarModal>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    dispatch,
    push: (url) => dispatch(push(url)),
});

LoansListItem.propTypes = {
    dispatch: func.isRequired,
    push: func.isRequired,
};

export default connect(null, mapDispatchToProps)(LoansListItem);
