/**
 * crypto.util.react.js (banking-api extension).
 * Requires: aes.js, pbkdf2.js (CryptoJS v3.1.2+), jsencrypt.min.js.
 */
import JSEncrypt from "node-jsencrypt";
import CryptoJS from "crypto-js";

const publicRSAKey =
    "-----BEGIN PUBLIC KEY----- 		MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4b2L3Gk8Q+qlcFQSRmho	 	/fDPvrZ3A5jx3/9XE2X7N6EO2F9RcH23b8EY8hEEd7lHNXqHDYcpt97QPgey1lTq	 	NItUrRqYFACU+5FFxUD6hwcibZFRaBy8PjHsfT2m9VcTlCAvViybHrwWMH73o9V8	 	zLJfoFtxKKxfiTv4CBVIxLN+dTOJbqKgOYSRCG/3022rWcB4KpUqQlqTfp+3CreI	 	IOcTVq9MZ41MvniMczBdC4ywOtBm/sd9acpUch2MKUbEe8nL9izfjahS6MADFAHq	 	DTStCAsfPwh5S3yOj0cVtun6rkMhPG1Av6HT1bAWW8Du6BvF1ZKdC4ZY4t3/e005	 	oQIDAQAB	 	-----END PUBLIC KEY-----";

const AESKey = "cjk3SzNVRUBNU1Vxem5DK2s5dXVKOSR6cUZGWEg2YWQ=";

/**
 * Encrypt received text with RSA public key.
 * @returns {string}
 */
// eslint-disable-next-line import/prefer-default-export
export function encryptText(text) {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicRSAKey);
    return encrypt.encrypt(text);
}

/**
 * Decrypt received text with AES key.
 * @returns {string}
 */
// eslint-disable-next-line import/prefer-default-export
export function decryptAESText(text) {
    if (text === null) {
        return null;
    }
    const key = CryptoJS.enc.Base64.parse(AESKey);
    try {
        const decrypted = CryptoJS.AES.decrypt(text, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (e) {
        return null;
    }
}
