import classNames from "classnames";
import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import CredentialTokenComponent from "pages/forms/_components/credential/CredentialTokenComponent";
import { useSelectorOptionsAccounts } from "pages/forms/customForms/hooks/TransferCustomHooks";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import {
    selectors as transactionalProfileSelector,
    actions as transactionalProfileActions,
} from "reducers/transactionalProfile";
import { flattenArray, removeDuplicateItems } from "util/array";
import * as Yup from "yup";
import * as i18n from "util/i18n";
import { selectors as revelockSelectors } from "reducers/revelock/revelock.reducer";
import useCustomLocationSetter from "hooks/useLocationSetter";
import ListCountriesComponentMemo from "./ListCountriesComponent";

const FORM_ID = "transactional.profile";
const ID_ACTIVITY = `${FORM_ID}.send`;

const TransactionalProfileModal = ({
    countriesListProp,
    modifiedCountries,
    currentLang,
    fromBackoffice,
    modalProps,
    idTransaction,
    isDesktop,
    mode,
    setFieldValue,
    showModal,
    values,
    fetching,
    account,
    accountList,
    dispatch,
    submitForm,
    credentials,
    resetForm,
    hasTransactionalProfile,
    currentRevelockPosition,
    isAllInCheckedAuthorized,
    isAllOutCheckedAuthorized,
    isAllInCheckedUnauthorized,
    isAllOutCheckedUnauthorized,
    fromMenu,
    selectedCountryId,
    setErrors,
}) => {
    const { isCreate, accountID, debitAccount, hideSelector } = modalProps;
    const selectRef = useRef(null);

    const [accountListOptions] = useSelectorOptionsAccounts(accountList);
    const [isValidating, setIsValidating] = useState(false);
    const [disabledOutColumn, setDisabledOutColumn] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useCustomLocationSetter(showModal, currentRevelockPosition, FORM_ID);

    const genericProps = {
        fromBackoffice,
        idActivity: ID_ACTIVITY,
        idTransactionTicket: idTransaction,
        isRequired: true,
        lang: currentLang,
        mode,
    };

    const verifyAccountSimplified = (productId) => {
        const accountSelected = accountList.find((accountItem) => accountItem.idProduct === productId);
        const isSimplified = accountSelected?.simplifiedLocal || accountSelected?.simplifiedInternacional;
        if (isSimplified !== undefined) {
            setDisabledOutColumn(isSimplified);
        }
    };

    const clearForm = () => {
        setErrorMessage(null);
        setFieldValue("otp", "");
        setErrors({});
        dispatch(transactionalProfileActions.resetCountrySelected());
    };

    useEffect(() => {
        resetForm();
        if (
            accountListOptions?.options?.length > 0 &&
            accountListOptions?.options.filter((el) => !el?.disabled).length > 0
        ) {
            if (accountID !== null && account?.idProduct !== values?.account) {
                setFieldValue("account", accountID);
                setFieldValue("accountData", debitAccount);
                verifyAccountSimplified(accountID);
            } else if (!values.account) {
                const {
                    countableBalance,
                    currency,
                    idProduct,
                    number,
                    otherLabel,
                    productType,
                    statusDesc,
                } = accountListOptions?.options.filter((el) => !el.disabled)[0];
                setFieldValue("account", accountListOptions?.options[0]?.idProduct);
                setFieldValue("accountData", {
                    countableBalance,
                    currency,
                    idProduct,
                    number,
                    otherLabel,
                    productType,
                    statusDesc,
                });
                verifyAccountSimplified(accountListOptions?.options.filter((el) => !el.disabled)[0]?.idProduct);
            }
        }
    }, [accountListOptions]);

    useEffect(() => {
        if (values?.account && account?.idProduct !== values?.account) {
            verifyAccountSimplified(values?.account);
            dispatch(transactionalProfileActions.transactionalCountriesRequest(values.account, values.accountData));
        }
    }, [values?.account]);

    useEffect(() => {
        setErrorMessage(null);
        if (showModal) {
            dispatch(transactionalProfileActions.credentialsGroupsRequest({ idActivity: ID_ACTIVITY }));
        } else {
            clearForm();
        }
    }, [showModal]);

    const handlerSendForm = () => {
        setIsValidating(true);
    };

    useEffect(() => {
        if (isValidating !== undefined && isValidating === true) {
            const oneCountrySelected = Object.values(modifiedCountries).filter((country) => country.in || country.out);
            setFieldValue("listSelected", oneCountrySelected);
            setFieldValue("isCreate", isCreate);
            setFieldValue("hasTransactionalProfile", hasTransactionalProfile);
            if (oneCountrySelected.length > 0) {
                submitForm();
                setIsValidating(false);
                setErrorMessage(null);
            } else {
                setErrorMessage(i18n.get("transactional.profile.select.oneMore.countries"));
                setIsValidating(false);
            }
        }
    }, [isValidating]);

    const hasChanges = useMemo(
        () =>
            countriesListProp?.some((country) => {
                const modifiedCountry = modifiedCountries[country.id];
                if (!modifiedCountry) {
                    return true;
                }
                return modifiedCountry.in !== country.in || modifiedCountry.out !== country.out;
            }),
        [modifiedCountries, countriesListProp],
    );

    const handlerAllSelectAuthorized = (_type, value, changeAll = true) => {
        if (changeAll) {
            dispatch(transactionalProfileActions.setModifiedCountryList(_type, value, true));
        }

        if (_type === "in") {
            dispatch(transactionalProfileActions.setIsAllInCheckedAuthorized(value));
        } else {
            dispatch(transactionalProfileActions.setIsAllOutCheckedAuthorized(value));
        }
    };

    const handlerAllSelectUnauthorized = (_type, value, changeAll = true) => {
        if (changeAll) {
            dispatch(transactionalProfileActions.setModifiedCountryList(_type, value, false));
        }

        if (_type === "in") {
            dispatch(transactionalProfileActions.setIsAllInCheckedUnauthorized(value));
        } else {
            dispatch(transactionalProfileActions.setIsAllOutCheckedUnauthorized(value));
        }
    };

    const handleHideModal = () => {
        dispatch(transactionalProfileActions.modalHide());
    };

    return (
        <>
            <Modal
                aria-labelledby="modalTitleID"
                aria-modal="true"
                show={showModal}
                onHide={handleHideModal}
                className={classNames("drawer transactional-profile-modal")}>
                <div className="modal-container px-0">
                    <Modal.Header closeButton className={isDesktop ? "px-9" : "px-5"}>
                        <Box display="flex" column alignY="center" className="mt-3 mt-md-7 mb-0 mb-md-4">
                            <Text
                                id="modalTitleID"
                                component="h4"
                                labelKey="transactional.profile.modal.title"
                                className={classNames("mt-0", { "mb-2": hideSelector }, { "mb-0": !hideSelector })}
                                color="heading-color"
                                size="3"
                                align="center"
                                bold
                            />
                            {account && isCreate === true && (
                                <Text
                                    id="modalTitleID"
                                    component="h4"
                                    labelKey="transactional.profile.modal.title"
                                    className={classNames("mt-0", { "mb-2": hideSelector }, { "mb-0": !hideSelector })}
                                    color="heading-color"
                                    size="3"
                                    align="center"
                                    regular>
                                    {" "}
                                    {account?.otherLabel || account?.longLabel}
                                </Text>
                            )}
                        </Box>
                    </Modal.Header>

                    <Form className="full-width full-height min-height-0">
                        <Box display="flex" column fullWidth fullHeight className="px-0 px-md-0">
                            <Box display="flex" column>
                                {accountList.length > 0 && isCreate !== true && (
                                    <Box display="flex" className="mb-5 mb-md-6 px-5 px-md-9">
                                        <Field
                                            {...genericProps}
                                            component={FormFieldsComponents.ProductselectorCustom}
                                            data={accountListOptions}
                                            value={
                                                accountListOptions?.options?.length > 0 &&
                                                accountListOptions?.options.filter((el) => !el?.disabled).length > 0
                                                    ? accountListOptions?.options.filter((el) => !el.disabled)[0]
                                                          ?.idProduct
                                                    : ""
                                            }
                                            key="account"
                                            name="account"
                                            idField="account"
                                            renderAs="combo"
                                            labelNoMarginTop
                                            disabled={fetching}
                                            autoFocus
                                            withSingleOption={!fromMenu}
                                            selectRef={selectRef}
                                            onChangeDataCustom={() => clearForm()}
                                        />
                                    </Box>
                                )}
                                <Text
                                    component="p"
                                    size="6"
                                    align="center"
                                    className={classNames("mb-5 mb-md-6 mb-md-4 my-0", { "px-5": !isDesktop })}>
                                    {!fetching && hideSelector && i18n.get("transactional.profile.not.created")}
                                    {!fetching && !hideSelector && i18n.get("transactional.profile.create")}
                                </Text>
                            </Box>
                            <Box display="flex" flex1 className="min-height-7rem">
                                <ListCountriesComponentMemo
                                    countriesList={countriesListProp}
                                    isDesktop={isDesktop}
                                    fetching={fetching}
                                    isAllInCheckedAuthorized={isAllInCheckedAuthorized}
                                    isAllOutCheckedAuthorized={isAllOutCheckedAuthorized}
                                    isAllInCheckedUnauthorized={isAllInCheckedUnauthorized}
                                    isAllOutCheckedUnauthorized={isAllOutCheckedUnauthorized}
                                    handlerAllSelectAuthorized={handlerAllSelectAuthorized}
                                    handlerAllSelectUnauthorized={handlerAllSelectUnauthorized}
                                    disabledOutColumn={disabledOutColumn}
                                    selectedCountryId={selectedCountryId}
                                    error={errorMessage}
                                />
                            </Box>
                            <Box
                                display="flex"
                                column
                                alignY="center"
                                className="mt-auto pt-7 pt-md-8 px-5 px-md-9 pb-10"
                                gap="7">
                                {!fetching && (
                                    <CredentialTokenComponent
                                        credentials={credentials}
                                        onChangeToken={(token) => {
                                            if (setFieldValue) {
                                                setFieldValue("otp", token);
                                            }
                                        }}
                                        disabled={fetching}
                                        relativeStyleError
                                    />
                                )}
                                <Button
                                    bsStyle="primary"
                                    label="global.confirm"
                                    block
                                    onClick={() => handlerSendForm()}
                                    disabled={isValidating || fetching || !account?.idProduct || !hasChanges}
                                />
                            </Box>
                        </Box>
                    </Form>
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => ({
    fetching: transactionalProfileSelector.getFetching(state),
    countriesListProp: transactionalProfileSelector.getCountriesList(state),
    modifiedCountries: transactionalProfileSelector.getModifiedCountries(state),
    selectedCountryId: transactionalProfileSelector.getSelectedCountryId(state),
    hideSelector: !transactionalProfileSelector.getHasProfile(state),
    account: transactionalProfileSelector.getAccount(state),
    accountList: transactionalProfileSelector.getAccounts(state),
    credentials: compose(
        (array) => array.filter((item) => item !== "accessToken"),
        removeDuplicateItems,
        flattenArray,
        (array) => array.map(({ credentials }) => credentials),
    )(transactionalProfileSelector.getCredentialsGroups(state)),
    hasCompleteCredentialGroups: transactionalProfileSelector.hasCompleteCredentialGroups(state),
    currentRevelockPosition: revelockSelectors.getPosition(state),
    showModal: transactionalProfileSelector.getDisplayModal(state),
    modalProps: transactionalProfileSelector.getModalProps(state),
    hasTransactionalProfile: transactionalProfileSelector.getHasProfile(state),
    isAllInCheckedAuthorized: transactionalProfileSelector.getIsAllInCheckedAuthorized(state),
    isAllOutCheckedAuthorized: transactionalProfileSelector.getIsAllOutCheckedAuthorized(state),
    isAllInCheckedUnauthorized: transactionalProfileSelector.getIsAllInCheckedUnauthorized(state),
    isAllOutCheckedUnauthorized: transactionalProfileSelector.getIsAllOutCheckedUnauthorized(state),
});

TransactionalProfileModal.propTypes = {
    children: func,
    countriesListProp: arrayOf(shape({})).isRequired,
    accountList: arrayOf(shape({})).isRequired,
    modifiedCountries: shape({}).isRequired,
    isAllInCheckedAuthorized: bool.isRequired,
    isAllOutCheckedAuthorized: bool.isRequired,
    isAllInCheckedUnauthorized: bool.isRequired,
    isAllOutCheckedUnauthorized: bool.isRequired,
    currentLang: string,
    fromBackoffice: bool,
    idTransaction: string,
    isDesktop: bool.isRequired,
    mode: string,
    setFieldValue: func.isRequired,
    showModal: bool.isRequired,
    modalProps: shape({}),
    fetching: bool,
    values: shape({}).isRequired,
    account: shape({}),
    dispatch: func.isRequired,
    submitForm: func.isRequired,
    credentials: shape({}),
    resetForm: func.isRequired,
    hasTransactionalProfile: bool,
    currentRevelockPosition: string.isRequired,
    fromMenu: bool,
    selectedCountryId: string,
    setErrors: func.isRequired,
};

TransactionalProfileModal.defaultProps = {
    children: null,
    currentLang: null,
    fromBackoffice: false,
    idTransaction: null,
    mode: "edit",
    account: null,
    modalProps: {},
    fetching: false,
    credentials: {},
    hasTransactionalProfile: false,
    fromMenu: false,
    selectedCountryId: null,
};

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            inAll: false,
            outAll: false,
            otp: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string()
                    .nullable()
                    .required(i18n.get(`${FORM_ID}.otp.required`)),
            }),
        handleSubmit: (values, formikBag) => {
            const { dispatch, account } = formikBag.props;
            const { otp, isCreate, hasTransactionalProfile } = values;

            const newListCountries = values.listSelected;

            dispatch(
                transactionalProfileActions.transactionalCountriesUpdateRequest(
                    account?.idProduct,
                    newListCountries,
                    () => dispatch(transactionalProfileActions.modalHide()),
                    otp,
                    formikBag,
                    isCreate,
                    hasTransactionalProfile,
                ),
            );
        },
    }),
)(resizableRoute(TransactionalProfileModal));
