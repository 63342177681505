import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import { bool, func, arrayOf, shape } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";

const UserDataUpdateSuccess = ({ onClose, isApproved, listUserData }) => (
    <div className="modal-container px-0">
        <Modal.Header closeButton className="px-5" />
        <Box className="full-width full-height min-height-0">
            <Box display="flex" column fullWidth fullHeight className="px-0">
                <Modal.Body>
                    <Box display="flex" column className="px-0 px-md-5" fullWidth fullHeight>
                        <Box display="flex" fullWidth flex1 alignY="center">
                            <Box display="flex" column gap="7" className="pb-8 px-10" fullWidth>
                                <Image src="images/icons/bigCheck.svg" />
                                {isApproved ? (
                                    <Text
                                        component="p"
                                        size="6"
                                        align="center"
                                        labelKey={`remediation.modal.success.approved${
                                            listUserData?.length > 1 ? ".plural" : ""
                                        }`}
                                    />
                                ) : (
                                    <Text
                                        component="p"
                                        size="6"
                                        align="center"
                                        labelKey={`remediation.modal.success.rejected${
                                            listUserData?.length > 1 ? ".plural" : ""
                                        }`}
                                    />
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Modal.Body>
                <Box display="flex" column alignY="center" className="mt-auto pt-7 pt-md-8 px-5 px-md-9 pb-10" gap="5">
                    <Button label="global.accept" bsStyle="primary" block onClick={() => onClose()} />
                </Box>
            </Box>
        </Box>
    </div>
);

UserDataUpdateSuccess.propTypes = {
    onClose: func,
    isApproved: bool,
    listUserData: arrayOf(shape({})).isRequired,
};

UserDataUpdateSuccess.defaultProps = {
    onClose: () => {},
    isApproved: true,
};

export default resizableRoute(UserDataUpdateSuccess);
