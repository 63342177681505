import classNames from "classnames";
import Box from "pages/_components/Box";
import { PATH_NAME_MASK } from "pages/_components/NavigationBarDesktop";
import Text from "pages/_components/Text";
import { bool, number, shape, string } from "prop-types";
import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { selectors as i18nSelectors } from "reducers/i18n";
import { selectors as sessionSelectors } from "reducers/session";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";
import { countDecimalPlaces, numberFormat } from "util/number";

const REPEAT_MASK_NUMBER = 4;
class FormattedAmount extends Component {
    static propTypes = {
        align: string,
        avoidMasking: bool,
        bigDataAmount: bool,
        bold: bool,
        className: string,
        color: string,
        currency: string,
        enabledMask: bool,
        fixedDecimalScale: bool,
        frequency: string,
        frequencyInSpan: bool,
        lang: string.isRequired,
        location: shape({}),
        maskAmountUpdate: bool,
        noAmountMargin: bool,
        noCurrency: bool,
        noDecimalsWhenRound: bool,
        quantity: number,
        showAbbreviature: bool,
        size: string,
    };

    static defaultProps = {
        align: null,
        avoidMasking: false,
        bigDataAmount: false,
        bold: false,
        className: "data-amount",
        color: null,
        currency: null,
        enabledMask: true,
        fixedDecimalScale: true,
        frequency: "",
        frequencyInSpan: true,
        location: {},
        maskAmountUpdate: false,
        noAmountMargin: false,
        noCurrency: false,
        noDecimalsWhenRound: false,
        quantity: 0,
        showAbbreviature: false,
        size: null,
    };

    getCurrency = () => {
        const { currency } = this.props;
        return currency || configUtils.get("core.masterCurrency");
    };

    getAmountDetails = (quantity) => {
        const { showAbbreviature } = this.props;
        const million = 1000000;

        if (showAbbreviature) {
            if (quantity >= million) {
                return {
                    quantity: quantity / million,
                    abbreviature: i18n.get("formattedAmount.millionAbbreviature"),
                };
            }
        }

        return {
            quantity,
            abbreviature: "",
        };
    };

    renderText = (formattedValue) => {
        const {
            align,
            bigDataAmount,
            bold,
            className,
            color,
            frequency,
            frequencyInSpan,
            noAmountMargin,
            noCurrency,
            size,
        } = this.props;
        const currency = this.getCurrency();

        return (
            <>
                <Box display="flex" fullWidth={bigDataAmount} className={className}>
                    {!noCurrency && (
                        <Text className="data-amount-currency" color={color} size={size} bold={bold} align={align}>
                            {currency}
                        </Text>
                    )}
                    <Text
                        className={classNames("data-amount-quantity", { "ml-2": !noAmountMargin && !noCurrency })}
                        color={color}
                        size={size}
                        bold={bold}>
                        {formattedValue}
                    </Text>
                    {frequencyInSpan && (
                        <Text color={color} size={size} bold={bold}>
                            {frequency}
                        </Text>
                    )}
                </Box>
                {!frequencyInSpan && (
                    <Text className="data-desc" color={color} size={size} bold={bold}>
                        {frequency}
                    </Text>
                )}
            </>
        );
    };

    generateMaskAmount = () => {
        const { quantity, className, color, size, bold } = this.props;
        const currency = this.getCurrency();

        if (quantity !== undefined && currency) {
            return (
                <Box className={className}>
                    <Text
                        className="data-amount-currency"
                        color={color}
                        size={size}
                        bold={bold}
                        labelKey={`currency.label.${currency}`}
                    />
                    <Text color={color} size={size} bold={bold} className="data-amount-currency">
                        {"*".repeat(REPEAT_MASK_NUMBER)}
                    </Text>
                </Box>
            );
        }

        return "";
    };

    showMaskAmount = () => {
        const { color, size, bold } = this.props;
        return (
            <Text className="data-amount" color={color} size={size} bold={bold}>
                {this.generateMaskAmount()}
            </Text>
        );
    };

    render() {
        const {
            avoidMasking,
            enabledMask = true,
            fixedDecimalScale,
            lang,
            maskAmountUpdate,
            noDecimalsWhenRound,
            quantity,
        } = this.props;
        const currency = this.getCurrency();

        const { decimalSeparator, thousandSeparator } = numberFormat(lang);
        const { pathname } = this.props.location;
        const maximumDecimals = configUtils.get("defaultDecimal.maximum", 0);
        const minimumDecimals = configUtils.get("defaultDecimal.minimum", 0);
        if (enabledMask && maskAmountUpdate && !avoidMasking && PATH_NAME_MASK.includes(pathname)) {
            return this.showMaskAmount();
        }

        if (!currency) {
            return null;
        }

        const amount = this.getAmountDetails(quantity);
        let decimalPlaces;
        let decimalScale;

        if (amount.abbreviature.length > 0) {
            decimalScale = 1;
        } else {
            decimalPlaces = countDecimalPlaces(quantity, decimalSeparator);
            decimalScale = Math.max(Math.min(decimalPlaces, maximumDecimals), minimumDecimals);
        }

        if (noDecimalsWhenRound) {
            decimalScale = 0;
        }

        return (
            <NumberFormat
                decimalSeparator={decimalSeparator}
                thousandSeparator={thousandSeparator}
                decimalScale={decimalScale}
                value={amount.quantity}
                displayType="text"
                renderText={(formattedValue) => this.renderText(formattedValue)}
                suffix={amount.abbreviature}
                fixedDecimalScale={fixedDecimalScale}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    lang: i18nSelectors.getLang(state),
    maskAmountUpdate: sessionSelectors.getMaskAmountUpdate(state),
});

export default withRouter(connect(mapStateToProps)(FormattedAmount));
