import classNames from "classnames";
import { resizableRoute } from "pages/_components/Resizable";
import filesize from "filesize";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import Select from "pages/forms/_components/_fields/Select";
import { bool, func, number, shape, string } from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { actions as notificationActions } from "reducers/notification";
import {
    actions as updateUserDataActions,
    selectors as updateUserDataSelectors,
} from "reducers/updateUserData/updateUserData.reducer";
import { compose } from "redux";
import * as config from "util/config";
import * as i18n from "util/i18n";
import { useLoadingGlobalProvider } from "providers/LoadingGlobalProvider";
import { isEmpty } from "lodash";

const AttachedFile = ({ file, isDesktop }) => (
    <Box display="flex" alignX="flex-end" alignY="center" fullWidth withEllipsis>
        <Image
            src="images/icons/attachment.svg"
            color="text-disabled-color"
            wrapperWidth="7"
            wrapperHeight="7"
            width="7"
            height="7"
            {...(!isDesktop && { width: "5", height: "5" })}
        />
        <Text color="text" size="6" ellipsis className="max-widht-5rem">
            {file.name}
        </Text>

        <Text color="text" size="6" className="ml-2">
            ({filesize(file.size)})
        </Text>
    </Box>
);

AttachedFile.propTypes = {
    file: shape({}),
    isDesktop: bool.isRequired,
};

AttachedFile.defaultProps = {
    file: null,
};

const AttachElementInfo = ({
    dispatch,
    id,
    internalIndex,
    isMandatory,
    labelKey,
    link,
    linkLabel,
    tooltipLabel,
    mandatoryDocuments,
    margin,
    name,
    options,
    setFieldValue,
    selectedMandatoryAttachments,
    selectedAdditionalAttachments,
    selectedIdentificationAttachments,
    mandatoryCount,
    additionalCount,
    isDesktop,
    identityDocument,
    isSubmitting,
    isIdentification,
    identificationCount,
}) => {
    const [fileInputRef, setFileInputRef] = useState(React.createRef());
    const [checked, setChecked] = useState(false);
    const [addDocValue, setAddDocValue] = useState("");
    const [file, setFile] = useState({});

    const fileTypes = useMemo(() => config.get("settings.userData.allowedFileExtensions"), []);
    const fileMimeTypes = useMemo(() => config.get("settings.userData.allowedMimeTypes"), []);

    const onChangeFile = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const fileAux = event.target.files[0];
        if (fileAux) {
            const fileExtension = `${fileAux.name.split(".").slice(-1)}`;
            if (fileTypes.indexOf(fileExtension) > -1) {
                const fileMaxSize = config.get("core.maxFileSize");
                const validDocumentExtensions = config.getArray("settings.userData.allowedDocumentFileExtensions");
                if (
                    (labelKey === "settings.personalDataUpdateStep6.idPassport.label" ||
                        labelKey === "settings.personalDataUpdateStep6.idCard.label") &&
                    !validDocumentExtensions.includes(fileExtension)
                ) {
                    dispatch(
                        notificationActions.showNotification(
                            i18n.get("communications.compose.attachment.invalid"),
                            "error",
                            ["personalDataUpdate"],
                        ),
                    );
                } else if (fileAux.size < fileMaxSize) {
                    setFile(fileAux);
                    dispatch(
                        updateUserDataActions.selectFile(
                            fileAux,
                            isMandatory,
                            internalIndex,
                            labelKey,
                            identityDocument,
                            isIdentification,
                        ),
                    );
                } else {
                    dispatch(
                        notificationActions.showNotification(
                            `${i18n.get("communications.compose.attachment.maxFileSize") +
                                fileMaxSize / (1024 * 1024)}Mb`,
                            "error",
                            ["personalDataUpdate"],
                        ),
                    );
                }
            } else {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("communications.compose.attachment.invalid"),
                        "error",
                        ["personalDataUpdate"],
                    ),
                );
            }
        }
        fileInputRef.current.value = "";
        setFileInputRef(fileInputRef);
    };

    useEffect(() => {
        if (
            isMandatory &&
            mandatoryDocuments &&
            mandatoryDocuments !== null &&
            mandatoryDocuments.length > 0 &&
            file &&
            file !== null &&
            file.name !== "" &&
            selectedMandatoryAttachments &&
            selectedMandatoryAttachments !== null &&
            selectedMandatoryAttachments.length > 0 &&
            selectedMandatoryAttachments.filter((e) => e.name === file.name).length === 1
        ) {
            const f = selectedMandatoryAttachments.filter((e) => e.name === file.name)[0];
            setChecked(true);
            setFile(f);
        }
    }, [mandatoryCount]);

    useEffect(() => {
        if (
            isIdentification &&
            file &&
            file !== null &&
            file.name !== "" &&
            selectedIdentificationAttachments &&
            selectedIdentificationAttachments !== null &&
            selectedIdentificationAttachments.length > 0 &&
            selectedIdentificationAttachments.filter((e) => e.idx === internalIndex).length === 1
        ) {
            const f = selectedIdentificationAttachments.filter((e) => e.idx === internalIndex)[0];
            setChecked(true);
            setFile(f);
        }
    }, [identificationCount]);

    useEffect(() => {
        if (
            !isMandatory &&
            !isIdentification &&
            file &&
            file !== null &&
            file.name !== "" &&
            selectedAdditionalAttachments &&
            selectedAdditionalAttachments !== null &&
            selectedAdditionalAttachments.length > 0 &&
            selectedAdditionalAttachments.filter((e) => e.idx === internalIndex).length === 1
        ) {
            const f = selectedAdditionalAttachments.filter((e) => e.idx === internalIndex)[0];
            setChecked(true);
            setFile(f);
        }
    }, [additionalCount]);

    const { setLoading } = useLoadingGlobalProvider();

    useEffect(() => {
        setLoading(isSubmitting);
    }, [isSubmitting]);

    const removeSelectedFile = () => {
        let index = -1;
        const removedFile = {
            idx: internalIndex,
            name: "",
            size: "",
            content: "",
        };
        if (isMandatory) {
            const copiedMandatoryAttachments = [...selectedMandatoryAttachments];
            index = copiedMandatoryAttachments.map((e) => e.idx).indexOf(internalIndex);
            copiedMandatoryAttachments[index] = removedFile;
            dispatch(updateUserDataActions.removeMandatoryAttachments(copiedMandatoryAttachments));
        } else if (isIdentification) {
            const copiedIdentificationAttachments = [...selectedIdentificationAttachments];
            index = copiedIdentificationAttachments.map((e) => e.idx).indexOf(internalIndex);
            copiedIdentificationAttachments[index] = removedFile;
            dispatch(updateUserDataActions.removeIdentificationAttachments(copiedIdentificationAttachments));
        } else {
            const copiedAdditionalAttachments = [...selectedAdditionalAttachments];
            index = copiedAdditionalAttachments.map((e) => e.idx).indexOf(internalIndex);
            copiedAdditionalAttachments[index] = removedFile;
            dispatch(updateUserDataActions.removeAdditionalAttachments(copiedAdditionalAttachments));
        }
        setFile(null);
        setChecked(false);
    };

    const onChangeOption = (selected) => {
        const { value } = selected;
        const fieldName = `optionalDocument_${internalIndex}`;
        setFieldValue(fieldName, value);
        setAddDocValue(value);
    };

    useEffect(() => {
        if (file && !isEmpty(file) && !file?.name && !file?.size && !file?.content) {
            setFile(null);
            setChecked(false);
        }
    }, [file]);

    return (
        <Box
            display="flex"
            column
            fullWidth
            position="relative"
            background="component-background"
            className={classNames("pt-8 pb-8 py-md-7 px-5 px-md-7", margin)}
            borderRadius="default">
            <Box display="flex" fullWidth alignX="between" alignY="center">
                <Box display="flex" alignY="center" flex1 className="with-ellipsis-container">
                    <Box display="flex" className="mr-3 mr-md-5 min-width-7">
                        <Image src={checked ? "images/icons/checkedRing.svg" : "images/icons/ring.svg"} />
                    </Box>
                    <Box
                        display="flex"
                        column
                        alignX="center"
                        className="special-selector-wrapper"
                        {...(options ? { fullWidth: true } : { fitWidth: true })}>
                        {labelKey && <Text labelKey={labelKey} color="heading" size="6" bold />}
                        {options && (
                            <div className="input-group">
                                <Select
                                    id={id}
                                    name={name}
                                    options={options}
                                    onChange={onChangeOption}
                                    value={addDocValue}
                                    menuFitContent={isDesktop}
                                    containerClassName={!isDesktop ? "noEllipsis" : ""}
                                />
                            </div>
                        )}
                        {/* Only applies for mandatory docs which require links */}
                        {(checked || link) && (
                            <Box
                                display="flex"
                                {...(!isDesktop && {
                                    position: "absolute",
                                    bottom: "3",
                                    gap: "2",
                                    className: "attachment-data-width-100",
                                })}
                                fullWidth>
                                {link && (
                                    <Box
                                        display="flex"
                                        flex1
                                        {...(checked && !isDesktop && { className: "max-widht-half" })}>
                                        <Button
                                            bsStyle="link"
                                            externalHref={link}
                                            className="p-0 remove-min-height"
                                            small
                                            label={linkLabel}
                                            primaryLink
                                        />
                                    </Box>
                                )}
                                {checked && !isDesktop && (
                                    <Box display="flex" flex1 {...(link && { className: "max-widht-half" })}>
                                        <AttachedFile file={file} />
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                    {link && <Tooltip forEllipsis text={tooltipLabel} />}
                </Box>

                <Box display="flex" alignX="center" className="pl-5 pr-2 px-md-7">
                    {((options && addDocValue !== "") || !options) && (
                        <Button
                            bsStyle={isDesktop ? "link" : "only-icon"}
                            image="images/icons/upload.svg"
                            onClick={() => {
                                fileInputRef.current.click();
                            }}
                            {...(isDesktop && { label: "global.attach" })}
                        />
                    )}
                    <input
                        id="myInput"
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={(e) => onChangeFile(e)}
                        {...(fileMimeTypes && {
                            accept: fileMimeTypes.split("|").join(","),
                        })}
                    />
                </Box>
                <Box
                    display="flex"
                    alignX="flex-end"
                    {...(isDesktop && { className: "attachment-data-width", flex1: true, fullWidth: true })}>
                    {checked && isDesktop && <AttachedFile file={file} />}
                    <Button
                        image="images/icons/deleteTrash.svg"
                        onClick={() => removeSelectedFile(file)}
                        className="px-0"
                        imageMd
                        {...(checked ? { imageColor: "secondary-hover-color" } : { disabled: true })}
                    />
                </Box>
            </Box>
        </Box>
    );
};

AttachElementInfo.propTypes = {
    link: string,
    onClick: func,
    onClickTrash: func,
    labelKey: string,
    checked: bool,
    margin: string,
    linkLabel: string,
    tooltipLabel: string,
    options: shape({}),
    id: string,
    name: string,
    onChangeFile: func,
    dispatch: func.isRequired,
    internalIndex: number,
    setFieldValue: func.isRequired,
    isMandatory: bool.isRequired,
    isIdentification: bool.isRequired,
    mandatoryDocuments: shape({}),
    mandatoryCount: number,
    additionalCount: number,
    identificationCount: number,
    isDesktop: bool.isRequired,
    selectedMandatoryAttachments: shape({}),
    selectedAdditionalAttachments: shape({}),
    selectedIdentificationAttachments: shape({}),
    identityDocument: string.isRequired,
    isSubmitting: bool,
};

AttachElementInfo.defaultProps = {
    link: null,
    onClick: null,
    onClickTrash: null,
    labelKey: null,
    checked: false,
    margin: "mb-3 mb-md-5",
    linkLabel: null,
    tooltipLabel: null,
    options: null,
    id: null,
    name: null,
    onChangeFile: null,
    internalIndex: 0,
    mandatoryDocuments: null,
    mandatoryCount: 0,
    additionalCount: 0,
    identificationCount: 0,
    selectedMandatoryAttachments: null,
    selectedAdditionalAttachments: null,
    selectedIdentificationAttachments: null,
    isSubmitting: false,
};

const mapStateToProps = (state) => ({
    selectedMandatoryAttachments: updateUserDataSelectors.getSelectedMandatoryAttachments(state),
    selectedAdditionalAttachments: updateUserDataSelectors.getSelectedAdditionalAttachments(state),
    selectedIdentificationAttachments: updateUserDataSelectors.getSelectedIdentificationAttachments(state),
    mandatoryCount: updateUserDataSelectors.getMandatoryCount(state),
    additionalCount: updateUserDataSelectors.getAdditionalCount(state),
    identificationCount: updateUserDataSelectors.getIdentificationCount(state),
    identityDocument: updateUserDataSelectors.getIdentityDocument(state),
    isSubmitting: updateUserDataSelectors.isSubmitting(state),
});

export default compose(connect(mapStateToProps))(resizableRoute(AttachElementInfo));
