import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import Checkbox from "pages/_components/Checkbox";
import FieldError from "pages/_components/fields/FieldError";
import GridTable from "pages/_components/GridTable/GridTable";
import Text from "pages/_components/Text";
import { arrayOf, bool, func, shape, string } from "prop-types";
import React, { useState, useRef, useMemo } from "react";
import * as i18n from "util/i18n";
import isEqual from "lodash/isEqual";
import Country from "./Country";

const ListCountriesComponent = (props) => {
    const {
        countriesList,
        isDesktop,
        fetching,
        isAllInCheckedAuthorized,
        isAllOutCheckedAuthorized,
        isAllInCheckedUnauthorized,
        isAllOutCheckedUnauthorized,
        handlerAllSelectAuthorized,
        handlerAllSelectUnauthorized,
        disabledOutColumn,
        error = null,
    } = props;
    const getColumnsTemplate = () => "1.9fr 1.1fr 1fr";

    const bodyRefAuthorized = useRef(null);
    const bodyRefUnauthorized = useRef(null);
    const countryRefsAuthorized = useRef({});
    const countryRefsUnauthorized = useRef({});
    const [searchTermAuthorized, setSearchTermAuthorized] = useState("");
    const [searchTermUnauthorized, setSearchTermUnauthorized] = useState("");

    const handleSearchChangeAuthorized = (event) => {
        setSearchTermAuthorized(event.target.value);
    };

    const handleSearchChangeUnauthorized = (event) => {
        setSearchTermUnauthorized(event.target.value);
    };

    const authorizedCountries = useMemo(() => {
        const newCountryList = countriesList.filter((country) => country.in === true || country.out === true);
        if (searchTermAuthorized === "") {
            return newCountryList;
        }

        return newCountryList.filter((country) =>
            country.label.toLowerCase().includes(searchTermAuthorized.toLowerCase()),
        );
    }, [countriesList, searchTermAuthorized]);

    const unauthorizedCountries = useMemo(() => {
        const newCountryList = countriesList.filter((country) => country.in === false && country.out === false);
        if (searchTermUnauthorized === "") {
            return newCountryList;
        }

        return newCountryList.filter((country) =>
            country.label.toLowerCase().includes(searchTermUnauthorized.toLowerCase()),
        );
    }, [countriesList, searchTermUnauthorized]);

    return (
        <>
            <GridTable>
                {/** loading */}
                {fetching && <GridTable.Body loading={fetching} className="scrollable-content" />}

                {/** AUTHORIZED */}
                {(authorizedCountries?.length > 0 || searchTermAuthorized) && (
                    <>
                        <GridTable.Header>
                            <GridTable.Container
                                columnsTemplate={getColumnsTemplate()}
                                className={isDesktop ? "gapX-9" : "gapX-3 min-height-9-i"}
                                padding="py-2 px-5 pl-md-7 pr-md-8">
                                <GridTable.Data columnStart={1} alignX="flex-start" size="5" inHeader>
                                    {/* <Text size="6" color="text-boton" labelKey="transactional.all.countries" uppercase /> */}
                                    <Box className="input-group in-table-header">
                                        <Box>
                                            <Box
                                                display="flex"
                                                alignX="center"
                                                alignY="center"
                                                position="absolute"
                                                fullHeight>
                                                <Image src="images/search.svg" />
                                            </Box>
                                            <input
                                                className="form-control custom-font-size-6"
                                                type="text"
                                                name="searchAuthorized"
                                                placeholder={i18n.get("transactional.authorized.countries")}
                                                value={searchTermAuthorized}
                                                onChange={handleSearchChangeAuthorized}
                                                maxLength={18}
                                            />
                                        </Box>
                                    </Box>
                                </GridTable.Data>
                                <GridTable.Data columnStart={2} alignX="flex-start" size="5" inHeader>
                                    <Box display="flex" alignY="center">
                                        <Checkbox
                                            name="inAllAuthorized"
                                            className="d-flex"
                                            formGroup
                                            noLabel
                                            checked={isAllInCheckedAuthorized}
                                            onChange={(e) => handlerAllSelectAuthorized("in", e)}
                                        />
                                        <Text
                                            size="7"
                                            color="text-boton"
                                            labelKey="transactional.in.countries"
                                            uppercase
                                        />
                                    </Box>
                                </GridTable.Data>
                                <GridTable.Data columnStart={3} alignX="flex-start" size="5" inHeader>
                                    <Checkbox
                                        name="outAllAuthorized"
                                        className="d-flex"
                                        formGroup
                                        noLabel
                                        checked={isAllOutCheckedAuthorized}
                                        onChange={(e) => handlerAllSelectAuthorized("out", e)}
                                        disabled={disabledOutColumn}
                                    />
                                    <Text
                                        size="7"
                                        color="text-boton"
                                        labelKey="transactional.out.countries"
                                        uppercase
                                    />
                                </GridTable.Data>
                            </GridTable.Container>
                        </GridTable.Header>
                        <GridTable.Body
                            ref={bodyRefAuthorized}
                            loading={fetching}
                            className="scrollable-content full-height">
                            {authorizedCountries.map((country) => {
                                const assignRef = (el) => {
                                    countryRefsAuthorized.current[country.id] = el;
                                };

                                return (
                                    <div ref={assignRef} key={country.id}>
                                        <Country
                                            data={country}
                                            disabledOutColumn={disabledOutColumn}
                                            isDesktop={isDesktop}
                                            columnsTemplate={getColumnsTemplate()}
                                            isAuthorized
                                        />
                                    </div>
                                );
                            })}
                        </GridTable.Body>
                    </>
                )}

                {/** UNAUTHORIZED */}
                {(unauthorizedCountries?.length > 0 || searchTermUnauthorized) && (
                    <>
                        <GridTable.Header>
                            <GridTable.Container
                                columnsTemplate={getColumnsTemplate()}
                                className={isDesktop ? "gapX-9" : "gapX-3 min-height-9-i"}
                                padding="py-2 px-5 pl-md-7 pr-md-8">
                                <GridTable.Data columnStart={1} alignX="flex-start" size="5" inHeader>
                                    {/* <Text size="6" color="text-boton" labelKey="transactional.all.countries" uppercase /> */}
                                    <Box className="input-group in-table-header">
                                        <Box>
                                            <Box
                                                display="flex"
                                                alignX="center"
                                                alignY="center"
                                                position="absolute"
                                                fullHeight>
                                                <Image src="images/search.svg" />
                                            </Box>
                                            <input
                                                className="form-control custom-font-size-6"
                                                type="text"
                                                name="searchUnauthorized"
                                                placeholder={i18n.get("transactional.unauthorized.countries")}
                                                value={searchTermUnauthorized}
                                                onChange={handleSearchChangeUnauthorized}
                                                maxLength={18}
                                            />
                                        </Box>
                                    </Box>
                                </GridTable.Data>
                                <GridTable.Data columnStart={2} alignX="flex-start" size="5" inHeader>
                                    <Box display="flex" alignY="center">
                                        <Checkbox
                                            name="inAllUnauthorized"
                                            className="d-flex"
                                            formGroup
                                            noLabel
                                            checked={isAllInCheckedUnauthorized}
                                            onChange={(e) => handlerAllSelectUnauthorized("in", e)}
                                        />
                                        <Text
                                            size="7"
                                            color="text-boton"
                                            labelKey="transactional.in.countries"
                                            uppercase
                                        />
                                    </Box>
                                </GridTable.Data>
                                <GridTable.Data columnStart={3} alignX="flex-start" size="5" inHeader>
                                    <Checkbox
                                        name="outAllUnauthorized"
                                        className="d-flex"
                                        formGroup
                                        noLabel
                                        checked={isAllOutCheckedUnauthorized}
                                        onChange={(e) => handlerAllSelectUnauthorized("out", e)}
                                        disabled={disabledOutColumn}
                                    />
                                    <Text
                                        size="7"
                                        color="text-boton"
                                        labelKey="transactional.out.countries"
                                        uppercase
                                    />
                                </GridTable.Data>
                            </GridTable.Container>
                        </GridTable.Header>
                        <GridTable.Body
                            ref={bodyRefUnauthorized}
                            loading={fetching}
                            className="scrollable-content full-height">
                            {unauthorizedCountries.map((country) => {
                                const assignRef = (el) => {
                                    countryRefsUnauthorized.current[country.id] = el;
                                };

                                return (
                                    <div ref={assignRef} key={country.id}>
                                        <Country
                                            data={country}
                                            disabledOutColumn={disabledOutColumn}
                                            isDesktop={isDesktop}
                                            columnsTemplate={getColumnsTemplate()}
                                            isAuthorized={false}
                                        />
                                    </div>
                                );
                            })}
                        </GridTable.Body>
                    </>
                )}

                {error && (
                    <Box className="form-group has-error px-9 py-3">
                        <FieldError error={error} removeFullWidth />
                    </Box>
                )}
            </GridTable>
        </>
    );
};

ListCountriesComponent.propTypes = {
    countriesList: arrayOf(shape({})).isRequired,
    isDesktop: bool.isRequired,
    fetching: bool,
    isAllInCheckedAuthorized: bool,
    isAllOutCheckedAuthorized: bool,
    isAllInCheckedUnauthorized: bool,
    isAllOutCheckedUnauthorized: bool,
    handlerAllSelectAuthorized: func,
    handlerAllSelectUnauthorized: func,
    disabledOutColumn: bool,
    error: string,
};

ListCountriesComponent.defaultProps = {
    fetching: false,
    isAllInCheckedAuthorized: false,
    isAllOutCheckedAuthorized: false,
    isAllInCheckedUnauthorized: false,
    isAllOutCheckedUnauthorized: false,
    handlerAllSelectAuthorized: () => {},
    handlerAllSelectUnauthorized: () => {},
    disabledOutColumn: true,
    error: null,
};

export default React.memo(
    (props) => <ListCountriesComponent {...props} />,
    (prevProps, nextProps) =>
        JSON.stringify(prevProps.countriesList) === JSON.stringify(nextProps.countriesList) &&
        prevProps.fetching === nextProps.fetching &&
        prevProps.disabledOutColumn === nextProps.disabledOutColumn &&
        prevProps.isAllInCheckedAuthorized === nextProps.isAllInCheckedAuthorized &&
        prevProps.isAllOutCheckedAuthorized === nextProps.isAllOutCheckedAuthorized &&
        prevProps.isAllInCheckedUnauthorized === nextProps.isAllInCheckedUnauthorized &&
        prevProps.isAllOutCheckedUnauthorized === nextProps.isAllOutCheckedUnauthorized &&
        isEqual(prevProps.error, nextProps.error),
);
