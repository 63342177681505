import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import BanescoCheckbox from "pages/_components/fields/BanescoCheckbox";
import GridTable from "pages/_components/GridTable/GridTable";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import { arrayOf, bool, func, shape } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import * as i18n from "util/i18n";
import Image from "pages/_components/Image";
import Line from "pages/_components/Line";

const UserDataUpdateRequestList = ({
    fetching,
    listUserData,
    handleFilterData,
    handleCheckboxChange,
    handleApprove,
    handleReject,
    handleSelectUser,
    selectedUsers,
    values,
    setFieldValue,
    showSearchHelp,
}) => {
    const createTable = () => {
        const columnsTemplate = "auto 1fr 1fr auto";

        const list = listUserData.map((user) => (
            <GridTable.Container
                className="min-height-12-i"
                columnsTemplate={columnsTemplate}
                key={user.idRequest}
                onClick={() => handleSelectUser(user)}
                padding="px-0">
                <GridTable.Data columnStart={1} alignX="flex-start" alignY="center">
                    <BanescoCheckbox
                        name={`checkbox-${user?.idRequest}}`}
                        value={user?.idUser}
                        defaultChecked={false}
                        checked={selectedUsers?.includes(user)}
                        onChange={(e) => {
                            handleCheckboxChange(e.target.checked, user, setFieldValue, values);
                        }}
                        hideLabel
                        className="m-0"
                    />
                </GridTable.Data>
                <GridTable.Data columnStart={2} alignX="flex-start" alignY="center">
                    <Box display="flex" column alignX="flex-start" fullWidth>
                        <Text color="secondary" size="6" align="left" bold ellipsis>
                            {`${user?.name || ""} ${user?.lastName || ""}`.trim()}
                        </Text>
                        <Text color="text" size="6" ellipsis>
                            {user?.document}
                        </Text>
                    </Box>
                </GridTable.Data>
                <GridTable.Data columnStart={3} alignX="flex-end" alignY="center">
                    <Box display="flex" column fullWidth>
                        <Text color="text" size="6" ellipsis>
                            {user?.email}
                        </Text>
                        <Text color="text" size="6" ellipsis>
                            +{user?.mobileNumber}
                        </Text>
                    </Box>
                </GridTable.Data>
                <GridTable.Data columnStart={4} alignY="center">
                    <Image src="images/chevromRight.svg" color="text-color" className="svg-caret" />
                </GridTable.Data>
            </GridTable.Container>
        ));

        return (
            <GridTable>
                <GridTable.Body loading={fetching} className="my-6 px-7 scrollable-content scroll">
                    {showSearchHelp ? (
                        <Box display="flex" alignX="center" gap="5" className="px-5 pb-0" align="center">
                            <Text
                                size={6}
                                color="heading-color"
                                className="px-5"
                                labelKey="remediation.modal.list.search.help"
                            />
                        </Box>
                    ) : (
                        list
                    )}
                </GridTable.Body>
            </GridTable>
        );
    };

    return (
        <div className="modal-container px-0">
            <Modal.Header closeButton className="px-5">
                <Box display="flex" column alignY="center">
                    <Text
                        id="modalTitleID"
                        component="h4"
                        labelKey="remediation.modal.list.title"
                        className="mt-0 mb-2"
                        color="heading-color"
                        size="3"
                        align="center"
                        bold
                    />
                    <Text
                        id="modalTextID"
                        labelKey="remediation.modal.list.description"
                        className="mt-0 mb-2"
                        color="heading-color"
                        size="11"
                        align="center"
                        regular
                    />
                    <Line color="background-divider" className="mt-5" />
                </Box>
            </Modal.Header>
            <Box className="full-width full-height min-height-0">
                <Box display="flex" column fullWidth fullHeight className="px-0 px-md-0">
                    <Box display="flex" flex1 className="min-height-7rem">
                        <Modal.Body>
                            <Box display="flex" column className="px-0 px-md-5" fullWidth fullHeight>
                                <Box display="flex" alignX="between" gap="5" className="px-7">
                                    <TextSimpleField
                                        name="searchList"
                                        placeholder={i18n.get("remediation.modal.list.search.placeholder")}
                                        onChange={(e) => handleFilterData(e.target.value)}
                                        pattern="^[a-zA-Z0-9ñ@+._-]*$"
                                        serarchStyle
                                    />
                                </Box>
                                <Box display="flex" flex1 className="min-height-7rem">
                                    {createTable()}
                                </Box>
                            </Box>
                        </Modal.Body>
                    </Box>
                    <Box
                        display="flex"
                        column
                        alignY="center"
                        className="mt-auto pt-7 pt-md-8 px-5 px-md-9 pb-10"
                        gap="5">
                        <Line color="background-divider" className="mb-3" />
                        <Button
                            bsStyle="primary"
                            label="global.approve"
                            block
                            onClick={() => handleApprove(setFieldValue)}
                            disabled={fetching || selectedUsers.length === 0}
                        />
                        <Button
                            bsStyle="outline"
                            label="global.reject"
                            block
                            onClick={() => handleReject(setFieldValue)}
                            disabled={fetching || selectedUsers.length === 0}
                        />
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

UserDataUpdateRequestList.propTypes = {
    fetching: bool.isRequired,
    handleFilterData: func,
    handleCheckboxChange: func,
    handleApprove: func,
    handleReject: func,
    handleSelectUser: func,
    setFieldValue: func,
    listUserData: arrayOf(shape({})).isRequired,
    selectedUsers: arrayOf(shape({})),
    values: arrayOf(shape({})).isRequired,
    showSearchHelp: bool,
};

UserDataUpdateRequestList.defaultProps = {
    handleFilterData: () => {},
    handleCheckboxChange: () => {},
    handleApprove: () => {},
    handleReject: () => {},
    handleSelectUser: () => {},
    setFieldValue: () => {},
    selectedUsers: [],
    showSearchHelp: false,
};

export default resizableRoute(UserDataUpdateRequestList);
