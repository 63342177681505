import classNames from "classnames";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import withExchangeToken from "pages/_components/withExchangeToken";
import Logo from "pages/login/_components/Logo";
import RemediationUserContentStep2 from "pages/RemediationUser/_components/RemediationUserContentStep2";
import { bool, func, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "react-router-redux/actions";
import { actions as loginActions, selectors as loginSelectors } from "reducers/login";
import { compose } from "redux";

class RemediationUserStep2 extends Component {
    onHeaderClose = () => {
        const { dispatch } = this.props;
        dispatch(loginActions.reset());
        dispatch(push("/"));
    };

    render() {
        const { isDesktop, userFirstName } = this.props;
        return (
            <>
                {isDesktop && (
                    <Head
                        additionalClassName="special-header-login"
                        logo={<Logo isDesktop={isDesktop} className="svg-image logo" />}
                        replace={{
                            USER_NAME:
                                userFirstName && userFirstName.length > 1
                                    ? userFirstName
                                          .split(" ")
                                          .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
                                          .join(" ")
                                    : "",
                        }}
                        accessibilityTextId="activities.session.login.step3.a11y"
                        flex
                    />
                )}
                {!isDesktop && (
                    <Head
                        onClose={this.onHeaderClose}
                        logo={<Logo isDesktop={isDesktop} className="svg-image logo" />}
                    />
                )}
                <Notification scopeToShow="loginStep3" />
                <MainContainer
                    className={classNames("main-container", {
                        "container-fluid with-special-header-login": isDesktop,
                    })}>
                    <RemediationUserContentStep2 className={classNames("app-default-main styled-2")} />
                </MainContainer>
            </>
        );
    }
}

RemediationUserStep2.propTypes = {
    isDesktop: bool.isRequired,
    userFirstName: string.isRequired,
    dispatch: func.isRequired,
};

RemediationUserStep2.defaultProps = {};

const mapStateToProps = (state) => ({
    userFirstName: loginSelectors.getUserFirstName(state),
});

export default compose(withRouter, connect(mapStateToProps))(withExchangeToken(RemediationUserStep2));
