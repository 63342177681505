import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Line from "pages/_components/Line";
import { resizableRoute } from "pages/_components/Resizable";
import Text from "pages/_components/Text";
import TextSimpleField from "pages/forms/customForms/_customFields/TextSimpleField";
import Select from "pages/forms/_components/_fields/Select";
import FieldLabel from "pages/_components/fields/FieldLabel";
import PhoneInput from "pages/_components/fields/PhoneInput";
import { arrayOf, func, shape } from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";
import { Field } from "formik";
import * as i18n from "util/i18n";

const UserDataUpdateDetail = ({ handleBack, userData, listEnvironments, countryList }) => (
    <div className="modal-container px-0 scrollable-content">
        <Modal.Header closeButton className="px-5">
            <Box display="flex" column>
                <Text
                    id="modalTitleID"
                    component="h4"
                    labelKey="remediation.modal.detail.title"
                    className="mt-0 mb-2"
                    color="heading-color"
                    size="3"
                    align="center"
                    bold
                />
                <Line color="background-divider" className="mt-3 mb-4" />
                <Text
                    id="modalsubTextID"
                    labelKey="remediation.modal.detail.description"
                    className="mt-0 mb-2"
                    color="heading-color"
                    size="10"
                    align="left"
                    bold
                />
                {listEnvironments.map((environmet) => (
                    <Text className="mt-0 mb-2" color="text" size="6" align="left">
                        {environmet.name}
                    </Text>
                ))}
                <Line color="background-divider" className="mt-3" />
            </Box>
        </Modal.Header>
        <Modal.Body>
            <Box display="flex" column className="px-0 px-md-5" fullWidth fullHeight>
                <Box className="px-7">
                    <Box display="flex" alignX="between" gap="5" className="pt-5">
                        <TextSimpleField
                            labelKey="remediation.modal.detail.name.label"
                            name="name"
                            disabled
                            value={userData?.name}
                        />
                    </Box>
                    <Box display="flex" alignX="between" gap="5" className="pt-5">
                        <TextSimpleField
                            labelKey="remediation.modal.detail.lastName.label"
                            name="lastName"
                            disabled
                            value={userData?.lastName}
                        />
                    </Box>
                    <Box display="flex" alignX="between" gap="5" className="pt-5">
                        <TextSimpleField
                            labelKey="remediation.modal.detail.email.label"
                            name="mail"
                            disabled
                            value={userData?.email}
                        />
                    </Box>
                    <Box display="flex" alignX="between" gap="5" className="pt-5">
                        <Field
                            component={PhoneInput}
                            idForm="remediation.modal.detail"
                            idField="remediation.modal.detail.mobileNumber"
                            name="mobileNumber"
                            isDesktop
                            className="remove-flex"
                            mobilePhone={userData?.mobileNumber}
                            preferredCountries={["pa", "ve"]}
                            areaCodes={{ ve: ["412", "414", "416", "424", "426"] }}
                            labelNoMarginTop
                            disabled
                        />
                    </Box>
                    <Box display="block" alignX="between" gap="5" className="pt-5">
                        <FieldLabel
                            idField="remediation.modal.detail.documentCountry.label"
                            labelKey="remediation.modal.detail.documentCountry.label"
                        />
                        <div className="input-group">
                            <Select
                                label="remediation.modal.detail.documentCountry"
                                name="documentCountry"
                                value={userData?.documentCountry}
                                options={countryList}
                                labelKey="name"
                                valueKey="id"
                                disabled
                            />
                        </div>
                    </Box>
                    <Box display="block" alignX="between" gap="5" className="pt-5">
                        <FieldLabel
                            idField="remediation.modal.detail.documentType.label"
                            labelKey="remediation.modal.detail.documentType.label"
                        />
                        <div className="input-group">
                            <Select
                                label="remediation.modal.detail.documentCountry"
                                name="documentType"
                                value={userData?.documentType}
                                options={[
                                    {
                                        id: userData?.documentType,
                                        name: i18n.get(`documentType.label.${userData?.documentType}`),
                                    },
                                ]}
                                labelKey="name"
                                valueKey="id"
                                disabled
                            />
                        </div>
                    </Box>
                    <Box display="flex" alignX="between" gap="5" className="pt-5">
                        <TextSimpleField
                            labelKey="remediation.modal.detail.document.label"
                            name="document"
                            disabled
                            value={userData?.document}
                        />
                    </Box>
                </Box>
                <Box display="flex" column alignY="center" className="mt-auto pt-7 pt-md-8 px-5 px-md-7 pb-10" gap="7">
                    <Line color="background-divider" className="my-2" />
                    <Button bsStyle="outline" label="global.goBack" block onClick={() => handleBack()} />
                </Box>
            </Box>
        </Modal.Body>
    </div>
);

UserDataUpdateDetail.propTypes = {
    countryList: arrayOf(shape({})),
    userData: shape({}),
    handleBack: func,
    listEnvironments: arrayOf(shape({})),
};

UserDataUpdateDetail.defaultProps = {
    countryList: [],
    userData: {},
    handleBack: () => {},
    listEnvironments: [],
};

export default resizableRoute(UserDataUpdateDetail);
