import { call, put, select, takeLatest } from "redux-saga/effects";

import { types, actions, selectors as userDataUpdateRequestActions } from "reducers/userDataUpdateRequest";
import * as userDataUpdateRequest from "middleware/userDaraUpdateRequest";
import { actions as notificationActions } from "reducers/notification";
import { selectors as loginActions } from "reducers/login";

import * as i18n from "util/i18n";
import * as form from "middleware/form";
import { replace } from "react-router-redux";
import * as utilIdentification from "util/recoveryUser";
import { adjustIdFieldErrors } from "util/form";

const sagas = [
    takeLatest(types.LIST_USER_DATA_UPDATE_REQUEST, listUserDataUpdateRequest),
    takeLatest(types.USER_DATA_UPDATE_SEND_ADMIN_REQUEST, sendDataUserDataUpdateAdminRequest),
    takeLatest(types.USER_DATA_UPDATE_GROUPS_REQUEST, getcredencialsGroups),
    takeLatest(types.USER_DATA_UPDATE_PRE_REQUEST, loadDataUserDataUpdateRequest),
    takeLatest(types.USER_DATA_UPDATE_SEND_REQUEST, sendDataUserDataUpdateRequest),
];

export default sagas;

function* listUserDataUpdateRequest() {
    const response = yield call(userDataUpdateRequest.listUserDataUpdateRequest);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["desktop"]));
        yield put(actions.listUserDataUpdateRequestFailed());
        yield put(actions.modalHide());
    } else {
        const { listUserData, listEnvironments, countryList } = response.data.data;
        yield put(
            actions.listUserDataUpdateRequestSuccess(listUserData || [], listEnvironments || [], countryList || []),
        );
    }
}

function* sendDataUserDataUpdateAdminRequest({ status, selectedUsers, otp, onFinish, formikBag, isNeedSignedByAdmin }) {
    const response = yield call(userDataUpdateRequest.sendUserDataUpdateRequestAdmin, status, selectedUsers, otp);

    if (response && response.status === 200 && response.data.code === "COR000I") {
        yield put(actions.sendDataUserDataUpdateRequestAdminSendSuccess(isNeedSignedByAdmin));
        if (onFinish) {
            onFinish();
        }
    } else {
        formikBag.setErrors(adjustIdFieldErrors(response.data.data));
        yield put(actions.sendDataUserDataUpdateRequestAdminSendFailed());
    }

    formikBag.setSubmitting(false);
}

function* getcredencialsGroups({ data }) {
    const { idActivity } = data;
    const response = yield call(form.listCredentialsGroups, "", idActivity);

    if (response && response.status === 200) {
        yield put({
            type: types.USER_DATA_UPDATE_GROUPS_SUCCESS,
            credentialsGroups: response.data.data.groups,
        });
    } else {
        yield put(notificationActions.showNotification(response.data.message, "error", ["desktop"]));
    }
}

function* loadDataUserDataUpdateRequest() {
    const exchangeToken = yield select(loginActions.getExchangeToken);
    const response = yield call(userDataUpdateRequest.loadUserDataUpdateRequest, exchangeToken);

    if (response.type === "W") {
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["login"]));
        yield put(actions.loadDataUserDataUpdateRequestPreFailed());
    } else {
        const { countryList, documentTypeList, idNumberMap1, idNumberMap2, _exchangeToken } = response.data.data;
        yield put(
            actions.loadDataUserDataUpdateRequestPreSuccess(
                countryList,
                documentTypeList,
                idNumberMap1,
                idNumberMap2,
                _exchangeToken,
            ),
        );
    }
}

function* sendDataUserDataUpdateRequest({
    name,
    lastName,
    email,
    mobilePhone,
    documentUser,
    dataTreatment,
    formikBag,
}) {
    const exchangeToken = yield select(userDataUpdateRequestActions.getExchangeToken);

    const documentNumber = utilIdentification.generateIdentificationToSend(documentUser);

    const response = yield call(
        userDataUpdateRequest.sendUserDataUpdateRequest,
        name,
        lastName,
        email,
        mobilePhone,
        documentUser,
        documentNumber,
        dataTreatment,
        exchangeToken,
    );

    if (response.type === "W") {
        yield put(
            notificationActions.showNotification(i18n.get("global.unexpectedError"), "warning", [
                "remediationUserStep1",
            ]),
        );
        if (formikBag) {
            formikBag.setSubmitting(false);
            formikBag.setErrors(response.data.data);
        }
    } else {
        yield put(replace("/remediationUser/step2"));
    }
}
