import classNames from "classnames";
import { Field } from "formik";
import moment from "moment";
import {
    PDFAmountField,
    PDFAmountFieldError,
    PDFProductSelectorField,
    PDFTextField,
} from "pages/forms/customForms/PDFTicket";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Checkbox from "pages/_components/Checkbox";
import Container from "pages/_components/Container";
import Disclaimer from "pages/_components/Disclaimer";
import EmptyTable from "pages/_components/EmptyTable";
import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import GridTable from "pages/_components/GridTable/GridTable";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import Tooltip from "pages/_components/Tooltip";
import { arrayOf, bool, func, shape, string } from "prop-types";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { goBack, push } from "react-router-redux";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as loanActions, selectors as loanSelectors } from "reducers/loans";
import { compose } from "redux";
import * as i18n from "util/i18n";
import { numberFormat } from "util/number";
import { actions as notificationActions } from "reducers/notification";
import useAsyncTicketProcessing from "hooks/useAsyncTicketProcessing";
import { statusMap } from "util/general";
import { payLoanButtonIsDisabled } from "pages/loans/hooks/loandsHook";
import FormTransition from "../_components/FormTransition";

const ID_FORM = "loan.payment";
const ID_ACTIVITY = `${ID_FORM}.send`;
const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

const LoanPaymentForm = (props) => {
    const {
        mode,
        dispatch,
        location,
        preDataForm,
        isDesktop,
        isMobile,
        statements,
        transaction,
        usesJointAccount,
    } = props;
    const [TooltipProcessingComponent] = useAsyncTicketProcessing(transaction);
    const [loadingFees, setLoadingFees] = useState(false);
    const [checkedFees, setCheckedFees] = useState([]);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [previousLoan, setPreviousLoan] = useState(null);
    const [unpaidStatements, setUnpaidStatements] = useState(null);
    const [isPayButtonDisabledForLoan, setIsPayButtonDisabledForLoan] = useState(false);

    const { decimalSeparator, thousandSeparator } = numberFormat();
    useEffect(() => {
        setUnpaidStatements(statements?.filter((statement) => !statement.paid));
    }, [statements]);

    useEffect(() => {
        if (mode === "edit") {
            const formData = {};
            dispatch(loanActions.clearLoanStatement());
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, formData }));
        }
        // eslint-disable-next-line
    }, [dispatch, location]);

    const onListFeesFinish = () => {
        setLoadingFees(false);
    };

    const onOtherAmountOptionClick = (setFieldValue) => {
        setCheckedFees([]);
        setSubmitDisabled(isPayButtonDisabledForLoan);
        setFieldValue("amount", { currency: "USD", quantity: 0 });
        setTimeout(() => {
            document.querySelector("#otherAmount").focus();
        }, 0);
    };

    const loanOrigin = parse(location.search)?.loan;

    const cancelAction = () => {
        if (loanOrigin) {
            dispatch(goBack());
        } else {
            dispatch(push("/desktop"));
        }
    };

    const preData = preDataForm || {
        debitAccountList: [],
        loanList: [],
    };

    const renderTicket = (values) => {
        const { idTransactionStatus } = props.transaction;
        const idStatus = statusMap.get(idTransactionStatus) ?? idTransactionStatus;
        const showReference = idStatus === "FINISHED" || idStatus === "REJECTED";

        return (
            <>
                {idStatus === "FINISHED" ? (
                    <PDFAmountField
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label_view`)}
                    />
                ) : (
                    <PDFAmountFieldError
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label_view`)}
                    />
                )}

                {showReference && (
                    <PDFTextField
                        idForm={ID_FORM}
                        name="backendReference"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.voucher.label`)}
                    />
                )}

                <PDFTextField
                    idForm={ID_FORM}
                    value={moment(transaction?.valueDateTime).format("DD/MM/YYYY")}
                    label={i18n.get(`forms.${ID_FORM}.date.label`)}
                />

                <PDFProductSelectorField idForm={ID_FORM} name="debitAccount" values={values} />

                <PDFTextField
                    idForm={ID_FORM}
                    name="loanType"
                    value={values?._loanType || values?.loanType}
                    label={i18n.get(`forms.${ID_FORM}.loanType.label`)}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    name="loanNumber"
                    value={values?.loanPaymentData?.number}
                    label={i18n.get(`forms.${ID_FORM}.loanNumber`)}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    name="loanNumber"
                    value={i18n.get(`forms.${ID_FORM}.paymentType.${values?.paymentType}`)}
                    label={i18n.get(`forms.${ID_FORM}.paymentType.label`)}
                />
            </>
        );
    };

    const renderFields = (setFieldValue, values) => {
        const { currentLang, fromBackoffice, amountPayment, feePayment } = props;
        const idTransaction = transaction?.idTransaction;

        if (values?.loanPayment) {
            const loan = preData?.loanList?.find((e) => values?.loanPayment === e.idProduct);
            setIsPayButtonDisabledForLoan(payLoanButtonIsDisabled(loan?.acctBlockCod));
        }

        const onOtherAmountChange = (e) => {
            setFieldValue("amount", e);
        };

        if (unpaidStatements?.length === 0 && !values?.otherAmountOption?.includes("otherAmount") && !loadingFees) {
            if (values?.paymentType === "fee") {
                setFieldValue("otherAmountOption", ["otherAmount"]);
            }
            setSubmitDisabled(isPayButtonDisabledForLoan);
        }

        const onPaymentTypeClick = (type) => {
            // inactivo boton pagar
            setSubmitDisabled(true);
            // desmarco el radio
            setFieldValue("otherAmountOption", null);
            setFieldValue("otherAmount", "");

            setFieldValue("paymentType", type);
            if (type === "amount" && !!unpaidStatements) {
                setCheckedFees([]);
                setSubmitDisabled(isPayButtonDisabledForLoan);
            }
            setFieldValue("amount", { currency: "USD", quantity: "" });
        };

        const onLoanPaymentChange = (value) => {
            if (previousLoan !== value) {
                setPreviousLoan(value);
                setCheckedFees([]);
                setLoadingFees(true);
                setSubmitDisabled(true);
                setFieldValue("otherAmountOption", null);
                setFieldValue("otherAmount", "");
                onPaymentTypeClick("fee");
                dispatch(loanActions.listStatements(value, onListFeesFinish));
            }
        };

        const calculateFeesTotalAmount = (newCheckedFees) => {
            const total = unpaidStatements.reduce((accum, el) => {
                if (newCheckedFees.includes(el.idFee)) {
                    return accum + el.dueBalance;
                }
                return accum;
            }, 0);

            setFieldValue("amount", { currency: "USD", quantity: total });
        };

        const onFeeClicked = (idFee) => {
            const newCheckedFees = [...checkedFees];

            let changed = false;
            if (newCheckedFees.includes(idFee)) {
                if (idFee === newCheckedFees[newCheckedFees.length - 1]) {
                    newCheckedFees.pop();
                    changed = true;
                }
            } else if (newCheckedFees.length === 0) {
                if (idFee === unpaidStatements[0].idFee) {
                    newCheckedFees.push(idFee);
                    changed = true;
                }
            } else {
                const idxIdFee = unpaidStatements.findIndex((statement) => statement.idFee === idFee);
                const lastCheckedIdFee = newCheckedFees[newCheckedFees.length - 1];
                const idxLastCheckedFee = unpaidStatements.findIndex(
                    (statement) => statement.idFee === lastCheckedIdFee,
                );

                if (idxIdFee === idxLastCheckedFee + 1) {
                    newCheckedFees.push(idFee);
                    changed = true;
                }
            }

            if (changed) {
                setCheckedFees(newCheckedFees);

                // desmarco el radio
                setFieldValue("otherAmountOption", null);
                setFieldValue("otherAmount", "");
                setSubmitDisabled(isPayButtonDisabledForLoan || newCheckedFees.length === 0);
            }

            // calculo el monto
            calculateFeesTotalAmount(newCheckedFees);
        };

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        if (preDataForm && (preDataForm.debitAccountList.length === 0 || preDataForm.loanList.length === 0)) {
            dispatch(push("/desktop"));
            dispatch(
                notificationActions.showNotification(i18n.get("activities.loan.payment.pre.error"), "error", [
                    "desktop",
                    "login",
                ]),
            );
        }

        if (mode === "edit" && (!preDataForm || !preDataForm.debitAccountList)) {
            return <PageLoading loading />;
        }

        const getDataAmount = (amount) => ({
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options: [
                {
                    id: amount?.currency,
                    label: amount?.currency ? i18n.get(`currency.label.${amount?.currency}`) : "",
                },
            ],
        });

        let debitAccountListOptions = {
            options: preData?.debitAccountList?.map((acc) => ({
                ...acc,
                balance: { currency: acc.currency, quantity: acc.balance },
                id: acc.idProduct,
                disabled: acc.isDisabled,
            })),
        };
        if (usesJointAccount) {
            const daData = transaction.data.debitAccountData;
            if (daData) {
                debitAccountListOptions = { options: [{ ...daData, id: daData.idProduct, label: daData.otherLabel }] };
            }
        }

        const loanListOptions = {
            options: preData?.loanList?.map((loan) => ({
                ...loan,
                id: loan.idProduct,
            })),
        };

        const dataAmount = {
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options: ["USD"],
        };

        const getLoanType = (loanPayment) => preData?.loanList?.find((loan) => loan.idProduct === loanPayment)?.subType;
        const getCurrentBalance = (loanPayment) =>
            preData?.loanList?.find((loan) => loan.idProduct === loanPayment)?.currentBalance;

        // eslint-disable-next-line no-confusing-arrow
        const renderPaymentTypes = () => (
            <Box fullWidth className="pb-5 px-5 px-md-0" border="bottom-background-divider-1">
                <Row>
                    <Col xs={6}>
                        <Box display="flex" alignX="flex-end" fullWidth>
                            <Button
                                bsStyle="chip"
                                className={classNames({
                                    "is-active": values?.paymentType === "fee",
                                })}
                                key="fee"
                                label="forms.loan.payment.paymentType.fee"
                                onClick={() => onPaymentTypeClick("fee")}
                                image={values?.paymentType === "fee" ? "images/check.svg" : ""}
                            />
                        </Box>
                    </Col>

                    <Col xs={6}>
                        <Box display="flex" alignX="flex-start" fullWidth>
                            <Button
                                bsStyle="chip"
                                className={classNames({
                                    "is-active": values?.paymentType === "amount",
                                })}
                                key="amount"
                                label="forms.loan.payment.paymentType.amount"
                                onClick={() => onPaymentTypeClick("amount")}
                                image={values?.paymentType === "amount" ? "images/check.svg" : ""}
                            />
                        </Box>
                    </Col>
                </Row>
            </Box>
        );

        // eslint-disable-next-line no-confusing-arrow
        const columnsTypes = () =>
            isDesktop ? "3.25rem minmax(8rem, 1fr) repeat(7, 1fr) 1.5rem" : "1.5rem minmax(auto, 11rem)  1fr 2rem";

        const renderPaymentAmount = () => (
            <Box
                display="flex"
                background="heading-color"
                className="px-5 px-md-7 py-6 mt-md-5 mb-7 mb-md-0"
                borderRadius="default"
                alignX={isDesktop ? "flex-end" : "between"}
                alignY="center"
                fullWidth>
                <Field
                    {...genericProps}
                    component={FormFieldsComponents.Hidden}
                    key="amount"
                    name="amount"
                    idField="amount"
                    value={values?.amount || { currency: "USD", quantity: 0 }}
                />

                <Text color="inverse" size="5" labelKey="forms.loan.payment.totalAmountToPay" className="mr-8" />

                <FormattedAmount
                    color="inverse"
                    size="big"
                    bold
                    className="data-amount justify-content-end"
                    {...(values?.amount || { currency: "USD", quantity: 0 })}
                />
            </Box>
        );

        const renderAmount = () => (
            <>
                {(unpaidStatements || unpaidStatements?.length === 0) && (
                    <Box className="px-5 pt-5 pt-md-7 pb-9 pb-md-11">
                        <Row gapY={isDesktop ? "8" : "5"}>
                            <Col xs={12} md={4} mdOffset={4}>
                                <Box
                                    display="flex"
                                    alignY="center"
                                    alignX="between"
                                    className="px-5 py-7"
                                    borderRadius="default"
                                    background="grey"
                                    fullWidth>
                                    <Box display="flex" alignY="center">
                                        <Text
                                            size={isDesktop ? "6" : "5"}
                                            color="heading"
                                            labelKey="forms.loan.payment.amount.balance"
                                        />
                                        <Tooltip
                                            text="forms.loan.payment.amount.balance.tooltip"
                                            position="bottom-centered"
                                        />
                                    </Box>
                                    <FormattedAmount
                                        className={classNames("data-amount justify-content-end")}
                                        quantity={getCurrentBalance(values?.loanPayment)}
                                        currency="USD"
                                        size="5"
                                        color="heading"
                                        bold
                                    />
                                </Box>
                            </Col>
                            <Col xs={12} md={4} mdOffset={4}>
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Amount}
                                    data={dataAmount}
                                    hideCurrency
                                    key="amount"
                                    name="amount"
                                    value={{ currency: "USD", quantity: values?.amount }}
                                    idField="amount"
                                    customPlaceholderQuantity="0.00"
                                    quantityTextAlign="text-left"
                                    labelNoMarginTop
                                />
                            </Col>
                        </Row>
                    </Box>
                )}
            </>
        );

        const renderTable = () => (
            <>
                <Container className="container--layout scrollable movements-container">
                    <GridTable>
                        <GridTable.Header>
                            <GridTable.Container columnsTemplate={columnsTypes()}>
                                <>
                                    <GridTable.Data
                                        columnStart={1}
                                        {...(!isDesktop && { columnWidth: 2 })}
                                        alignX="flex-start"
                                        inHeader>
                                        <Text
                                            labelKey={
                                                isDesktop
                                                    ? "forms.loan.payment.fees.feeId"
                                                    : "forms.loan.payment.fees.feeIdDueDate"
                                            }
                                        />
                                    </GridTable.Data>
                                    {isDesktop && (
                                        <>
                                            <GridTable.Data columnStart={2} alignX="center" inHeader>
                                                <Text labelKey="forms.loan.payment.fees.dueDate" align="center" />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={3} alignX="center" inHeader>
                                                <Text labelKey="forms.loan.payment.fees.capital" align="center" />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={4} alignX="flex-end" inHeader>
                                                <Text labelKey="forms.loan.payment.fees.interest" align="right" />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={5} alignX="flex-end" inHeader>
                                                <Text labelKey="forms.loan.payment.fees.delay" align="right" />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={6} alignX="flex-end" inHeader>
                                                <Text labelKey="forms.loan.payment.fees.feci" align="right" />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={7} alignX="flex-end" inHeader>
                                                <Text labelKey="forms.loan.payment.fees.otherCharges" align="right" />
                                            </GridTable.Data>
                                            <GridTable.Data columnStart={8} alignX="flex-end" inHeader>
                                                <Text labelKey="forms.loan.payment.fees.initialAmount" align="right" />
                                            </GridTable.Data>
                                        </>
                                    )}
                                    <GridTable.Data columnStart={isDesktop ? 9 : 3} alignX="flex-end" inHeader>
                                        <Text labelKey="forms.loan.payment.fees.owedAmount" align="right" />
                                    </GridTable.Data>
                                </>
                            </GridTable.Container>
                        </GridTable.Header>
                        <GridTable.Body>
                            <>
                                {unpaidStatements?.length === 0 && (
                                    <>
                                        <EmptyTable labelKey="forms.loan.payment.fees.noFee" />
                                    </>
                                )}

                                {unpaidStatements?.map((statement) => (
                                    <GridTable.Container
                                        className="product-data"
                                        columnsTemplate={columnsTypes()}
                                        key={statement.idFee}>
                                        <GridTable.Data columnStart={1} alignX="flex-Start">
                                            <Text {...(!isDesktop && { bold: true, size: "5" })}>
                                                {statement.idFee}
                                            </Text>
                                            {/* {paymentPlan && (
                                                    <Sticker status="success" labelKey="Vigente" className="ml-3" />
                                                )} */}
                                        </GridTable.Data>
                                        <GridTable.Data columnStart={2} alignX={isDesktop ? "center" : "flex-start"}>
                                            <FormattedDate
                                                date={statement.dueDate}
                                                size={isDesktop ? "6" : "5"}
                                                {...(!isDesktop && { color: "text" })}
                                            />
                                        </GridTable.Data>
                                        {isDesktop && (
                                            <>
                                                <GridTable.Data columnStart={3} alignX="center">
                                                    <FormattedAmount
                                                        size="6"
                                                        className="data-amount justify-content-end"
                                                        quantity={statement.principalBalance}
                                                        noCurrency
                                                    />
                                                </GridTable.Data>
                                                <GridTable.Data columnStart={4} alignX="flex-end">
                                                    <FormattedAmount
                                                        size="6"
                                                        className="data-amount justify-content-end"
                                                        quantity={statement.interest}
                                                        noCurrency
                                                    />
                                                </GridTable.Data>
                                                <GridTable.Data columnStart={5} alignX="flex-end">
                                                    <FormattedAmount
                                                        size="6"
                                                        className="data-amount justify-content-end"
                                                        quantity={statement.penaltyInterest}
                                                        noCurrency
                                                    />
                                                </GridTable.Data>
                                                <GridTable.Data columnStart={6} alignX="flex-end">
                                                    <FormattedAmount
                                                        size="6"
                                                        className="data-amount justify-content-end"
                                                        quantity={statement.feci}
                                                        noCurrency
                                                    />
                                                </GridTable.Data>
                                                <GridTable.Data columnStart={7} alignX="flex-end">
                                                    <FormattedAmount
                                                        size="6"
                                                        className="data-amount justify-content-end"
                                                        quantity={statement.overdraft}
                                                        noCurrency
                                                    />
                                                </GridTable.Data>
                                                <GridTable.Data columnStart={8} alignX="flex-end">
                                                    <FormattedAmount
                                                        size="6"
                                                        className="data-amount justify-content-end"
                                                        quantity={statement.amount}
                                                        noCurrency
                                                    />
                                                </GridTable.Data>
                                            </>
                                        )}
                                        <GridTable.Data
                                            columnStart={isDesktop ? 9 : 3}
                                            alignX="flex-end"
                                            {...(!isDesktop && { rowStart: 1, rowWidth: 2 })}>
                                            <FormattedAmount
                                                className="data-amount justify-content-end"
                                                quantity={statement.dueBalance}
                                                noCurrency
                                                {...(!isDesktop && {
                                                    bold: true,
                                                    size: "5",
                                                    color: "text",
                                                })}
                                            />
                                        </GridTable.Data>
                                        <GridTable.Data
                                            columnStart={isDesktop ? 10 : 4}
                                            alignX="center"
                                            {...(!isDesktop && { rowStart: 1, rowWidth: 2 })}>
                                            <Box
                                                display="flex"
                                                alignY="center"
                                                alignX="center"
                                                fullWidth
                                                fullHeight
                                                className="checkbox-table">
                                                <Checkbox
                                                    key={`fee_${statement.idFee}`}
                                                    name={`fee_${statement.idFee}`}
                                                    className="mb-0 c-control-block"
                                                    gap="0"
                                                    formGroup
                                                    noLabel
                                                    checked={checkedFees.includes(statement.idFee)}
                                                    onChange={() => onFeeClicked(statement.idFee)}
                                                />
                                            </Box>
                                        </GridTable.Data>
                                    </GridTable.Container>
                                ))}

                                {(unpaidStatements || unpaidStatements?.length === 0) && (
                                    <Box
                                        display="flex"
                                        alignX="flex-end"
                                        className="pl-5 pr-7"
                                        fullWidth
                                        {...(isMobile && { column: true })}>
                                        {!isMobile && values?.otherAmountOption?.[0] === "otherAmount" && (
                                            <Disclaimer
                                                className="p-3 px-5 p-md-5 mt-5 mt-md-0 mr-md-auto"
                                                styled=""
                                                labelKey="forms.loan.payment.otherAmount.disclaimer"
                                            />
                                        )}
                                        <Box
                                            display="flex"
                                            alignX="flex-end"
                                            alignY="center"
                                            className="py-5"
                                            {...(isMobile && { fullWidth: true })}>
                                            <Box display="flex" className="checkbox-wrapper-simple mr-3">
                                                <Field
                                                    {...genericProps}
                                                    component={FormFieldsComponents.Selector}
                                                    key="otherAmountOption"
                                                    name="otherAmountOption"
                                                    idField="otherAmountOption"
                                                    optionList={[
                                                        {
                                                            id: "otherAmount",
                                                            label: i18n.get("forms.loan.payment.otherAmount.label"),
                                                        },
                                                    ]}
                                                    renderAs="radio"
                                                    onChange={() => onOtherAmountOptionClick(setFieldValue)}
                                                    renderLabel={false}
                                                    noMarginBottom
                                                />
                                            </Box>

                                            <Box display="flex" {...(isDesktop && { className: "max-width-105rem" })}>
                                                <Field
                                                    {...genericProps}
                                                    id="otherAmount"
                                                    component={FormFieldsComponents.Amount}
                                                    data={dataAmount}
                                                    hideCurrency
                                                    key="otherAmount"
                                                    name="otherAmount"
                                                    value={{ currency: "USD", quantity: "" }}
                                                    idField="otherAmount"
                                                    customPlaceholderQuantity="0.00"
                                                    quantityTextAlign="text-left"
                                                    onChange={onOtherAmountChange}
                                                    renderLabel={false}
                                                    disabled={values?.otherAmountOption?.[0] !== "otherAmount"}
                                                />
                                            </Box>
                                        </Box>
                                        {isMobile && values?.otherAmountOption?.[0] === "otherAmount" && (
                                            <Box className="mx-n-5">
                                                <Disclaimer labelKey="forms.loan.payment.otherAmount.disclaimer" />
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </>
                        </GridTable.Body>
                    </GridTable>
                </Container>
            </>
        );

        return (
            <>
                {mode === "edit" && (
                    <>
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Hidden}
                            key="paymentType"
                            name="paymentType"
                            idField="paymentType"
                            // eslint-disable-next-line no-nested-ternary
                            value={values?.paymentType || (feePayment ? "fee" : amountPayment ? "amount" : "fee")}
                        />

                        <Row gapY={isDesktop ? "5" : "3"} className="mt-5 mt-md-0">
                            <Col xs={12} md={6}>
                                <Box
                                    background="white"
                                    className="px-5 px-md-10 pt-5 pt-md-7 pb-8 pb-md-10 mx-n-5"
                                    borderRadius="default">
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.ProductselectorCustom}
                                        data={debitAccountListOptions}
                                        key="debitAccount"
                                        name="debitAccount"
                                        renderAs="combo"
                                        value={values?.debitAccount}
                                        idField="debitAccount"
                                        loadBalance
                                        labelNoMarginTop
                                    />
                                </Box>
                            </Col>

                            <Col xs={12} md={6}>
                                <Box
                                    background="white"
                                    className="px-5 px-md-10 pt-5 pt-md-7 pb-8 pb-md-10 mx-n-5"
                                    borderRadius="default">
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.ProductselectorCustom}
                                        data={loanListOptions}
                                        key="loanPayment"
                                        name="loanPayment"
                                        renderAs="combo"
                                        value={values?.loanPayment || loanOrigin}
                                        idField="loanPayment"
                                        onChange={(value) => onLoanPaymentChange(value)}
                                        labelNoMarginTop
                                    />
                                </Box>
                            </Col>

                            <Col xs={12} md={12}>
                                <Box
                                    background="white"
                                    className={classNames("pt-5 mx-n-5", {
                                        "min-height-10rem": !isDesktop,
                                        "min-height-14rem": isDesktop,
                                    })}
                                    borderRadius="default"
                                    overflow="hidden"
                                    position="relative">
                                    <PageLoading zIndex="9" loading={loadingFees}>
                                        {renderPaymentTypes()}

                                        {values?.paymentType === "fee" && renderTable()}
                                        {values?.paymentType === "amount" && renderAmount()}
                                    </PageLoading>
                                    {!isDesktop &&
                                        values?.paymentType === "fee" &&
                                        unpaidStatements &&
                                        unpaidStatements.length > 0 &&
                                        (values.otherAmountOption == null ||
                                            (!isDesktop && values.otherAmountOption[0] !== "otherAmount")) && (
                                            <Box className="px-5">{renderPaymentAmount()}</Box>
                                        )}
                                </Box>

                                {isDesktop &&
                                    values?.paymentType === "fee" &&
                                    unpaidStatements &&
                                    unpaidStatements.length > 0 &&
                                    (values.otherAmountOption == null ||
                                        (isDesktop && values.otherAmountOption[0] !== "otherAmount")) &&
                                    renderPaymentAmount()}
                            </Col>
                        </Row>
                    </>
                )}
                {mode === "preview" || mode === "view" ? (
                    <>
                        <Field
                            {...genericProps}
                            component={FormFieldsComponents.Hidden}
                            key="paymentType"
                            name="paymentType"
                            idField="paymentType"
                            // eslint-disable-next-line no-nested-ternary
                            value={values?.paymentType || (feePayment ? "fee" : amountPayment ? "amount" : "fee")}
                        />

                        <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                data={getDataAmount(values?.amount)}
                                key="amount"
                                name="amount"
                                value={values?.amount}
                                idField="amount"
                                bigDataAmount
                                label_viewMap={
                                    transaction?.idTransactionStatus === "FINISHED"
                                        ? { [currentLang]: i18n.get(`forms.${ID_FORM}.amount.label_view`) }
                                        : { [currentLang]: i18n.get(`forms.${ID_FORM}.amount.label`) }
                                }
                            />
                        </Box>

                        {TooltipProcessingComponent}

                        <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                            {values.backendReference && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={values?.backendReference}
                                    label={`forms.${ID_FORM}.voucher.label`}
                                    shouldRender={mode === "view" && values?.backendReference}
                                />
                            )}

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={
                                    values?.scheduler?.valueDate?.format("DD/MM/YYYY") ||
                                    (transaction?.valueDateTime
                                        ? moment(transaction?.valueDateTime).format("DD/MM/YYYY")
                                        : moment().format("DD/MM/YYYY"))
                                }
                                label={`forms.${ID_FORM}.date.label`}
                            />

                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.ProductselectorCustom}
                                data={mode === "preview" ? debitAccountListOptions : { options: [] }}
                                key="debitAccount"
                                name="debitAccount"
                                renderAs="combo"
                                value={values?.debitAccountData?.otherLabel}
                                idField="debitAccount"
                            />

                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Hidden}
                                key="loanPayment"
                                name="loanPayment"
                                idField="loanPayment"
                                value={values?.loanPayment}
                            />

                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Text}
                                key="loanType"
                                name="loanType"
                                idField="loanType"
                                value={values?._loanType || values?.loanType || getLoanType(values?.loanPayment)}
                            />

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.loanPaymentData?.number}
                                label={`forms.${ID_FORM}.loanNumber`}
                            />

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={i18n.get(`forms.${ID_FORM}.paymentType.${values?.paymentType}`)}
                                label={`forms.${ID_FORM}.paymentType.label`}
                            />
                        </Box>
                    </>
                ) : null}
            </>
        );
    };

    const formProps = {
        title: "forms.loan.payment.formName",
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: false,
            programable: false,
            idActivity: ID_ACTIVITY,
        },

        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: preData || {},
        isCustom: true,
        idActivity: ID_ACTIVITY,
        showFilterChips: false,
        submitDisabled,
        cancelAction,
        submitButtonLabel: "forms.loan.payment.pay",
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    statements: loanSelectors.getStatements(state),
    feePayment: loanSelectors.getFeePayment(state),
    amountPayment: loanSelectors.getAmountPayment(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
});

LoanPaymentForm.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    fromTransaction: bool,
    statements: arrayOf(shape({})),
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
    feePayment: bool,
    amountPayment: bool,
    usesJointAccount: bool,
};

LoanPaymentForm.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: null,
    previewData: null,
    transaction: null,
    location: {},
    fromTransaction: false,
    statements: null,
    feePayment: null,
    amountPayment: null,
    usesJointAccount: false,
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(LoanPaymentForm));
