import classNames from "classnames";
import { Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import Text from "pages/_components/Text";
import { bool, func, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { replace } from "react-router-redux";
import { compose } from "redux";
import { selectors as loginSelectors } from "reducers/login";

class RemediationUserStep2 extends Component {
    goDesktop = () => {
        const { dispatch } = this.props;
        dispatch(replace("/loginStep1"));
    };

    render() {
        const { className, isDesktop, userFirstName } = this.props;

        return (
            <Form className={`${className} d-flex mt-3 justify-content-between align-items-center flex-column`}>
                <Box className="mb-5" display="flex" column alignX="center" fullWidth>
                    <Text
                        align="center"
                        component="h1"
                        size="1"
                        color="heading"
                        bold
                        labelKey="remediation.step2.title"
                        className={classNames("my-0", "mt-0 mb-0")}
                        textParams={{
                            NAME: userFirstName,
                        }}
                    />
                </Box>
                <Box display="flex" fullWidth>
                    <Image src="images/remediateUserApprove.svg" className="svg-image logo mt-10 mb-5" />
                </Box>
                <Box className="mb-9 mb-md-10 mt-auto" column display="flex" alignX="center">
                    <Text
                        component="p"
                        color="text"
                        size={isDesktop ? "6" : "5"}
                        align="center"
                        labelKey="remediation.step2.subtitle1"
                    />
                    <Text
                        component="p"
                        color="text"
                        size={isDesktop ? "6" : "5"}
                        align="center"
                        labelKey="remediation.step2.subtitle2"
                    />
                </Box>
                <Box fullWidth>
                    <Button bsStyle="primary" label="global.accept" block onClick={this.goDesktop} />
                </Box>
            </Form>
        );
    }
}

RemediationUserStep2.propTypes = {
    className: string,
    isDesktop: bool.isRequired,
    dispatch: func.isRequired,
    userFirstName: string,
};

RemediationUserStep2.defaultProps = {
    className: null,
    userFirstName: "",
};

const mapStateToProps = (state) => ({
    userFirstName: loginSelectors.getUserFirstName(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({}),
    }),
)(RemediationUserStep2);
