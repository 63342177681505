import { call, put, takeLatest } from "redux-saga/effects";
import * as debitCards from "middleware/debitCards";
import * as i18n from "util/i18n";
import { types, actions, actions as debitCardsActions } from "reducers/debitCards";
import { adjustIdFieldErrors } from "util/form.js";

import { actions as notificationActions } from "reducers/notification";
import { actions as changeStatusProductActions } from "reducers/changeStatusProduct";

const sagas = [
    takeLatest(types.LIST_REQUEST, listRequest),
    takeLatest(types.LIST_REQUEST_DESKTOP, listRequestDesktop),
    takeLatest(types.REQUEST_PIN_REQUEST, requestPinRequest),
    takeLatest(types.ACTIVATE_NEW_DEBIT_CARD, activateNewDebitCard),
];

export default sagas;

function* listRequest(params) {
    const response = yield call(debitCards.listRequest);
    if (response.type === "W") {
        yield put(actions.listFailure());
        yield put(notificationActions.showNotification(i18n.get("global.unexpectedError"), "error", ["debitcards"]));
    } else {
        const { debitCards: responseCards, hasPendingRenewal, cardsExpired, cardsToExpire } = response.data.data;
        yield put(actions.listSuccess(responseCards, hasPendingRenewal));
        if (params?.callBackResult) {
            params.callBackResult({ responseCards, hasPendingRenewal, cardsExpired, cardsToExpire });
        }
    }
}

function* listRequestDesktop(params) {
    try {
        yield call(listRequest, params);
    } catch (error) {
        yield put(actions.listFailure());
    }
}

function* requestPinRequest({ data }) {
    const { parametersSubmit, credentialsForm, formikBag } = data;
    try {
        const response = yield call(debitCards.requestPinRequest, parametersSubmit.cardNumber, credentialsForm);
        if (response?.status === 200) {
            if (response.data.code === "COR020W") {
                if (response.data.data.cardNumber) {
                    yield put(changeStatusProductActions.modalHide());
                    yield put(
                        notificationActions.showNotification(response.data.data.cardNumber, "error", ["debitcards"]),
                    );
                } else {
                    formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                }
            } else if (response.data.code === "COR000I") {
                yield put(changeStatusProductActions.modalHide());
                yield put(
                    notificationActions.showNotification(
                        i18n.get("debitCards.snackbar.requestPin.messageOk"),
                        "success",
                        ["debitcards"],
                    ),
                );
            } else {
                yield put(changeStatusProductActions.modalHide());
                yield put(
                    notificationActions.showNotification(
                        i18n.get("debitCards.snackbar.requestPin.messageError"),
                        "error",
                        ["debitcards"],
                    ),
                );
            }
        } else {
            yield put(changeStatusProductActions.modalHide());
            yield put(notificationActions.showNotification(response.data.message, "error", ["debitcards"]));
        }
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
    } catch (error) {
        yield put(changeStatusProductActions.modalHide());
        yield put(
            notificationActions.showNotification(i18n.get("debitCards.snackbar.requestPin.messageError"), "error", [
                "debitcards",
            ]),
        );
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
    }
}

function* activateNewDebitCard({ data }) {
    const { parametersSubmit, credentialsForm, formikBag } = data;
    try {
        const response = yield call(
            debitCards.activateNewDebitCard,
            parametersSubmit.debitCard.cardNumber,
            credentialsForm,
        );
        if (response?.status === 200) {
            if (response.data.code === "COR020W") {
                if (response.data.data.cardNumber) {
                    yield put(changeStatusProductActions.modalHide());
                    yield put(
                        notificationActions.showNotification(response.data.data.cardNumber, "error", ["debitcards"]),
                    );
                } else {
                    formikBag.setErrors(adjustIdFieldErrors(response.data.data));
                }
            } else if (response.data.code === "COR000I") {
                yield put(changeStatusProductActions.modalHide());
                yield put(actions.listRequest());
                yield put(
                    notificationActions.showNotification(i18n.get("debitCards.snackbar.active.messageOk"), "success", [
                        "debitcards",
                    ]),
                );
            } else {
                yield put(changeStatusProductActions.modalHide());
                yield put(
                    notificationActions.showNotification(i18n.get("debitCards.snackbar.active.messageError"), "error", [
                        "debitcards",
                    ]),
                );
            }
        } else {
            yield put(changeStatusProductActions.modalHide());
            yield put(notificationActions.showNotification(response.data.message, "error", ["debitcards"]));
        }
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
    } catch (error) {
        yield put(changeStatusProductActions.modalHide());
        yield put(
            notificationActions.showNotification(i18n.get("debitCards.snackbar.active.messageError"), "error", [
                "debitcards",
            ]),
        );
        if (formikBag) {
            formikBag.setSubmitting(false);
        }
    }
}
