export const types = {
    UPDATE_REQUEST: "config/UPDATE_REQUEST",
    UPDATE_SUCCESS: "config/UPDATE_SUCCESS",
    UPDATE_FAILURE: "config/UPDATE_FAILURE",
    RESET_SAGAS_UPDATE: "config/RESET_SAGAS_UPDATE",
};

export const INITIAL_STATE = {
    fetching: true,
    items: {},
    timesFailed: 0,

    lastResponseCode: undefined,
    lastResponseMessage: undefined,
};

export default (state = INITIAL_STATE, action = {}) => {
    switch (action.type) {
        case types.UPDATE_REQUEST:
            return { ...state, fetching: true };

        case types.UPDATE_SUCCESS:
            return { ...state, fetching: false, items: action.items, timesFailed: 0 };

        case types.UPDATE_FAILURE:
            return {
                ...state,
                fetching: false,
                timesFailed: state.timesFailed + 1,
                lastResponseCode: action?.data?.lastResponseCode,
                lastResponseMessage: action?.data?.lastResponseMessage,
            };

        default:
            return state;
    }
};

export const actions = {
    load: () => ({
        type: types.LOAD,
    }),
};

export const selectors = {
    getConfig: (state) => state.config.items,
    getFetching: (state) => state.config.fetching,
    getTimesFailed: (state) => state.config.timesFailed,
    getLastResponseCode: (state) => state?.config?.lastResponseCode,
    getLastResponseMessage: (state) => state?.config?.lastResponseMessage,
};
