/* eslint-disable jsx-a11y/interactive-supports-focus */
import classNames from "classnames";
import { CORPORATE_ENVIRONMENT_TYPE, CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import A11yNav from "pages/_components/A11yNav";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import DraggableList from "pages/_components/DraggableList";
import Dropdown from "pages/_components/Dropdown";
import EntrustDisclaimerComponent from "pages/_components/EntrustDisclaimer/EntrustDisclaimerComponent";
import FastRechargeCarousel from "pages/_components/FastRechargeCarousel";
import FooterDesktop from "pages/_components/FooterDesktop";
import GeneralMsg from "pages/_components/GeneralMsg";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import MainContainer from "pages/_components/MainContainer";
import Notification from "pages/_components/Notification";
import PreApprovedProduct from "pages/_components/PreApprovedProduct";
import PreApprovedScrollBar from "pages/_components/PreApprovedScrollBar";
import Row from "pages/_components/Row";
import ScheduledTransactionsMigrationDisclaimer from "pages/_components/ScheduledTransactionsMigrationDisclaimer/ScheduledTransactionsMigrationDisclaimer";
import ShortCuts from "pages/_components/ShortCuts";
import Text from "pages/_components/Text";
import Header from "pages/_components/header/Header";
import TextAndButtonsModal from "pages/_components/modal/TextAndButtonsModal";
import ConfirmDialog from "pages/_components/modal/ConfirmDialog";
import Campaigns from "pages/campaigns/Campaigns";
import * as Widgets from "pages/desktop/widgets";
import PreApprovedProductModal from "pages/forms/_components/PreApprovedProductModal/PreApprovedProductModal";
import BiometricModal from "pages/login/_components/BiometricModal";
import fieldARP from "pages/servicePayments/hooks/field";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push, routerActions } from "react-router-redux";
import { actions, selectors as desktopSelectors } from "reducers/desktop";
import { selectors as loginSelectors } from "reducers/login";
import { actions as notificationActions } from "reducers/notification";
import { actions as payServiceActions, selectors as payServiceSelectors } from "reducers/payService";
import {
    actions as preApprovedProductActions,
    selectors as preApprovedProductSelectors,
} from "reducers/preApprovedProduct";
import {
    actions as preApprovedCreditPosActions,
    selectors as preApprovedCreditPosSelectors,
} from "reducers/preApprovedCreditPos";
import { actions as productActions, selectors as productSelectors } from "reducers/products";
import { actions as productRequestActions } from "reducers/productRequest";
import { actions as transactionalProfileActions } from "reducers/transactionalProfile";
import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { actions as updateCompanyDataActions } from "reducers/updateCompanyData/updateCompanyData.reducer";
import { actions as widgetActions, selectors as widgetSelectors } from "reducers/widgets";
import { get as getConfig, getBoolean } from "util/config";
import * as deviceUtils from "util/device";
import * as i18n from "util/i18n";
import * as stringUtils from "util/string";
import NextPayments from "pages/_components/NextPayments";
import BannerAppleWalletContainer from "pages/wallet/banner/BannerAppleWalletContainer";
import { actions as updateUserDataActions } from "reducers/updateUserData/updateUserData.reducer";
import { selectors as creditLinesSelectors } from "reducers/creditLines";
import SideBarModal from "pages/_components/modal/SideBarModal";
import TokeStatusQR from "pages/settings/TokenStatusQR";
import { loansIsNotAvailable } from "pages/loans/hooks/loandsHook";
import { actions as debitCardsActions, selectors as debitCardsSelectors } from "reducers/debitCards";
import { ENABLED_CONFIG_MONITOR_DBF } from "util/monitor/monitorDbf.utils";
import PreApprovedProductCreditPOSModal from "pages/forms/_components/PreApprovedProductCreditPOSModal/PreApprovedProductCreditPOSModal";
import EconomicGroups from "./widgets/economicGroup/EconomicGroups";

export const ENABLED_RENEW_CARD = "renew.debit.card.enabled.functionality";

export const preApprovedProductsStatus = {
    PENDING: "PENDIENTE",
    SEEN: "LEIDO",
    ACCEPTED: "ACEPTADO",
    REJECTED: "RECHAZADO",
};

// eslint-disable-next-line max-len
class Desktop extends Component {
    mySectionRef = React.createRef();

    SHOW_REQUEST_PRODUCTS = "showRequestProducts";

    SHOW_TRANSACTIONAL_PROFILE = "showTransactionalProfile";

    SHOW_DATA_UPDATE = "showDataUpdate";

    static propTypes = {
        dispatch: func.isRequired,
        layout: arrayOf(
            shape({
                column: number,
                id: string,
                row: number,
                uri: string,
            }),
        ).isRequired,
        isDesktop: bool,
        isTablet: bool,
        availableWidgets: arrayOf(
            shape({
                column: number,
                id: string,
                row: number,
                uri: string,
            }),
        ).isRequired,
        isEditable: bool,
        sessionFetching: bool,
        desktopFetching: bool,
        user: shape({
            userFullName: string,
            userFirstName: string,
        }),
        activeEnvironment: shape({ type: string }).isRequired,
        displayCampaigns: bool,
        urlFooterBanner: string,
        location: shape({}),
        isMobile: bool,
        showUpdateDataModal: bool,
        daysSinceLastUpdt: number,
        showUpdateCorporateDataModal: bool,
        yearsSinceLastUpdtCorporate: number,
        isAdministrator: bool,
        showAdulthoodModal: bool,
        match: shape({}),
        preApprovedProductsList: shape({}).isRequired,
        showModalPreApproved: bool,
        listPayService: arrayOf(shape({})),
        payServiceFetching: bool,
        listNextPayment: shape({}),
        showMonitorFraudModal: bool.isRequired,
        creditLinesList: arrayOf(shape({})),
        drawerTokenStatusType: string,
        showDrawerStatusToken: bool,
        showModalPreApprovedCreditPOS: bool,
    };

    static defaultProps = {
        isDesktop: false,
        isTablet: false,
        isEditable: null,
        sessionFetching: null,
        desktopFetching: null,
        displayCampaigns: null,
        user: null,
        urlFooterBanner: null,
        location: {},
        isMobile: false,
        showUpdateDataModal: false,
        daysSinceLastUpdt: 0,
        showUpdateCorporateDataModal: false,
        yearsSinceLastUpdtCorporate: 0,
        isAdministrator: false,
        showAdulthoodModal: false,
        match: undefined,
        showModalPreApproved: false,
        listPayService: [],
        payServiceFetching: false,
        listNextPayment: null,
        creditLinesList: [],
        drawerTokenStatusType: undefined,
        showDrawerStatusToken: false,
        showModalPreApprovedCreditPOS: false,
    };

    state = {
        showDeleteWidgetModal: false,
        widgetToDelete: null,
        nextPaymentsShowMore: false,
        showPreApprovedScrollBar: true,
        wasShownPreApproveds: false,
        preApprovedProductType: null,
        preApprovedProductStatus: null,
    };

    componentDidMount() {
        const {
            dispatch,
            layout,
            isMobile,
            isDesktop,
            preApprovedProductsList,
            activeEnvironment: { type },
        } = this.props;
        if (layout === null || layout === undefined || !layout) {
            dispatch(actions.loadLayoutRequest());
            if (isMobile) {
                dispatch(payServiceActions.listPayServiceRequest(true));
            }
        }
        this.showAlertTokenInvalid();

        if (!isDesktop) {
            this.mainElement = document.querySelector("main");
            if (this.mainElement) {
                this.mainElement.addEventListener("scroll", this.handleScroll);
            }
        }

        if (!preApprovedProductsList) {
            dispatch(preApprovedProductActions.getPreApprovedProductsList());
        }

        if (window.location.href.includes("desktop")) {
            this.showRedirectionModal(window.location.href, type);
        }

        const showRenewDebitCard = getConfig(ENABLED_RENEW_CARD, "0");
        if (showRenewDebitCard && showRenewDebitCard === "1") {
            dispatch(debitCardsActions.listRequestDesktop(this.callbackDebitCardResult));
        }
    }

    componentDidUpdate(prevProps) {
        const { dispatch, availableWidgets } = this.props;
        if (availableWidgets !== prevProps.availableWidgets) {
            const newPaymentAvailableId = availableWidgets
                .filter((item) => item.id === "creditCards" || item.id === "loans")
                .map((item) => item.id);
            // eslint-disable-next-line no-restricted-syntax
            for (const widget of newPaymentAvailableId) {
                dispatch(widgetActions.listWidgetAvailableNextPaymentRequest(widget));
            }
        }
    }

    componentWillUnmount() {
        const { isDesktop, dispatch } = this.props;
        dispatch(actions.hideMonitorFraudModal());
        if (!isDesktop && this.mainElement) {
            this.mainElement.removeEventListener("scroll", this.handleScroll);
        }
    }

    callbackDebitCardResult = (result) => {
        try {
            const { dispatch } = this.props;
            const { hasPendingRenewal, cardsExpired, cardsToExpire } = result;
            if (hasPendingRenewal) {
                return;
            }
            if (cardsExpired > 1 && cardsToExpire > 1) {
                const message = "debitCards.renew.desktop.expiredAndToExpire.disclaimer";
                dispatch(notificationActions.showNotification(i18n.get(message), "warning", ["desktop"], false));
            } else if (cardsExpired > 0) {
                const message =
                    cardsExpired === 1
                        ? "debitCards.renew.desktop.expired.single.disclaimer"
                        : "debitCards.renew.desktop.expired.multiple.disclaimer";
                dispatch(notificationActions.showNotification(i18n.get(message), "warning", ["desktop"], false));
            } else if (cardsToExpire > 0) {
                const message =
                    cardsToExpire === 1
                        ? "debitCards.renew.desktop.toExpire.single.disclaimer"
                        : "debitCards.renew.desktop.toExpire.multiple.disclaimer";
                dispatch(notificationActions.showNotification(i18n.get(message), "warning", ["desktop"], false));
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    };

    handleScroll = () => {
        const { wasShownPreApproveds } = this.state;
        const section = document.getElementById("preApprovedSection");

        if (!wasShownPreApproveds && section && this.isElementVisible(section)) {
            this.setState({ showPreApprovedScrollBar: false });
            this.setState({ wasShownPreApproveds: true });
        }
        if (!wasShownPreApproveds && section && !this.isElementVisible(section)) {
            this.setState({ showPreApprovedScrollBar: true });
        }
    };

    showAlertTokenInvalid = () => {
        const { dispatch, match } = this.props;
        if (!deviceUtils.isMobileNativeFunc() || !match?.params?.id) {
            return;
        }

        const showAlertTokenInvalid = match.params.id;
        if (showAlertTokenInvalid === 1 || showAlertTokenInvalid === "1") {
            dispatch(
                notificationActions.showNotification(i18n.get("transaction.invalid.otp.required.mobile"), "error", [
                    "desktop",
                ]),
            );
        }
    };

    hideDeleteWidgetModal = () => {
        this.setState({ showDeleteWidgetModal: false, widgetToDelete: null });
    };

    handleCloseUpdateModal = () => {
        const { dispatch } = this.props;
        dispatch(actions.updateModalHide());
    };

    displayUpdateCompanyDataStepZero = () => {
        const { dispatch } = this.props;
        dispatch(actions.updateCorporateModalHide());
        dispatch(updateCompanyDataActions.showUpdateCompanyDataStepZero());
    };

    handleCloseUpdateCorporteModal = () => {
        const { dispatch } = this.props;
        dispatch(actions.updateCorporateModalHide());
    };

    showDeleteWidgetModal = (item) => {
        this.setState({ showDeleteWidgetModal: true, widgetToDelete: item });
    };

    getColumns = (layout) =>
        layout?.reduce((obj, item) => {
            const columnValue = obj[item.column] || [];

            return { ...obj, [item.column]: [...columnValue, item] };
        }, {});

    handleClick = () => {
        const { widgetToDelete } = this.state;
        const { dispatch, layout } = this.props;
        const { column, row } = widgetToDelete;

        dispatch(actions.deleteWidget(layout?.findIndex((widget) => widget.column === column && widget.row === row)));
        this.setState({ showDeleteWidgetModal: false, widgetToDelete: null });
    };

    handleIsEditableChange = () => {
        const { dispatch } = this.props;
        dispatch(actions.toggleIsEditable());
    };

    handleItemsPositionChange = (items) => {
        const { dispatch } = this.props;

        dispatch(actions.setLayout(items));
        dispatch(actions.saveLayoutRequest());
    };

    handleLinkFastRecharge = (paymentUser) => {
        const { dispatch } = this.props;
        const newPaymentUser = { ...paymentUser, fastRecharge: true };
        dispatch(payServiceActions.isLinkFastRecharge(true));

        const query = {
            paymentUser: newPaymentUser,
            biller: paymentUser?.extraData,
            savePayment: false,
            isFastRecharge: true,
        };
        dispatch(payServiceActions.setQueryBillPre(query));
        dispatch(payServiceActions.setSelectedBiller(paymentUser?.extraData));

        // Telered -> consulta de reglas
        if (paymentUser?.extraData?.svcProviderId === "Telered") {
            dispatch(payServiceActions.listRulesBillerRequest(paymentUser?.idBiller));
        } else {
            // ARP -> definicion de reglas por defecto
            dispatch(payServiceActions.listRulesBillerSuccess(fieldARP));
        }
        dispatch(routerActions.push("/formCustom/servicePayment"));
    };

    renderHeader = () => {
        const {
            isDesktop,
            dispatch,
            availableWidgets,
            isEditable,
            activeEnvironment: { type },
            user,
            creditLinesList,
        } = this.props;

        const availableWidgetsToShow = availableWidgets.filter(
            (widget) => widget.id !== "creditLines" || (widget.id === "creditLines" && creditLinesList.length > 0),
        );

        const hasAvailableWidgets = availableWidgetsToShow.length > 0;
        const title = "menu.desktop";
        const { userFirstName } = user;

        if (!isDesktop) {
            return (
                <Head contentLeft dashboard title={title} userFullName={userFirstName} userFirstName={userFirstName} />
            );
        }

        return (
            <>
                <Header>
                    <Text
                        className="toolbar-item view-title"
                        component="h1"
                        labelKey="desktop.welcome"
                        username={userFirstName}
                    />

                    {type !== CORPORATE_GROUP_ENVIRONMENT_TYPE && (
                        <div className="toolbar-item desktop-edit toolbar-item--fixed">
                            {!isEditable ? (
                                <Button
                                    label="desktop.editLayout.edit.label"
                                    onClick={() => this.handleIsEditableChange()}
                                    bsStyle="link"
                                    id="editDashboardBtn"
                                />
                            ) : (
                                <>
                                    {hasAvailableWidgets ? (
                                        <Dropdown
                                            enabled={isEditable}
                                            label="desktop.selectWidget"
                                            buttonClass="btn-select">
                                            {availableWidgetsToShow.map((widget, index) => (
                                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                                <Button
                                                    key={widget.id}
                                                    onClick={() => dispatch(actions.addWidget(index))}
                                                    label={`list.addWidget.${widget.id}`}
                                                    className="dropdown__item-btn"
                                                    bsStyle="link"
                                                />
                                            ))}
                                        </Dropdown>
                                    ) : (
                                        <I18n
                                            id="desktop.widgets.empty"
                                            componentProps={{ className: "desktop-edit-empty-message" }}
                                        />
                                    )}
                                    <Button
                                        bsStyle="only-icon"
                                        image="images/icons/circleCross.svg"
                                        className="widget-edit-close"
                                        label="desktop.editLayout.finish.label"
                                        defaultLabelText="Terminar edición"
                                        onClick={() => this.handleIsEditableChange()}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </Header>
            </>
        );
    };

    renderItem = (item, { draggableItemProps }) => {
        const { isEditable, isDesktop } = this.props;

        const Widget = Widgets[stringUtils.capitalizeFirstLetter(item.id)];
        const buttonActionDesc = `${i18n.get("global.close")} ${i18n.get("global.widget")}, ${i18n.get(
            `list.addWidget.${item.id}`,
        )}`;
        return (
            <>
                <Widget
                    className={classNames({ "draggable-list__item": isEditable })}
                    closeButton={
                        isEditable && (
                            <Button
                                className="btn-link btn-only-icon widget-close-icon"
                                onClick={() => this.showDeleteWidgetModal(item)}>
                                {/* onClick={() => this.handleClick(item)}> */}
                                <Image src="images/icons/circleCross.svg" />
                                <span className="visually-hidden">{buttonActionDesc}</span>
                            </Button>
                        )
                    }
                    draggableItemProps={draggableItemProps}
                    isEditable={isEditable}
                    isDesktop={isDesktop}
                />
            </>
        );
    };

    redirectTrasferShortCut = (transferUrl) => {
        const { dispatch } = this.props;
        dispatch(routerActions.push(transferUrl));
    };

    getTransferUrlShortCut = () => {
        const { activeEnvironment, dispatch } = this.props;
        if (activeEnvironment?.permissions?.transferInternal) {
            this.redirectTrasferShortCut("/formCustom/transferInternal");
            return;
        }
        if (activeEnvironment?.permissions?.transferLocal || activeEnvironment?.permissions?.transferThirdParties) {
            this.redirectTrasferShortCut("/formCustom/transferLocal");
            return;
        }
        if (activeEnvironment?.permissions?.transferForeign) {
            this.redirectTrasferShortCut("/formCustom/transferForeign");
            return;
        }

        dispatch(notificationActions.showNotification(i18n.get("returnCode.COR005E"), "error", ["desktop"]));
    };

    scrollToPreApproved = () => {
        const element = document.getElementById("preApprovedSection");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    handleClosePreApprovedModal = () => {
        const { dispatch } = this.props;
        dispatch(preApprovedProductActions.modalHide());
        dispatch(preApprovedProductActions.getPreApprovedProductsList());
    };

    handleClosePreApprovedCreditPOSModal = () => {
        const { dispatch } = this.props;
        dispatch(preApprovedCreditPosActions.modalHideCreditPOS());
        dispatch(preApprovedProductActions.getPreApprovedProductsList());
    };

    isElementVisible = (el) => {
        const rect = el?.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        return rect.top >= 0 && rect.bottom <= windowHeight;
    };

    changePreApprovedProductStatus = (productType, productStatusCode) => {
        const { dispatch } = this.props;
        dispatch(preApprovedProductActions.preApprovedProductChangeStatus(productType, productStatusCode));
    };

    showRedirectionModal = (redirection, type) => {
        const { dispatch } = this.props;

        if (redirection.includes(this.SHOW_REQUEST_PRODUCTS)) {
            dispatch(productRequestActions.modalShow());
        }

        if (redirection.includes(this.SHOW_TRANSACTIONAL_PROFILE)) {
            dispatch(transactionalProfileActions.transactionalCountriesSettingRequest());
            dispatch(transactionalProfileActions.modalShow({}, true));
        }

        if (redirection.includes(this.SHOW_DATA_UPDATE)) {
            if (type === CORPORATE_ENVIRONMENT_TYPE) {
                dispatch(updateCompanyDataActions.showUpdateCompanyDataStepZero());
            } else {
                dispatch(updateUserDataActions.showUpdateUserDataStepZero());
            }
        }
    };

    hasModalShow = () =>
        [this.SHOW_DATA_UPDATE, this.SHOW_REQUEST_PRODUCTS, this.SHOW_TRANSACTIONAL_PROFILE].some((item) =>
            window.location.href.includes(item),
        );

    showPreApprovedProductModal = (producType, productStatusCode) => {
        const { dispatch } = this.props;
        this.setState({ preApprovedProductType: producType });
        this.setState({ preApprovedProductStatus: productStatusCode });
        dispatch(preApprovedProductActions.modalShow());

        if (productStatusCode === preApprovedProductsStatus.PENDING) {
            this.changePreApprovedProductStatus(producType, preApprovedProductsStatus.SEEN);
        }
    };

    showPreApprovedProductModalCreditPos = (producType, productStatusCode, preApprovedCreditUsersId) => {
        const { dispatch } = this.props;
        this.setState({ preApprovedProductType: producType });
        this.setState({ preApprovedProductStatus: productStatusCode });
        dispatch(preApprovedCreditPosActions.modalShowCreditPOS());
        dispatch(preApprovedCreditPosActions.getApprovedCreditPosRequest(preApprovedCreditUsersId));
    };

    processNextPaymentList = (listNextPayment, dispatch) => {
        let listWithOnClick = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const map of listNextPayment) {
            // eslint-disable-next-line no-restricted-syntax
            for (const value of map.values()) {
                // eslint-disable-next-line no-restricted-syntax
                for (const item of value) {
                    if (item.nextPaymentDate != null) {
                        listWithOnClick.push({
                            ...item,
                            onClick: () => {
                                if (item.image === "creditCard") {
                                    dispatch(push(`/formCustom/payCreditCardLocal?creditCard=${item.idProduct}`));
                                }
                            },
                        });
                    }
                }
            }
        }

        const currentDate = new Date();
        const daysToBeValid = getConfig("widget.next.payment.daysToBeValid");

        listWithOnClick = listWithOnClick.filter((item) => {
            const nextPaymentDate = new Date(item.nextPaymentDate.replace(/-05$/, ""));
            const currentDatePlusDays = new Date(currentDate.getTime() + daysToBeValid * 24 * 60 * 60 * 1000);
            let statusValid = true;
            if (item.image !== "creditCard" && loansIsNotAvailable(item.overdueStatus)) {
                statusValid = false;
            }

            return (
                statusValid &&
                nextPaymentDate < currentDatePlusDays &&
                nextPaymentDate > new Date(currentDate.getTime())
            );
        });

        if (listWithOnClick.length > 1) {
            listWithOnClick.sort((a, b) => {
                const dateA = new Date(a.nextPaymentDate.replace(/-05$/, ""));
                const dateB = new Date(b.nextPaymentDate.replace(/-05$/, ""));

                const differenceA = Math.abs(dateA - currentDate);
                const differenceB = Math.abs(dateB - currentDate);

                return differenceA - differenceB;
            });
        }

        return listWithOnClick;
    };

    getAccessToUpdateData = () => {
        const { dispatch } = this.props;
        dispatch(updateUserDataActions.validateClientRemediation());
    };

    renderModalUpdateDataUser = () => {
        const enabledMonitorDbf = getConfig(ENABLED_CONFIG_MONITOR_DBF, "0");
        if (!enabledMonitorDbf !== "1") {
            return <div />;
        }

        const { activeEnvironment, daysSinceLastUpdt, showUpdateDataModal, user } = this.props;
        const { userFirstName } = user;
        const headingTextMessageUpdateModal = i18n.get("widgets.updateModal.headingText", null, {
            CLIENT: userFirstName,
        });
        const infoMessageUpdateModal = i18n.get("widgets.updateModal.text", null, {
            DAYS: daysSinceLastUpdt,
        });
        const isAccessToUpdateDataEnabled = getBoolean("settings.userData.accessFromModal.enabled");
        return activeEnvironment?.type &&
            activeEnvironment.type !== CORPORATE_ENVIRONMENT_TYPE &&
            isAccessToUpdateDataEnabled ? (
            <TextAndButtonsModal
                modalShow={showUpdateDataModal}
                acceptFunction={this.getAccessToUpdateData}
                cancelFunction={this.handleCloseUpdateModal}
                headingText={headingTextMessageUpdateModal}
                text={infoMessageUpdateModal}
                confirmLabel="widgets.updateModal.confirmLabel"
                cancelLabel="widgets.updateModal.cancelLabel"
                modalId="widgets.update.data.modal"
            />
        ) : (
            <TextAndButtonsModal
                modalShow={showUpdateDataModal}
                externalHref={getConfig("desktop.link.to.update")}
                cancelFunction={this.handleCloseUpdateModal}
                headingText={headingTextMessageUpdateModal}
                text={infoMessageUpdateModal}
                confirmLabel="widgets.updateModal.confirmLabel"
                cancelLabel="widgets.updateModal.cancelLabel"
                modalId="widgets.update.data.modal"
            />
        );
    };

    renderContent = () => {
        const {
            isEditable,
            activeEnvironment: { type },
            sessionFetching,
            desktopFetching,
            layout,
            isDesktop,
            isTablet,
            displayCampaigns,
            location,
            isMobile,
            activeEnvironment,
            listPayService,
            listNextPayment,
            dispatch,
            creditLinesList,
        } = this.props;

        const layoutToShow = layout?.filter(
            (item) => item.id !== "creditLines" || (item.id === "creditLines" && creditLinesList.length > 0),
        );
        const {
            showDeleteWidgetModal,
            widgetToDelete,
            nextPaymentsShowMore,
            preApprovedShowMore,
            showPreApprovedScrollBar,
        } = this.state;
        const isStepZero = location.pathname === "/";

        const listAllNextPayment = this.processNextPaymentList(listNextPayment, dispatch);

        let cardPaymentHref;
        if (
            activeEnvironment.permissions.payCreditCard ||
            activeEnvironment.permissions.payCreditCardOther ||
            activeEnvironment.permissions.payCreditCardThird
        ) {
            if (activeEnvironment.permissions.payCreditCard) {
                cardPaymentHref = "/formCustom/payCreditCardLocal";
            } else if (activeEnvironment.permissions.payCreditCardThird) {
                cardPaymentHref = "/formCustom/payCreditCardThird";
            }
        } else {
            cardPaymentHref = "/formCustom/payCreditCardLocal";
        }

        const shortCutBtns = !isMobile
            ? [
                  {
                      image: { desktop: "images/shortCutBtn/SCBaccounts.svg" },
                      label: "desktop.widgets.short.cuts.accounts",
                      href: "/formCustom/transferInternal",
                  },
                  {
                      image: { desktop: "images/shortCutBtn/SCBthirdParties.svg" },
                      label: "desktop.widgets.short.cuts.transferThirdParties",
                      href: "/formCustom/transferLocal",
                  },
                  {
                      image: {
                          desktop: "images/shortCutBtn/SCBpayments.svg",
                          mobile: "images/shortCutBtn/SCBpaymentsMobile.svg",
                      },
                      label: "desktop.widgets.short.cuts.payments",
                      href: "/servicePayments",
                  },
                  {
                      image: {
                          desktop: "images/shortCutBtn/SCBcard.svg",
                      },
                      label: "landing.payment.access.pay.creditcard",
                      href: cardPaymentHref,
                  },
              ]
            : [
                  {
                      image: { mobile: "images/shortCutBtn/SCBthirdParties.svg" },
                      label: "login.step0.short.cut.transfer",
                      click: () => {
                          this.getTransferUrlShortCut();
                      },
                  },
                  {
                      image: {
                          desktop: "images/shortCutBtn/SCBpayments.svg",
                          mobile: "images/shortCutBtn/SCBpaymentsMobile.svg",
                      },
                      label: "login.step0.short.cut.payments",
                      href: "/servicePayments",
                  },
                  {
                      image: {
                          desktop: "images/shortCutBtn/SCBcard.svg",
                      },
                      label: "landing.payment.access.pay.creditcard",
                      href: cardPaymentHref,
                  },
                  {
                      image: { desktop: "images/icons/userEditMb.svg" },
                      label: "menu.userData.update",
                      click: () => {
                          this.getAccessToUpdateData();
                      },
                  },
              ];

        const textWidgetToDelete = widgetToDelete ? i18n.get(`widgets.hideModal.${widgetToDelete.id}`) : "";
        const infoMessage = i18n.get("widgets.hideModal", null, {
            WIDGET: textWidgetToDelete,
        });

        /* const infoMessageUpdateCorporateModal = i18n.get(
            `administration.updateModal.corporate.text${isMobile ? ".mobile" : ""}`,
            null,
            {
                YEARS: yearsSinceLastUpdtCorporate,
            },
        ); */

        const preApprovedProducts = this.setPreApprovedProducts();

        return (
            <>
                <MainContainer
                    className="desktop"
                    showLoader={sessionFetching || desktopFetching}
                    {...(!isDesktop && layoutToShow?.length > 0 && { background: "primary" })}>
                    {isDesktop && !isTablet && (
                        <Box position="relative">
                            <Notification scopeToShow="menu" />
                            <Notification scopeToShow="desktop" />
                        </Box>
                    )}
                    <Row
                        {...(!isDesktop && {
                            className: "display-flex flex-column justify-content-between",
                            gapY: "0",
                        })}
                        fullHeight>
                        <Col xs={12} className="desktop-main">
                            {isDesktop && this.renderHeader()}
                            {isDesktop && <EntrustDisclaimerComponent />}
                            {type !== CORPORATE_ENVIRONMENT_TYPE && isDesktop && (
                                <ScheduledTransactionsMigrationDisclaimer />
                            )}
                            {type === CORPORATE_GROUP_ENVIRONMENT_TYPE && <EconomicGroups />}
                            {!isDesktop && (
                                <>
                                    <ShortCuts dashboard options={shortCutBtns} inverse isStepZero={isStepZero} />
                                    <FastRechargeCarousel
                                        sliderList={listPayService}
                                        onClick={(service) => this.handleLinkFastRecharge(service)}
                                    />
                                </>
                            )}
                            {!isDesktop && (
                                <div className={classNames("mx-n-5 px-5 px-md-0", "background-white")}>
                                    <EntrustDisclaimerComponent
                                        redirectSuccess="/desktop"
                                        redirectError="/desktop"
                                        scopeSuccess={["desktop"]}
                                        scopeError={["desktop"]}
                                        redirectAbort="/desktop"
                                    />
                                    {type !== CORPORATE_ENVIRONMENT_TYPE && (
                                        <ScheduledTransactionsMigrationDisclaimer
                                            redirectSuccess="/desktop"
                                            redirectError="/desktop"
                                            scopeSuccess={["desktop"]}
                                            scopeError={["desktop"]}
                                        />
                                    )}
                                </div>
                            )}
                            {layoutToShow?.length > 0 && (
                                <>
                                    <DraggableList
                                        columns={this.getColumns(layoutToShow)}
                                        isDragEnabled={isEditable}
                                        itemRenderer={this.renderItem}
                                        onItemsPositionChange={this.handleItemsPositionChange}
                                    />
                                </>
                            )}
                            {widgetToDelete && showDeleteWidgetModal ? (
                                <TextAndButtonsModal
                                    modalShow={showDeleteWidgetModal}
                                    acceptFunction={this.handleClick}
                                    cancelFunction={this.hideDeleteWidgetModal}
                                    headingText={i18n.get("widgets.hideModal.modal.headingText")}
                                    text={infoMessage}
                                />
                            ) : (
                                <></>
                            )}
                            {this.renderModalUpdateDataUser()}
                            {/* type === CORPORATE_ENVIRONMENT_TYPE &&
                            (permissions.settingsCorporateDataUpdate || isAdministrator) &&
                            showUpdateCorporateDataModal ? (
                                <TextAndButtonsModal
                                    modalShow
                                    acceptFunction={
                                        isMobile
                                            ? this.handleCloseUpdateCorporteModal
                                            : this.displayUpdateCompanyDataStepZero
                                    }
                                    {...(!isMobile && { cancelFunction: this.handleCloseUpdateCorporteModal })}
                                    headingText={headingTextMessageUpdateModal}
                                    text={infoMessageUpdateCorporateModal}
                                    confirmLabel={
                                        isMobile ? "showAcceptOptionText" : "widgets.updateModal.confirmLabel"
                                    }
                                    cancelLabel="widgets.updateModal.cancelLabel"
                                    modalId="widgets.update.data.modal"
                                />
                            ) : (
                                <></>
                            ) */}
                            {/*
                                        <Info1
                                            label="desktop.widgets.product.request.label"
                                            btnLabel="desktop.widgets.product.request.btn"
                                            href="/settings/pointsOfInterest"
                                        />
                                     */}
                            {!isMobile && (
                                <A11yNav
                                    position="relative"
                                    // TODO (LABEL a11y)
                                    ariaLabel="Atajo  en Sección Principal"
                                    buttonList={[
                                        { label: "Ir a Menu Principal", focusOnSection: "menu" },
                                        {
                                            label: "Volver al inicio",
                                            focusOnSection: "main",
                                        },
                                    ]}
                                />
                            )}
                        </Col>
                        {layoutToShow?.length === 0 && type !== CORPORATE_GROUP_ENVIRONMENT_TYPE && (
                            <Col
                                xs={12}
                                {...(!isDesktop && { className: "display-flex full-height justify-content-center" })}>
                                <Box display="flex" alignY="center" alignX="center" className="no-widget-box">
                                    <GeneralMsg
                                        title={<Text size="5" color="text" labelKey="widgets.list.empty.title" />}
                                        imagePath="images/icons/noWidgets.svg"
                                        descriptionNode={
                                            isDesktop ? (
                                                <>
                                                    <Text size="6" labelKey="widgets.list.empty.description" />

                                                    <Button
                                                        onClick={() => this.handleIsEditableChange()}
                                                        bsStyle="link"
                                                        className="px-2 py-0 remove-min-height "
                                                        id="editDashboardBtnSecondary"
                                                        secondary>
                                                        <Text
                                                            size="5"
                                                            bold
                                                            color="heading"
                                                            labelKey="desktop.editLayout.edit.label"
                                                        />
                                                    </Button>
                                                </>
                                            ) : null
                                        }
                                    />
                                </Box>
                            </Col>
                        )}
                        {!isDesktop && preApprovedProducts?.length > 0 && (
                            <Box
                                ref={this.mySectionRef}
                                id="preApprovedSection"
                                background="white"
                                className="mx-n-5 px-5 pb-5">
                                <Box display="flex" column gap="3">
                                    {preApprovedProducts
                                        ?.slice(0, preApprovedShowMore ? preApprovedProducts?.length : 3)
                                        .map((item) => (
                                            <PreApprovedProduct
                                                image={item.image}
                                                text={item.text}
                                                // href={item.href}
                                                actionLabel={item.actionLabel}
                                                onClick={() => {
                                                    if (item.isCreditPOS) {
                                                        this.showPreApprovedProductModalCreditPos(
                                                            item.productType,
                                                            item.statusCode,
                                                            item.preApprovedCreditUserId,
                                                        );
                                                        return;
                                                    }
                                                    this.showPreApprovedProductModal(item.productType, item.statusCode);
                                                }}
                                                textParams={item.textParams}
                                            />
                                        ))}
                                </Box>
                                {preApprovedProducts?.length > 3 && (
                                    <Box display="flex" alignX="center" className="mt-5">
                                        <Button
                                            bsStyle="link"
                                            label={
                                                preApprovedShowMore
                                                    ? "desktop.widgets.portfolio.seeLess"
                                                    : "desktop.widgets.portfolio.seeMore"
                                            }
                                            onClick={() => {
                                                this.setState({ preApprovedShowMore: !preApprovedShowMore });
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                        )}
                        {!isDesktop && displayCampaigns ? (
                            <Box background="white" className="mx-n-5">
                                <Col
                                    className="campaign-wrapper px-5"
                                    xs={12}
                                    md={6}
                                    mdOffset={isTablet ? 3 : 0}
                                    lg={12}>
                                    <Box className="campaigns-slider-wrapper-dashboard" fullWidth>
                                        <Campaigns
                                            className="mx-md-0 my-0 my-md-5 my-lg-0"
                                            isDesktop={isDesktop}
                                            section="desktop"
                                        />
                                    </Box>
                                </Col>
                            </Box>
                        ) : null}
                    </Row>
                    {deviceUtils.isMobileNative && preApprovedProducts?.length > 0 && showPreApprovedScrollBar && (
                        <PreApprovedScrollBar
                            id="preApprovedScrollBar"
                            actionHandler={this.scrollToPreApproved}
                            closeHandler={() => {
                                this.setState({ showPreApprovedScrollBar: false });
                                this.setState({ wasShownPreApproveds: true });
                            }}
                            secondaryActionHandler={() => {
                                this.setState({ showPreApprovedScrollBar: false });
                                this.setState({ wasShownPreApproveds: true });
                            }}
                        />
                    )}
                </MainContainer>
                {isDesktop && (
                    <Box
                        component="aside"
                        id="fast-access"
                        display="flex"
                        column
                        className="sidebar-right-wrapper"
                        ariaLabel="Sidebar de escritorio"
                        position="relative">
                        <Row heightAuto className="align-items-center px-0">
                            {isDesktop && !isTablet ? (
                                <Col xs={12}>
                                    <Box border="bottom-background-divider-1" className="pb-8">
                                        <ShortCuts
                                            className="mt-7"
                                            isDesktop
                                            options={shortCutBtns}
                                            isStepZero={isStepZero}
                                        />
                                    </Box>
                                </Col>
                            ) : null}
                            {preApprovedProducts?.length > 0 && (
                                <>
                                    <Box display="flex" column gap="5">
                                        {preApprovedProducts
                                            .slice(0, preApprovedShowMore ? preApprovedProducts?.length : 3)
                                            .map((item) => (
                                                <PreApprovedProduct
                                                    image={item.image}
                                                    text={item.text}
                                                    // href={item.href}
                                                    actionLabel={item.actionLabel}
                                                    onClick={() => {
                                                        if (item.isCreditPOS) {
                                                            this.showPreApprovedProductModalCreditPos(
                                                                item.productType,
                                                                item.statusCode,
                                                                item.preApprovedCreditUserId,
                                                            );
                                                            return;
                                                        }
                                                        this.showPreApprovedProductModal(
                                                            item.productType,
                                                            item.statusCode,
                                                        );
                                                    }}
                                                    textParams={item.textParams}
                                                />
                                            ))}
                                    </Box>
                                    {preApprovedProducts?.length > 3 && (
                                        <Box display="flex" alignX="center">
                                            <Button
                                                bsStyle="link"
                                                label={
                                                    preApprovedShowMore
                                                        ? "desktop.widgets.portfolio.seeLess"
                                                        : "desktop.widgets.portfolio.seeMore"
                                                }
                                                onClick={() => {
                                                    this.setState({ preApprovedShowMore: !preApprovedShowMore });
                                                }}
                                            />
                                        </Box>
                                    )}
                                </>
                            )}

                            {/* <Col xs={12} md={campaigns?.length > 0 ? 6 : 12} lg={12}>
                                <PaymentCards />
                            </Col> */}

                            {/* NEXT PAYMENTS */}
                            {listAllNextPayment.length > 0 && (
                                <Box
                                    display="flex"
                                    alignY="center"
                                    column
                                    border="bottom-background-divider-1"
                                    className={listAllNextPayment.length > 3 ? "pb-3" : "pb-5"}
                                    gap="3">
                                    <Text component="h2" color="heading-color" size="3" bold className="mt-0 mb-3">
                                        {i18n.get("nextPayments.desktop.tittle")}
                                    </Text>
                                    <Box
                                        display="flex"
                                        column
                                        gap="5"
                                        fullWidth
                                        className={classNames("next-payment-scrollable", {
                                            "pr-3": nextPaymentsShowMore,
                                        })}>
                                        {listAllNextPayment
                                            .slice(0, nextPaymentsShowMore ? listAllNextPayment.length : 3)
                                            .map((item) => (
                                                <NextPayments
                                                    image={item.image}
                                                    prodName={
                                                        item.type !== null && item.type !== undefined
                                                            ? `${item.type} ${item.alias}`
                                                            : item.alias
                                                    }
                                                    dueDate={item.nextPaymentDateStr}
                                                    onClick={item.onClick}
                                                />
                                            ))}
                                    </Box>
                                    {listAllNextPayment.length > 3 && (
                                        <Box display="flex" alignX="center">
                                            <Button
                                                bsStyle="link"
                                                label={
                                                    nextPaymentsShowMore
                                                        ? "nextPayments.desktop.seeLess"
                                                        : "nextPayments.desktop.seeMore"
                                                }
                                                onClick={() => {
                                                    this.setState({ nextPaymentsShowMore: !nextPaymentsShowMore });
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            )}

                            {displayCampaigns ? (
                                <Col
                                    xs={12}
                                    md={6}
                                    mdOffset={isTablet ? 3 : 0}
                                    lg={12}
                                    {...(!isDesktop && { className: "mb-8" })}>
                                    <Box className="campaigns-slider-wrapper-dashboard">
                                        <Campaigns
                                            className="mx-5 mx-md-0 my-0 my-md-5 my-lg-0"
                                            isDesktop={isDesktop}
                                            section="desktop"
                                        />
                                    </Box>
                                </Col>
                            ) : null}
                            {!isMobile && (
                                <Col xs="12">
                                    <A11yNav
                                        position="relative"
                                        // TODO (LABEL a11y)
                                        ariaLabel="Atajo en Transacciones rápidas"
                                        buttonList={[
                                            { label: "Ir a Sección principal", focusOnSection: "main" },
                                            { label: "Ir a Menu Principal", focusOnSection: "menu" },
                                        ]}
                                        column
                                    />
                                </Col>
                            )}
                        </Row>
                    </Box>
                )}
            </>
        );
    };

    setPreApprovedProducts = () => {
        const { preApprovedProductsList } = this.props;
        if (!preApprovedProductsList || preApprovedProductsList === null) {
            return null;
        }

        const preApprovedProducts = [];
        preApprovedProductsList.forEach((product) => {
            const textParams = {
                AMOUNT: product?.formatedAmount,
            };
            if (product.preApprovedProductType === "TDC") {
                preApprovedProducts.push({
                    image: "images/preApproved/preApprovedCreditCard.svg",
                    text: "preApprovedProduct.banner.TDC.text",
                    textParams,
                    actionLabel: i18n.get("preApprovedProduct.banner.TDC.actionLabel"),
                    productType: product.preApprovedProductType,
                    statusCode: product?.preApprovedProductStatusCode,
                });
            } else if (product.preApprovedProductType === "AUT") {
                preApprovedProducts.push({
                    image: "images/preApproved/preApprovedCar.svg",
                    text: "preApprovedProduct.banner.AUT.text",
                    textParams,
                    actionLabel: i18n.get("preApprovedProduct.banner.PER.actionLabel"),
                    productType: product.preApprovedProductType,
                    statusCode: product?.preApprovedProductStatusCode,
                });
            } else if (product.preApprovedProductType === "HIP") {
                preApprovedProducts.push({
                    image: "images/preApproved/preApprovedMoney.svg",
                    text: "preApprovedProduct.banner.HIP.text",
                    textParams,
                    actionLabel: i18n.get("preApprovedProduct.banner.PER.actionLabel"),
                    productType: product.preApprovedProductType,
                    statusCode: product?.preApprovedProductStatusCode,
                });
            } else if (product.preApprovedProductType === "PER") {
                preApprovedProducts.push({
                    image: "images/preApproved/preApprovedPig.svg",
                    text: "preApprovedProduct.banner.PER.text",
                    textParams,
                    actionLabel: i18n.get("preApprovedProduct.banner.PER.actionLabel"),
                    productType: product.preApprovedProductType,
                    statusCode: product?.preApprovedProductStatusCode,
                });
            } else if (product.preApprovedProductType === "CRE") {
                preApprovedProducts.push({
                    image: "images/preApproved/preApprovedCreditPOS.svg",
                    text: "preApprovedProduct.banner.CRE.text",
                    textParams,
                    actionLabel: i18n.get("preApprovedProduct.banner.CRE.actionLabel"),
                    productType: product.preApprovedProductType,
                    statusCode: product?.preApprovedProductStatusCode,
                    isCreditPOS: true,
                    preApprovedCreditUserId: product.preApprovedCreditUserId,
                });
            }
        });

        return preApprovedProducts;
    };

    render() {
        const {
            sessionFetching,
            desktopFetching,
            isDesktop,
            urlFooterBanner,
            showAdulthoodModal,
            dispatch,
            showMonitorFraudModal,
            showModalPreApproved,
            drawerTokenStatusType,
            showDrawerStatusToken,
            showModalPreApprovedCreditPOS,
        } = this.props;

        const { preApprovedProductType, preApprovedProductStatus } = this.state;

        return (
            <>
                {deviceUtils.isMobileNativeFunc() && !(sessionFetching || desktopFetching) && !this.hasModalShow() && (
                    <BiometricModal />
                )}
                {!isDesktop && this.renderHeader()}
                {isDesktop ? (
                    <Box component="section" className="dashboard-section">
                        {this.renderContent()}
                    </Box>
                ) : (
                    this.renderContent()
                )}
                {isDesktop && (
                    <FooterDesktop
                        dashboard
                        urlFooterBanner={urlFooterBanner}
                        moreOptions={[
                            <Link to="/" key="support">
                                <I18n id="global.support" />
                            </Link>,
                        ]}
                    />
                )}
                <TextAndButtonsModal
                    modalShow={showAdulthoodModal}
                    acceptFunction={() => {
                        dispatch(productActions.openCloseAdulthoodModal(false));
                        dispatch(sessionActions.logout());
                    }}
                    confirmLabel="adulthood.modal.button.accept"
                    headingText={i18n.get("adulthood.modal.title")}
                    text={i18n.get("adulthood.modal.text")}
                />
                <PreApprovedProductModal
                    showModal={showModalPreApproved && preApprovedProductType !== null}
                    handleCloseModal={this.handleClosePreApprovedModal}
                    productType={preApprovedProductType}
                    productStatusCode={preApprovedProductStatus}
                />
                {showModalPreApprovedCreditPOS && preApprovedProductType !== null && (
                    <PreApprovedProductCreditPOSModal
                        showModal={showModalPreApprovedCreditPOS}
                        handleCloseModal={this.handleClosePreApprovedCreditPOSModal}
                        productType={preApprovedProductType}
                        productStatusCode={preApprovedProductStatus}
                    />
                )}
                <BannerAppleWalletContainer />
                <ConfirmDialog
                    showDialog={showMonitorFraudModal}
                    description={i18n.get("configuration.monitor.fraud.modal") || ""}
                    onConfirm={() => dispatch(actions.hideMonitorFraudModal())}
                    confirmLabel="global.accept"
                    textClassName="mx-md-5"
                />
                {drawerTokenStatusType && (
                    <SideBarModal
                        show={showDrawerStatusToken ?? false}
                        onClose={() => {
                            dispatch(actions.hideDrawerStatusToken());
                        }}
                        title={`token.drawer.${drawerTokenStatusType}.title`}>
                        <TokeStatusQR tokenStatus={drawerTokenStatusType} />
                    </SideBarModal>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    availableWidgets: desktopSelectors.getAvailableWidgets(state),
    layout: desktopSelectors.getLayout(state),
    isEditable: desktopSelectors.getIsEditale(state),
    sessionFetching: sessionSelectors.isFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    user: sessionSelectors.getUser(state),
    desktopFetching: desktopSelectors.isFetching(state),
    displayCampaigns: loginSelectors.getDisplayCampaigns(state),
    urlFooterBanner: desktopSelectors.urlFooterBanner(state),
    showUpdateDataModal: desktopSelectors.updateModalShow(state),
    daysSinceLastUpdt: desktopSelectors.getDaysSinceLastUpdt(state),
    showUpdateCorporateDataModal: desktopSelectors.updateCorporateModalShow(state),
    yearsSinceLastUpdtCorporate: desktopSelectors.getYearsSinceLastUpdtCorporate(state),
    isAdministrator: sessionSelectors.isAdministrator(state),
    showAdulthoodModal: productSelectors.getShowAdulthoodModal(state),
    preApprovedProductsList: preApprovedProductSelectors.getPreApprovedProductList(state),
    showModalPreApproved: preApprovedProductSelectors.showModal(state),
    showModalPreApprovedCreditPOS: preApprovedCreditPosSelectors.showModalCreditPOS(state),
    listPayService: payServiceSelectors.getListFastRecharge(state),
    payServiceFetching: payServiceSelectors.isFetching(state),
    listNextPayment: widgetSelectors.getListNextPayment(state),
    showMonitorFraudModal: desktopSelectors.showMonitorFraudModal(state),
    creditLinesList: creditLinesSelectors.getList(state),
    drawerTokenStatusType: desktopSelectors.getDrawerTokenStatusType(state),
    showDrawerStatusToken: desktopSelectors.isShowDrawerStatusToken(state),
    debitCards: debitCardsSelectors.getList(state),
});

export default connect(mapStateToProps)(Desktop);
