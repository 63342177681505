import * as configUtils from "util/config";

const kuaraVersionNumber = (neededVersionNumber) => {
    try {
        const shortDateFormat = configUtils.get("kuara.features.version", "1");
        const version = parseInt(shortDateFormat, 10);
        return version === neededVersionNumber;
    } catch (error) {
        return 1;
    }
};

const NUMBER_VALIDATION_MAP = new Map([
    [1, (phoneFormatData) => !phoneFormatData?.countryCode || !phoneFormatData?.phoneNumber],
    [2, (phoneFormatData) => !phoneFormatData],
]);

// eslint-disable-next-line no-unused-vars
const numberValidationDefault = (phoneFormatData) => true;

export const getValidateNumberByVersion = () => {
    try {
        const shortDateFormat = configUtils.get("kuara.features.version", "1");
        return NUMBER_VALIDATION_MAP.get(shortDateFormat) ?? numberValidationDefault;
    } catch (error) {
        return numberValidationDefault;
    }
};

export const isKuaraActive = () => {
    try {
        return configUtils.getBoolean("kuara.show.feature", false);
    } catch (error) {
        return false;
    }
};

export default kuaraVersionNumber;
