import React, { Fragment } from "react";
import { oneOfType, arrayOf, node, string } from "prop-types";
import * as configUtil from "util/config";

const FeatureFlag = ({ children, id }) => {
    const isEnabled = configUtil.get(id, true);
    return <Fragment>{isEnabled && children}</Fragment>;
};

FeatureFlag.propTypes = {
    children: oneOfType([arrayOf(node), node]),
    id: string,
};

FeatureFlag.defaultProps = {
    children: null,
    id: "",
};

export default FeatureFlag;
