import { Field, Form, withFormik } from "formik";
import Box from "pages/_components/Box";
import Button from "pages/_components/Button";
import Document from "pages/_components/fields/Document";
import Checkbox from "pages/_components/fields/formik/Checkbox";
import PhoneInput from "pages/_components/fields/PhoneInput";
import TextField from "pages/_components/fields/TextField";
import Row from "pages/_components/Row";
import Text from "pages/_components/Text";
import { func, string, shape, bool, arrayOf } from "prop-types";
import HeadSimple from "pages/_components/HeadSimple";
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { selectors as themeSelectors } from "reducers/theme";
import {
    selectors as userDataUpdateRequestSelectors,
    actions as userDataUpdateRequestActions,
} from "reducers/userDataUpdateRequest";
import { compose } from "redux";
import * as i18n from "util/i18n";
import * as Yup from "yup";
import SelectViewPort from "pages/forms/_components/_fields/SelectViewPort";

const FORM_ID = "remediation.step1";
class RemediationUserContentStep1 extends Component {
    static propTypes = {
        className: string,
        dispatch: func.isRequired,
        countryList: arrayOf[{}],
        documentTypeList: arrayOf[{}],
        idNumberMap1: shape({}),
        idNumberMap2: shape({}),
        fetching: bool,
        isMobile: bool,
        isDesktop: bool,
        userFirstName: string,
        setFieldValue: func.isRequired,
    };

    static defaultProps = {
        className: null,
        countryList: [],
        documentTypeList: [],
        idNumberMap1: {},
        idNumberMap2: {},
        fetching: false,
        isMobile: false,
        isDesktop: false,
        userFirstName: "",
    };

    constructor(props) {
        super(props);
        this.titleRef = React.createRef();
        this.state = {
            // eslint-disable-next-line react/no-unused-state
            dataTreatment: false,
        };
    }

    onChangeDataTreatment = () => {
        const { setFieldValue } = this.props;
        this.setState((previousState) => ({
            ...previousState,
            dataTreatment: !previousState.dataTreatment,
        }));
        setFieldValue("dataTreatment", !this.state.dataTreatment);
    };

    render() {
        const {
            className,
            countryList,
            documentTypeList,
            idNumberMap1,
            idNumberMap2,
            isMobile,
            isDesktop,
            userFirstName,
        } = this.props;
        const preferredCountries = ["pa", "ve"];
        const areaCodes = { ve: ["412", "414", "416", "424", "426"] };

        return (
            <Form className={`${className} d-flex mt-3 justify-content-between align-items-center flex-column`}>
                <Box className="mb-5" display="flex" column alignX="center" fullWidth>
                    {isMobile && (
                        <HeadSimple
                            title="remediation.step1.title"
                            className="my-4"
                            textParams={{
                                NAME: userFirstName,
                            }}
                        />
                    )}
                    <Text
                        component="p"
                        color="text"
                        size={isMobile ? "5" : "6"}
                        align="center"
                        labelKey="remediation.step1.subtitle"
                    />
                </Box>

                <Box className="" display="flex" column alignX="center" fullWidth>
                    <Box className={`${isMobile ? "" : "content-remediationUser-wrapper pr-4"}`} scrollable>
                        <Row>
                            <Col xs={12}>
                                <Field
                                    idForm={FORM_ID}
                                    name="name"
                                    component={TextField}
                                    placeholder=""
                                    autoFocus={isDesktop}
                                    className="remove-flex"
                                    pattern="^[a-zA-Z0-9 ]*$"
                                />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    idForm={FORM_ID}
                                    name="lastName"
                                    component={TextField}
                                    placeholder=""
                                    autoFocus={isDesktop}
                                    className="remove-flex"
                                    pattern="^[a-zA-Z0-9 ]*$"
                                />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    idForm={FORM_ID}
                                    name="email"
                                    placeholder=""
                                    labelNoMarginTop
                                    component={TextField}
                                    autoFocus={isDesktop}
                                    className="remove-flex"
                                />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    component={PhoneInput}
                                    idForm={FORM_ID}
                                    idField={`${FORM_ID}.mobilePhone`}
                                    name="mobilePhone"
                                    isDesktop={isDesktop}
                                    className="remove-flex"
                                    country="pa"
                                    preferredCountries={preferredCountries}
                                    areaCodes={areaCodes}
                                    enableAreaCodes={preferredCountries}
                                    labelNoMarginTop
                                    mobilePhone={undefined}
                                />
                            </Col>
                            <Col xs={12}>
                                <Field
                                    countries={countryList}
                                    documentCountryMap={documentTypeList}
                                    idNumberMap={idNumberMap1}
                                    idNumber2Map={idNumberMap2}
                                    component={Document}
                                    searchableDocumentType={false}
                                    searchableIdentification={false}
                                    name="documentUser"
                                    reducedColumnGap
                                    customComponentSelect={SelectViewPort}
                                    {...(isMobile && { idNumberStyle2: true })}
                                />
                            </Col>
                        </Row>
                    </Box>
                </Box>

                <Box className="my-8" column display="flex" alignX="center">
                    <Field
                        idForm={FORM_ID}
                        name="dataTreatment"
                        component={Checkbox}
                        label={i18n.get("remediation.step1.dataTreatment")}
                        block={false}
                        checked={this.state.dataTreatment}
                        labelSize={isDesktop ? "7" : "6"}
                        labelColor="heading-color"
                        onChange={this.onChangeDataTreatment}
                    />
                </Box>

                <Box display="flex">
                    <Button
                        type="submit"
                        bsStyle="primary"
                        label="global.continue"
                        block
                        disabled={!this.state.dataTreatment}
                    />
                </Box>
            </Form>
        );
    }
}

const mapStateToProps = (state) => ({
    theme: themeSelectors.getThemeOption(state),
    countryList: userDataUpdateRequestSelectors.getCountryList(state),
    documentTypeList: userDataUpdateRequestSelectors.getDocumentTypeList(state),
    idNumberMap1: userDataUpdateRequestSelectors.getIdNumberMap1(state),
    idNumberMap2: userDataUpdateRequestSelectors.getIdNumberMap2(state),
    fetching: userDataUpdateRequestSelectors.getFetching(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            name: "",
            lastName: "",
            environmentType: props.theme,
            mobilePhone: "",
            documentUser: "",
            dataTreatment: false,
            email: "",
        }),
        validationSchema: () =>
            Yup.object().shape({
                name: Yup.string().required(i18n.get(`${FORM_ID}.name.required`)),
                lastName: Yup.string().required(i18n.get(`${FORM_ID}.lastName.required`)),
                email: Yup.string()
                    .email(i18n.get(`${FORM_ID}.email.invalid`))
                    .required(i18n.get(`${FORM_ID}.email.required`)),
                mobilePhone: Yup.object()
                    .shape({
                        prefix: Yup.string().required(i18n.get(`${FORM_ID}.mobilePhone.required`)),
                        value: Yup.string().required(i18n.get(`${FORM_ID}.mobilePhone.required`)),
                    })
                    .required(i18n.get(`${FORM_ID}.mobilePhone.required`)),
                documentUser: Yup.object().shape({
                    documentCountry: Yup.string().required(i18n.get(`${FORM_ID}.documentCountry.required`)),
                    documentType: Yup.string().required(i18n.get(`${FORM_ID}.documentType.required`)),
                    documentNumber1: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.required`)),
                    documentNumber2: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) => documentCountry === "PA" && documentType === "CIP",
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.required`)),
                    }),
                    idNumber1: Yup.string().when(["documentCountry", "documentType"], {
                        is: (documentCountry, documentType) =>
                            documentCountry === "PA" || (documentCountry === "VE" && documentType === "CED"),
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.required`)),
                    }),
                    idNumber2: Yup.string().when("documentCountry", {
                        is: "PA",
                        then: Yup.string().required(i18n.get(`${FORM_ID}.documentNumber.required`)),
                    }),
                }),
            }),
        handleSubmit: (values, formikBag) => {
            const { name, lastName, email, mobilePhone, documentUser, dataTreatment } = values;
            formikBag.props.dispatch(
                userDataUpdateRequestActions.sendDataUserDataUpdateRequestSend(
                    name,
                    lastName,
                    email,
                    mobilePhone,
                    documentUser,
                    dataTreatment,
                    formikBag,
                ),
            );
        },
    }),
)(RemediationUserContentStep1);
