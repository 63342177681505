import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { types as fingerprintTypes } from "reducers/fingerprint";
import { createReducer, makeActionCreator } from "util/redux";
import { REGION_PAN } from "constants.js";

export const types = {
    RESET: "login/RESET",
    INIT_LOGIN_FLOW: "login/INIT_LOGIN_FLOW",

    FINALIZE_LOGIN: "login/FINALIZE_LOGIN",

    LOGOUT_REQUEST: "login/LOGOUT_REQUEST",

    LOGIN_STEP_1_REQUEST: "login/LOGIN_STEP_1_REQUEST",
    LOGIN_STEP_1_SUCCESS: "login/LOGIN_STEP_1_SUCCESS",

    LOGIN_OAUTH_REQUEST: "login/LOGIN_OAUTH_REQUEST",
    LOGIN_OAUTH_SUCCESS: "login/LOGIN_OAUTH_SUCCESS",
    LOGIN_OAUTH_FAILURE: "login/LOGIN_OAUTH_FAILURE",
    LOGIN_LIST_ENVIRONMENTS_REQUEST: "login/LOGIN_LIST_ENVIRONMENTS_REQUEST",
    LOGIN_LIST_ENVIRONMENTS_SUCCESS: "login/LOGIN_LIST_ENVIRONMENTS_SUCCESS",

    LOGIN_STEP_3_REQUEST: "login/LOGIN_STEP_3_REQUEST",
    LOGIN_STEP_3_SUCCESS: "login/LOGIN_STEP_3_SUCCESS",
    LOGIN_STEP_4_REQUEST: "login/LOGIN_STEP_4_REQUEST",

    LOGIN_FAILURE: "login/LOGIN_FAILURE",
    LOGIN_FAILURE_REQUIRE_CAPTCHA: "LOGIN_FAILURE_REQUIRE_CAPTCHA",
    LOGIN_FINGERPRINT_FAILURE: "login/LOGIN_FINGERPRINT_FAILURE",
    LOGIN_SUCCESS: "login/LOGIN_SUCCESS",
    SET_SUBMITING: "login/SET_SUBMITING",
    FINGERPRINT_LOGIN_SUBMIT: "login/FINGERPRINT_LOGIN_SUBMIT",
    FINGERPRINT_LOGIN_PRE: "login/FINGERPRINT_LOGIN_PRE",
    FINGERPRINT_LOGIN_PRE_SUCCESS: "login/FINGERPRINT_LOGIN_PRE_SUCCESS",
    LOGIN_FINGERPRINT_FAILURE_PROCESS: "login/LOGIN_FINGERPRINT_FAILURE_PROCESS",

    SET_REMEMBERED_USER: "login/SET_REMEMBERED_USER",
    REMOVE_REMEMBERED_USER: "login/REMOVE_REMEMBERED_USER",
    SET_FROM_ONBOARDING_DATA: "login/SET_FROM_ONBOARDING_DATA",
    MARK_ENVIRONMENTS_DISABLED: "login/MARK_ENVIRONMENTS_DISABLED",
    GO_BACK_LOGIN_SHOW_MESSAGE: "login/GO_BACK_LOGIN_SHOW_MESSAGE",
    SET_FINGERPRINT_ENVIRONMENT_RESTRICTED: "login/SET_FINGERPRINT_ENVIRONMENT_RESTRICTED",
    SET_REGION: "session/SET_REGION",
    GET_CLIENT_COUNTRY_REQ: "session/GET_CLIENT_COUNTRY_REQ",
    GET_CLIENT_COUNTRY_RES: "session/GET_CLIENT_COUNTRY_RES",

    DISMISS_CAMPAIGN_REQUEST: "campaigns/DISMISS_CAMPAIGN_REQUEST",
    SHOW_SUSPICIOUS_ACTIVITY_MODAL: "login/SHOW_SUSPICIOUS_ACTIVITY_MODAL",
    SHOW_MODAL_INFO: "login/SHOW_MODAL_INFO",
    LOGIN_FINGERPRINTER_STEP_4_REQUEST: "login/LOGIN_FINGERPRINTER_STEP_4_REQUEST",
    CANCEL_LOGIN_STEP_1_REQUEST: "login/CANCEL_LOGIN_STEP_1_REQUEST",
    CANCEL_LOGIN_STEP_3_REQUEST: "login/CANCEL_LOGIN_STEP_3_REQUEST",
};

export const INITIAL_STATE = {
    rememberedUser: null,
    exchangeToken: null,
    showCaptcha: false,
    fetching: false,
    lang: "",
    environments: {},
    fromOnboardingLoginData: null,
    username: null,
    userFirstName: null,
    userFullName: null,
    fingerPrintEnvironmentRestricted: false,
    idEnvironmentToAcceptConditions: null,
    region: REGION_PAN,
    clientCountry: REGION_PAN,
    hasLogged: null,
    displayCampaigns: true,
    shouldRememberEmail: false,
    fingerprintSubmiting: false,
    isSubmiting: false,
    showSuspiciousActivityModal: 0,
    showModalInfo: false,
    environmentType: null,
};

const reducer = createReducer(INITIAL_STATE, {
    [types.SET_REGION]: (state, { region }) => ({
        ...state,
        region,
    }),
    [types.GET_CLIENT_COUNTRY_RES]: (state, { clientCountry }) => ({
        ...state,
        clientCountry,
    }),
    [types.SET_FROM_ONBOARDING_DATA]: (state, { fromOnboardingLoginData }) => ({
        ...state,
        fromOnboardingLoginData,
    }),
    [types.RESET]: (state) => ({
        ...INITIAL_STATE,
        rememberedUser: state.rememberedUser,
        fromOnboardingLoginData: state.fromOnboardingLoginData,
        region: state.region,
        clientCountry: state.clientCountry,
        fetching: false,
    }),
    [types.INIT_LOGIN_FLOW]: ({ fromOnboardingLoginData, rememberedUser, region, clientCountry }) => ({
        ...INITIAL_STATE,
        rememberedUser,
        fromOnboardingLoginData,
        region,
        clientCountry,
    }),

    [types.SET_FINGERPRINT_ENVIRONMENT_RESTRICTED]: (state) => ({ ...state, fingerPrintEnvironmentRestricted: true }),
    [types.LOGIN_STEP_1_REQUEST]: (state) => ({ ...state, notification: null }),
    [types.LOGIN_STEP_1_SUCCESS]: (
        state,
        { exchangeToken, username, shouldRememberEmail, lang, _userFirstName, _userFullName, environments },
    ) => ({
        ...state,
        fetching: false,
        showCaptcha: false,
        exchangeToken,
        username,
        lang,
        _userFirstName,
        _userFullName,
        environments,
        shouldRememberEmail,
    }),

    [types.MARK_ENVIRONMENTS_DISABLED]: (state, { environments }) => ({
        ...state,
        fetching: false,
        environments,
    }),

    [types.LOGIN_OAUTH_REQUEST]: (state) => ({ ...state, fetching: true, notification: null }),
    [types.LOGIN_OAUTH_SUCCESS]: (state, { environments, lang, _userFirstName, _userFullName }) => ({
        ...state,
        fetching: false,
        showCaptcha: false,
        lang,
        _userFirstName,
        _userFullName,
        environments,
    }),
    [types.LOGIN_OAUTH_FAILURE]: (state) => ({
        ...state,
        fetching: false,
    }),

    [types.LOGIN_STEP_3_REQUEST]: (state) => ({ ...state, fetching: true, notification: null }),
    [types.LOGIN_STEP_3_SUCCESS]: (state, { termsAndConditions }) => ({
        ...state,
        fetching: false,
        termsAndConditions,
    }),

    [types.LOGIN_STEP_4_REQUEST]: (state) => ({ ...state, fetching: true, notification: null }),
    [types.LOGIN_SUCCESS]: ({ rememberedUser, region, clientCountry }) => ({
        ...INITIAL_STATE,
        rememberedUser,
        region,
        clientCountry,
        fetching: false,
    }),

    [types.REMOVE_REMEMBERED_USER]: (state) => ({ ...state, rememberedUser: INITIAL_STATE.rememberedUser }),
    [types.SET_REMEMBERED_USER]: (state, { rememberedUser }) => ({ ...state, rememberedUser }),

    [types.LOGIN_FAILURE]: (state) => ({ ...state, fetching: false, fingerprintSubmiting: false }),

    [types.LOGIN_FAILURE_REQUIRE_CAPTCHA]: (state) => ({
        ...state,
        fetching: false,
        showCaptcha: true,
        fingerprintSubmiting: false,
    }),
    [types.LOGIN_FINGERPRINT_FAILURE]: (state) => ({
        ...state,
        fetching: false,
        showCaptcha: true,
        fingerprintSubmiting: false,
        fingerprintLoginFail: true,
    }),
    [types.LOGIN_FINGERPRINT_FAILURE_PROCESS]: (state) => ({
        ...state,
        fetching: false,
        fingerprintSubmiting: false,
        fingerprintLoginFail: true,
    }),
    [types.FINALIZE_LOGIN]: (state, { response }) => ({
        ...state,
        idEnvironmentToAcceptConditions: response.data.data.idEnvironmentToAcceptConditions,
        fetching: false,
    }),
    [types.FINGERPRINT_LOGIN_SUBMIT]: (state) => ({ ...state, fingerprintSubmiting: true }),
    [types.FINGERPRINT_LOGIN_PRE_SUCCESS]: (state, { exchangeToken }) => ({
        ...state,
        fetching: false,
        exchangeToken,
    }),
    [types.FINGERPRINT_LOGIN_PRE]: (state) => ({
        ...state,
        fetching: true,
    }),

    [fingerprintTypes.FINGERPRINT_CONFIGURATION_PRE_SUCCESS]: (state, { exchangeToken }) => ({
        ...state,
        exchangeToken,
    }),
    [types.DISMISS_CAMPAIGN_REQUEST]: (state) => ({
        ...state,
        displayCampaigns: false,
    }),
    [types.SET_SUBMITING]: (state, { isSubmiting }) => ({
        ...state,
        isSubmiting,
    }),
    [types.SHOW_SUSPICIOUS_ACTIVITY_MODAL]: (state) => ({
        ...state,
        showSuspiciousActivityModal: state.showSuspiciousActivityModal + 1,
    }),
    [types.SHOW_MODAL_INFO]: (state, { active }) => ({
        ...state,
        showModalInfo: active,
    }),
    [types.LOGIN_FINGERPRINTER_STEP_4_REQUEST]: (state) => ({ ...state, fetching: true, notification: null }),
});

export default persistReducer(
    {
        storage,
        key: "login",
        whitelist: ["rememberedUser", "clientCountry"],
    },
    reducer,
);

export const actions = {
    dismissCampaigns: () => ({
        type: types.DISMISS_CAMPAIGN_REQUEST,
    }),
    getClientContry: () => ({
        type: types.GET_CLIENT_COUNTRY_REQ,
    }),
    setRegion: (region) => ({
        type: types.SET_REGION,
        region,
    }),
    reset: makeActionCreator(types.RESET),
    initLoginFlow: makeActionCreator(types.INIT_LOGIN_FLOW),

    loginStep1: makeActionCreator(
        types.LOGIN_STEP_1_REQUEST,
        "email",
        "shouldRememberEmail",
        "password",
        "recaptchaResponse",
        "credentialType",
        "otp",
        "formikBag",
        "isActiveCorporate",
    ),
    cancelLoginStep1: makeActionCreator(types.CANCEL_LOGIN_STEP_1_REQUEST),
    cancelLoginStep3: makeActionCreator(types.CANCEL_LOGIN_STEP_3_REQUEST),
    setSubmiting: makeActionCreator(types.SET_SUBMITING, "isSubmiting"),
    oauth: makeActionCreator(types.LOGIN_OAUTH_REQUEST, "password", "recaptchaResponse", "formikBag"),
    loginStep3: makeActionCreator(types.LOGIN_STEP_3_REQUEST, "idEnvironment", "rememberEnvironment", "formikBag"),
    loginStep4: makeActionCreator(types.LOGIN_STEP_4_REQUEST, "acceptConditions", "formikBag"),

    fingerprintLoginPre: makeActionCreator(types.FINGERPRINT_LOGIN_PRE),

    setRememberedUser: makeActionCreator(types.SET_REMEMBERED_USER, "rememberedUser"),
    removeRememberedUser: makeActionCreator(types.REMOVE_REMEMBERED_USER),
    goBackToLoginAndShowMessage: makeActionCreator(types.GO_BACK_LOGIN_SHOW_MESSAGE, "message"),
    showSuspiciousActivityModal: makeActionCreator(types.SHOW_SUSPICIOUS_ACTIVITY_MODAL),
    showModalInfo: makeActionCreator(types.SHOW_MODAL_INFO, "active"),
    loginFingerPrinterStep4Request: makeActionCreator(
        types.LOGIN_FINGERPRINTER_STEP_4_REQUEST,
        "acceptConditions",
        "formikBag",
    ),
};

export const selectors = {
    getUsername: ({ login }) => login.username,
    getFingerPrintEnvironmentRestricted: ({ login }) => login.fingerPrintEnvironmentRestricted,
    getRememberedUser: ({ login }) => login.rememberedUser,
    getShouldRememberEmail: ({ login }) => login.shouldRememberEmail,
    getUserFirstName: ({ login }) => login._userFirstName,
    getUserFullName: ({ login }) => login.userFullName,
    getSecuritySeal: ({ login }) => login.securitySeal,
    getSecuritySealAlt: ({ login }) => login.securitySealAlt,
    getShowCaptcha: ({ login }) => login.showCaptcha,
    getFetching: ({ login }) => login.fetching,
    getSubmiting: ({ login }) => login.isSubmiting,
    getNotification: ({ login }) => login.notification,
    getTermsAndConditions: ({ login }) => login.termsAndConditions,
    getExchangeToken: ({ login }) => login.exchangeToken,
    getAccessToken: ({ login }) => login.accessToken,
    getFingerprintSubmiting: ({ login }) => login.fingerprintSubmiting,
    getFingerprintLoginFail: ({ login }) => login.fingerprintLoginFail,
    getEnvironments: ({ login }) => login.environments,
    getIsInFlow: ({ login }) => !!login.exchangeToken,
    getFromOnboardingLoginData: ({ login }) => login.fromOnboardingLoginData,
    getIdEnvironmentToAcceptConditions: ({ login }) => login.idEnvironmentToAcceptConditions,
    getRegion: ({ login }) => login.region,
    getHasLogged: ({ login }) => login.hasLogged,
    getDisplayCampaigns: ({ login }) => login.displayCampaigns,
    getShowSuspiciousActivityModal: ({ login }) => login.showSuspiciousActivityModal,
    getShowModalInfo: ({ login }) => login.showModalInfo,
};
