import React, { Component } from "react";
import PropTypes from "prop-types";

class Line extends Component {
    static propTypes = {
        color: PropTypes.string,
        height: PropTypes.string,
        width: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        color: "background-divider",
        height: "1px",
        width: "100%",
        className: "",
    };

    render() {
        const { color, height, width, className } = this.props;
        return (
            <div
                className={`background-${color} ${className}`}
                style={{
                    height,
                    width,
                }}
            />
        );
    }
}

export default Line;
