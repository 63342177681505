/* eslint-disable */
import classNames from "classnames";
import { Field } from "formik";
import moment from "moment";
import {
    PDFAmountField,
    PDFAmountFieldError,
    PDFBeneficiaryField,
    PDFProductSelectorField,
    PDFTextField,
} from "pages/forms/customForms/PDFTicket";
import BeneficiaryField from "pages/forms/customForms/_customFields/BeneficiaryField";
import * as FormFieldsComponents from "pages/forms/_components/_fields/Index";
import Box from "pages/_components/Box";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import { resizableRoute } from "pages/_components/Resizable";
import Row from "pages/_components/Row";
import { bool, func, shape, string, arrayOf } from "prop-types";
import { parse } from "query-string";
import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import { actions as formActions, selectors as formSelectors } from "reducers/form";
import { selectors as i18nSelectors } from "reducers/i18n";
import { actions as notificationActions } from "reducers/notification";
import { selectors as templateSelectors } from "reducers/template";
import { compose } from "redux";
import * as i18n from "util/i18n";
import { numberFormat } from "util/number";
import * as schedulerUtils from "util/scheduler";
import * as thirdTransaction from "util/thirdTransaction";
import { statusMap } from "util/general";
import { DEFAULT_REQUIRED } from "util/validationSchemaUtil";
import { selectors as sessionSelectors } from "reducers/session";
import FormTransition from "../_components/FormTransition";
import { SCHEDULER_DATE } from "../_components/_fields/ReadText";
import { useStartSensorDataCapture } from "./hooks/useStartSensorDataCapture";
import { validateTransferLocalForm } from "pages/frequentDestination/validation/BeneficiaryFormValidation";
import { selectors as creditCardSelectors } from "reducers/creditCard";
import {
    useCreditCardPaySelectorOptionsWithBalance,
} from "./hooks/PayCreditCardHooks";
import { amountDefault } from "./_customFields/AmountPaymentTypeField/AmountPaymentTypeField";
import * as configUtils from "util/config";
import Disclaimer from "pages/_components/Disclaimer";
import { useCreditCardOrigin } from "./hooks/TransferInternalHooks";
import { push } from "react-router-redux";


const Pay2x3Form = (props) => {

    const {
        mode,
        dispatch,
        location,
        preDataForm,
        previewData,
        isTablet,
        transaction,
        fromOneTouchAccess,
        activeEnvironment,
        creditCardList,
    } = props;
    
    const { idActivity } = transaction;
    

    const { decimalSeparator, thousandSeparator } = numberFormat();


    const ID_FORM ="pay.2x3";
    const transactionKind = thirdTransaction.getTransactionKindByIdForm(ID_FORM);

    const TITLE_FORM ="forms.pay.2x3.formName";

    const TITLE_PREVIEW_VIEW_FORM ="forms.pay.2x3.formName";

    const ID_ACTIVITY = `${ID_FORM}.send`;
    const ID_ACTIVITY_PRE = `${ID_FORM}.pre`;

    const isCreditCardPaymentForm = location.pathname === "/formCustom/payCreditCardThird";
    const isCreditCardRechargeForm = location.pathname === "/formCustom/rechargeCreditCardThird";

    useStartSensorDataCapture(mode);
    const [creditCardOrigin] = useCreditCardOrigin(location);
    const [titleForm, setTitleForm] = useState(TITLE_FORM);
    const [idActivityState, setIdActivityState] = useState(transaction?.idActivity ? idActivity : `${ID_FORM}.send`);
    const creditCardOptions = useCreditCardPaySelectorOptionsWithBalance(creditCardList);
    
    useEffect(() => {
      if (!creditCardList || creditCardList.length === 0) {
          dispatch(push("/desktop"));
          dispatch(
              notificationActions.showNotification(
                i18n.get("products.operation.NoDispose.2x3"),
                  "error",
                  ["desktop"],
              ),
          );
      }
    }, [creditCardList])
    
    
    useEffect(() => {
        if (mode === "edit") {
            dispatch(formActions.preForm({ idActivity: ID_ACTIVITY_PRE, requestData: {
                creditCardOrigin:parse(location.search)?.creditCard
            } }));
            setTitleForm(TITLE_FORM);
        }
        if (mode === "preview" || mode === "view") {
            setTitleForm(TITLE_PREVIEW_VIEW_FORM);
        }

        if (mode === "preview") {
            if (previewData.beneficiaryNameAlreadyExists) {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("forms.pay.2x3.beneficiary.nameAlreadyExists"),
                        "warning",
                        ["form"],
                    ),
                );
            }

            if (previewData.beneficiaryAlreadyExists) {
                dispatch(
                    notificationActions.showNotification(
                        i18n.get("forms.pay.2x3.beneficiary.alreadyExists"),
                        "warning",
                        ["form"],
                    ),
                );
            }
        }
        // eslint-disable-next-line
    }, [dispatch, mode, location]);

    const renderTicket = (values) => {
        const { idTransactionStatus } = props.transaction;
        const idStatus = statusMap.get(idTransactionStatus) ?? idTransactionStatus;
        const vaucherLabel = idStatus === "REJECTED" ? "Reference" : "";
        const showReference = idStatus === "FINISHED" || idStatus === "REJECTED" || idStatus === "ACCEPTED";

        return (
            <>
                {idStatus === "FINISHED" ? (
                    <PDFAmountField
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label_view`)}
                    />
                ) : (
                    <PDFAmountFieldError
                        idForm={ID_FORM}
                        name="amount"
                        values={values}
                        label={i18n.get(`forms.${ID_FORM}.amount.label_view`)}
                    />
                )}

                {showReference && (
                    <PDFTextField
                        idForm={ID_FORM}
                        name="backendReference"
                        values={values}
                        label={i18n.get(`forms.pay.2x3.voucher${vaucherLabel}.label`)}
                    />
                )}

                <PDFTextField
                    idForm={ID_FORM}
                    value={
                        transaction?.idParentTransaction
                            ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                            : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                    }
                    label={i18n.get("forms.pay.2x3.date.label")}
                />

                <PDFTextField
                    idForm={ID_FORM}
                    value={`${
                        transaction?.programed ? i18n.get("forms.transaction.ticket.typeOperation.program") : ""
                    } ${i18n.get("forms.pay.2x3.formName")}`}
                    label={i18n.get("forms.pay.2x3.operationType.label")}
                />

                <PDFProductSelectorField idForm={ID_FORM} name="creditCard" values={values} />

                <PDFBeneficiaryField idForm={ID_FORM} name="beneficiary" values={values} />

                <PDFTextField
                    idForm={ID_FORM}
                    name="estimatedCredit"
                    value={values?.obligationTypeData.label}
                    label={i18n.get("forms.pay.2x3.obligationType.label")}
                />
            </>
        );
    };

    const getTopDisclaimer = () => {
        if (mode === "preview") {
            return "forms.pay.2x3.disclaimer";
        }
        return null;
    };

    const renderFields = (setFieldValue, values, setValues, renderScheduler,errors) => {
        
        const { currentLang, fromBackoffice, isDesktop } = props;

        const idTransaction = transaction?.idTransaction;

        const genericProps = {
            mode,
            lang: currentLang,
            idTransactionTicket: idTransaction,
            fromBackoffice,
            isRequired: true,
            idActivity: ID_ACTIVITY,
        };

        if (mode === "edit" && (!preDataForm || !preDataForm.currencyList)) {
            return <PageLoading loading />;
        }
        const preData = preDataForm || {
            currencyList: [],
            obligationTypeList:[],
        };

        const dataAmount = {
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options: preData.currencyList?.map((c) => ({
                id: c.backendId,
                label: c.code,
            })) || [
                {
                    id: values?.amount?.currency,
                    label: values?.amount?.currency ? i18n.get(`currency.label.${values?.amount?.currency}`) : "",
                },
            ],
        };
       
        const getDataAmount = (amount) => ({
            decimalSeparator,
            precision: 2,
            thousandsSeparator: thousandSeparator,
            options: [
                {
                    id: amount?.currency,
                    label: amount?.currency ? i18n.get(`currency.label.${amount?.currency}`) : "",
                },
            ],
        });

        const getProductTypeList = () => {
            return preData?.productTypeList || [];
        };

        const getBankList = () => {
            return preData?.bankList || [];
        };

      

        

        const onBeneficiaryChange = (newValue) => {
            const { amount } = values;
            const bankNameID = newValue.bank?.id || newValue.bankCode;

            if (isCreditCardPaymentForm || isCreditCardRechargeForm) {
                setFieldValue("amount", { ...(amount || {}), currency: "USD" });
            } else if (!amount) {
                setFieldValue("amount", { currency: "USD", quantity: "" });
            }

            if (ID_FORM === "transfers.local") {
                if (bankNameID === "BANSPAPA") {
                    setIdActivityState("transfers.thirdParties.send");
                } else {
                    setIdActivityState(ID_ACTIVITY);
                }
            }
        };

        return (
            <>
                {mode === "edit" && (
                    <Row
                        {...(isTablet && { gapX: "8" })}
                        {...(isDesktop && !isTablet && { gapX: "12" })}
                        {...(!isDesktop && { gapY: "0" })}>
                        <Col xs={12} md={6}>
                            <Box
                                background="white"
                                position="relative"
                                className={classNames({
                                    "pl-5 pl-lg-10 pr-5 pr-lg-10 pt-6 pb-11 pb-md-11 mx-n-5 mb-7":
                                        isCreditCardPaymentForm || isCreditCardRechargeForm,
                                    "pl-5 pl-lg-10 pr-5 pr-lg-10 pb-9 pb-md-10 mx-n-5 mb-7":
                                        !isCreditCardPaymentForm && !isCreditCardRechargeForm,
                                })}
                                borderRadius="default">
                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.ProductselectorCustom}
                                    data={creditCardOptions}
                                    key="creditCard"
                                    name="creditCard"
                                    renderAs="combo"
                                    value={creditCardOrigin || values?.creditCard}
                                    idField="creditCard"
                                    labelIdField="react-select-debitAccount-input"
                                    autoFocus
                                    loadBalance
                                    showCreditCardBalance
                                />
                            </Box>

                            <Box
                                background="white"
                                className={classNames({
                                    "pt-10 pl-5 pl-lg-10 pr-5 pr-lg-10 pb-11 pb-md-11 mx-n-5 mb-7":
                                        isCreditCardPaymentForm || isCreditCardRechargeForm,
                                    "pt-6 pl-5 pl-lg-10 pr-5 pr-lg-10 pb-8 pb-md-11 mx-n-5 mb-7":
                                        !isCreditCardPaymentForm && !isCreditCardRechargeForm,
                                })}
                                borderRadius="default">
                                <Box
                                    display="flex"
                                    gap={isDesktop ? "3" : "2"}
                                    {...(isDesktop ? { alignY: "center" } : { column: true, alignX: true })}>
                                    <Field
                                        {...genericProps}
                                        component={FormFieldsComponents.Amount}
                                        data={dataAmount}
                                        key="amount"
                                        name="amount"
                                        value={
                                            isCreditCardRechargeForm
                                                ? { currency: "USD", quantity: "" }
                                                : { currency: values?.creditCardData?.currency, quantity: "" }
                                        }
                                        idField="amount"
                                        customPlaceholderCurrency=""
                                        customPlaceholderQuantity="0.00"
                                        quantityTextAlign="text-left"
                                        disabledCurrency
                                    />
                                </Box>

                                <Field
                                    {...genericProps}
                                    component={FormFieldsComponents.Selector}
                                    key="obligationType"
                                    name="obligationType"
                                    idField="obligationType"
                                    labelIdField="react-select-debitReference-input"
                                    optionList={preData.obligationTypeList}
                                    renderAs="combo"
                                    isFocused={false}
                                    onChange={(valueSeleted)=>{
                                        setFieldValue("obligationTypeData",valueSeleted[0])
                                    }}
                                />
                            </Box>

                            <Box
                                background="white"
                                className={classNames({
                                    "pt-0 pb-0 pt-md-5 pb-md-5": isCreditCardPaymentForm || isCreditCardRechargeForm,
                                })}
                                borderRadius="default">
                                {renderScheduler && renderScheduler()}
                            </Box>
                        </Col>

                        <Box
                            className={classNames({
                                "center-absolute-xy arrow-transfer": isDesktop,
                                "arrow-transfer": !isDesktop,
                            })}
                            {...(isDesktop && { position: "absolute", left: "50", top: "50" })}>
                            <Image src="images/icons/transfersArrow.svg" />
                        </Box>

                        <Col xs={12} md={6}>
                            <Box background="white" borderRadius="default">
                                <Field
                                    {...genericProps}
                                    isPay2x3Form
                                    component={BeneficiaryField}
                                    key="beneficiary"
                                    name="beneficiary"
                                    idField="beneificary"
                                    bankList={getBankList()}
                                    productTypeList={getProductTypeList()}
                                    value={values?.beneficiary}
                                    onChange={onBeneficiaryChange}
                                />
                                <Disclaimer
                                    iconClassname="tooltip-img"
                                    labelKey="forms.pay.2x3.disclaimer"
                                    notBorder
                                    textSize="7"
                                    className="px-4 pt-0"
                                />
                            </Box>
                        </Col>
                    </Row>
                )}

                {mode === "preview" || mode === "view" ? (
                    <>
                        <Box display="flex" alignX="center" fullWidth className="amount-wrapper">
                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.Amount}
                                data={getDataAmount(values?.amount)}
                                key="amount"
                                name="amount"
                                value={values?.amount}
                                idField="amount"
                                bigDataAmount
                                label_viewMap={
                                    transaction?.idTransactionStatus === "FINISHED" ||
                                    transaction?.idTransactionStatus === "PROCESSING"
                                        ? { [currentLang]: i18n.get(`forms.${ID_FORM}.amount.label_view`) }
                                        : { [currentLang]: i18n.get("forms.pay.2x3.amount.label") }
                                }
                            />
                        </Box>

                        <Box className={classNames("mt-3", { "mt-9 mx-7": mode === "view" })}>
                            {(transaction?.data?.backendReference || values?.backendReference) && (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    value={transaction?.data?.backendReference || values?.backendReference}
                                    label={
                                        transaction?.idTransactionStatus === "PROCESSING" ||
                                        transaction?.idTransactionStatus === "REJECTED"
                                            ? "forms.pay.2x3.voucherReference.label"
                                            : "forms.pay.2x3.voucher.label"
                                    }
                                    shouldRender={
                                        mode === "view" &&
                                        (transaction?.data?.backendReference || values?.backendReference)
                                    }
                                />
                            )}

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={
                                    // eslint-disable-next-line no-nested-ternary
                                    mode === "preview"
                                        ? moment().format("DD/MM/YYYY")
                                        : transaction?.idParentTransaction
                                        ? moment(transaction?.submitDateTime).format("DD/MM/YYYY")
                                        : moment(transaction?.creationDateTime).format("DD/MM/YYYY")
                                }
                                label="forms.pay.2x3.date.label"
                            />

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={`${
                                    transaction?.programed
                                        ? i18n.get("forms.transaction.ticket.typeOperation.program")
                                        : ""
                                }
                                    ${i18n.get("forms.pay.2x3.formName")}`}
                                label={"forms.pay.2x3.operationType.label"}
                                shouldRender={mode === "view"}
                            />

                            <Field
                                {...genericProps}
                                component={FormFieldsComponents.ProductselectorCustom}
                                data={mode === "preview" ? creditCardOptions : { options: [] }}
                                key="creditCard"
                                name="creditCard"
                                renderAs="combo"
                                value={values?.creditCard}
                                idField="creditCard"
                            />

                            <Field
                                {...genericProps}
                                isLocalForm
                                component={BeneficiaryField}
                                key="beneficiary"
                                name="beneficiary"
                                idField="beneificary"
                                value={values?.beneficiary}
                            />

                            <FormFieldsComponents.ReadTextCustom
                                {...genericProps}
                                value={values?.obligationTypeData.label}
                                label="forms.pay.2x3.obligationType.label"
                            />

                            {(!values?.scheduler || values?.scheduler?.selectedOption === schedulerUtils.TODAY) &&
                                mode === "preview" && (
                                    <FormFieldsComponents.ReadTextCustom
                                        {...genericProps}
                                        value={i18n.get(`scheduler.immediate.radiobutton.label.${transactionKind}`)}
                                        label={thirdTransaction.getTransactionPayLabel(location.pathname)}
                                    />
                                )}
                            {values?.scheduler?.selectedOption &&
                            values?.scheduler?.selectedOption !== schedulerUtils.TODAY ? (
                                <FormFieldsComponents.ReadTextCustom
                                    {...genericProps}
                                    type={SCHEDULER_DATE}
                                    value={values?.scheduler}
                                    label={thirdTransaction.getTransactionSchedulerLabel(location.pathname)}
                                />
                            ) : null}
                        </Box>
                    </>
                ) : null}
            </>
        );
    };

    const {
        nonWorkingDays = [],
        enabledWeekDays = [false, true, true, true, true, true, true, true],
        firstWorkingDate = new Date(),
        maxDaysToSchedule = 30,
        ...restPreData
        // eslint-disable-next-line react/prop-types
        // eslint-disable-next-line react/destructuring-assignment
    } = props.preData || {};



    const validateForm = (values) => {
        const messageRquired = i18n.get(DEFAULT_REQUIRED);


        const errors = validateTransferLocalForm(
            values,
            messageRquired,
            i18n.get("administration.users.emailIncorrectFormat"),
            i18n.get("administration.users.numberMaxLengthExceeded"),
            i18n.get("beneficiary.create.validation.creditCard.notFound"),
        );


        const { amount, creditCard,obligationType } = values;
        if (!amount) {
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.required`);
        }
        const { quantity, currency } = amount;
        if (!quantity || quantity === "" || !currency || currency === "") {
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.required`);
        }
        const minAmount=parseFloat(configUtils.get("pay.2x3.amount.min",75.00));
        const maxAmount=parseFloat(configUtils.get("pay.2x3.amount.max",10000.00));

        if(minAmount>parseFloat(quantity)){
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.min`,null,{
                "CURRENCY":amountDefault.currency,
                "MIN":minAmount.toFixed(2)
            });
        }
        if(parseFloat(quantity)>=maxAmount){
            errors.amount = i18n.get(`forms.${ID_FORM}.amount.max`,null,{
                "CURRENCY":amountDefault.currency,
                "MAX":maxAmount.toFixed(2)
            });
        }

        if (!creditCard || creditCard === "") {
            errors.creditCard = messageRquired;
        }

        if(!obligationType || obligationType==""){
            errors.obligationType = messageRquired;
        }
        return errors;
    };

    const formProps = {
        title: titleForm,
        metadata: {
            draftsEnabled: false,
            templatesEnabled: false,
            schedulable: true,
            programable: true,
            nonWorkingDays,
            enabledWeekDays,
            firstWorkingDate,
            maxDaysToSchedule,
            idActivity: idActivityState,
        },

        renderFields,
        renderTicket,
        useDefaultSubmit: true,
        preData: restPreData,
        isCustom: true,
        idActivity: ID_ACTIVITY,
        validate: validateForm,
        topDisclaimer: getTopDisclaimer(),
        showSchedulerMessage: false,
        pathBackEditMode: fromOneTouchAccess !== undefined && fromOneTouchAccess === true ? "/desktop" : undefined,
        showLocalRecharge: !(activeEnvironment.type === "corporate" && isCreditCardRechargeForm),
        showFilterChips: false,
        templatesEnabled: false,
        topDisclaimer: getTopDisclaimer(),
        topDisclaimerTextSize: "small",
        topDisclaimerTextColor: "text-boton",
        topDisclaimerAlignY: "flex-start",
    };
    return <FormTransition {...props} {...formProps} />;
};

const mapStateToProps = (state) => ({
    id: formSelectors.getId(state),
    fetching: formSelectors.getFetching(state),
    currentLang: i18nSelectors.getLang(state),
    data: formSelectors.getData(state),
    transaction: formSelectors.getTransaction(state),
    childrenTransactions: formSelectors.getChildrenTransactions(state),
    parentTransaction: formSelectors.getParentTransaction(state),
    ticketConfirmation: true,
    templates: templateSelectors.getTemplateList(state),
    mode: formSelectors.getMode(state),
    credentialsGroups: formSelectors.getCredentialsGroups(state),
    isCancellingTransaction: formSelectors.getIsCancellingTransaction(state),
    preDataForm: formSelectors.getPreData(state),
    previewData: formSelectors.getPreviewData(state),
    usesJointAccount: formSelectors.getUsesJointAccount(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    creditCardList: creditCardSelectors.getList(state),
});

Pay2x3Form.propTypes = {
    dispatch: func,
    mode: string,
    fromBackoffice: bool,
    previewData: shape({}),
    currentLang: string,
    preDataForm: shape({}),
    transaction: shape({}),
    location: shape({}),
    isDesktop: bool.isRequired,
    isTablet: bool.isRequired,
    fromTransaction: bool,
    usesJointAccount: bool,
    data: shape({}),
    preData: shape({}),
    fromOneTouchAccess: bool,
    activeEnvironment: shape({
        type: string,
    }),
    creditCardList: arrayOf(shape({})),
};
Pay2x3Form.defaultProps = {
    dispatch: () => {},
    fromBackoffice: false,
    mode: "",
    currentLang: "",
    preDataForm: {},
    previewData: {},
    transaction: {},
    location: {},
    fromTransaction: false,
    usesJointAccount: false,
    data: {},
    preData: {},
    fromOneTouchAccess: false,
    activeEnvironment: {},
    creditCardList: [],
};
export default compose(connect(mapStateToProps), withRouter)(resizableRoute(Pay2x3Form));
